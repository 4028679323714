import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import '../../assets/css/Custom.css'
import Sidebar from "../GeneralComponents/Sidebar";
import axios from "axios";
import { APIURL } from '../constants/Common';
import NodataImg from "../../assets/images/no.png"
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import dateFormat from 'dateformat';
import Footer from '../GeneralComponents/Footer';
import { withTranslation } from 'react-i18next';

class Index extends Component {
    constructor() {
        super()
        this.state = {
            navigate: false,
            visible: false,
            Fullvisible: false,
            team_name: "",
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            switchUser: JSON.parse(localStorage.getItem("switchUser")),
            Document_List: [],
            SubscriptionModal: false,
            ActiveTeam: 0,
            isPermission: 1,
            norecord: 0,
            no_record: false
        };

    }

    componentDidMount() {
        //  if (this.props.match.params.id !== "undefined") {
        this.getExpirationPlanMsg()
        // }

        this.getDocument()
        this.getTeamDetails()
    }


    getDocument() {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "admin_document/list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {


                let norecord = 0;

                response.data.data.map((docl, index) => {
                    if (docl.document_list.length > 0) {
                        norecord = 1;
                    }


                });
                this.setState({
                    Document_List: response.data.data, norecord: norecord, no_record: true
                });



            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        let user_id = this.state.user.user_id;

        var token = this.state.token
        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }
                this.setState({
                    team_name: team_name
                })
            });

    }

    getExpirationPlanMsg = () => {
        console.log(this.state.user.user_id)
        const formData = new FormData();
        let user_id = this.state.user.user_id;
        if (this.state.switchUser != null) {
            user_id = this.state.switchUser.user_id;
        }
        formData.append('user_id', user_id);
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("Subscription", response)
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan == "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }

                if (days > 60 || response.data.member.length == 0) {
                    isPermission = 0;
                }
                /*else if(response.data.member.length==1)
                {
                    if(response.data.member[0].is_admin==0 && response.data.member[0].is_teamleader==0)
                    {
                         isPermission=0;
                    }
                }*/

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission
                    // ActiveTeam:1
                })
            });
    }

    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    render() {
        const { t } = this.props;
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }

        const { Document_List, error } = this.state

        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                <Sidebar
                    data={{
                        // teamInfo: this.teamInfo.bind(this),
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage: "document_page",
                        visible:this.props.location.visible
                    }} />

                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                                <h6 className="mt-3">Upgrade Package</h6>
                            }
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner</h6>
                            }
                            {
                                this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team</h6>
                            }
                            {
                                this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Imenso if the team is to be reactivated.</h6>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                            <Button className="btn btn-danger" onClick={() => this.CloseModal()}>{t("Cancel")}  </Button>
                            {this.state.is_owner === "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>{t("Upgrade Package")}</Button> : ""}
                        </div>
                    </ModalFooter>
                </Modal>

                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">{this.state.team_name} | {t("Documents / links")}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    Document_List.length > 0 && Document_List.map((result, index) =>
                                        <div>
                                            {result.document_list.length > 0 &&
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 mt-2 document_list_area">
                                                                {result.document_list.length > 0
                                                                    ?
                                                                    <h5 className="header-title mb-3 mt-2 pt-0 small" style={{ textTransform: "capitalize" }}>{result.name}</h5>
                                                                    :
                                                                    ""
                                                                }
                                                                <ul className="list-group">
                                                                    {result.document_list.length > 0 && result.document_list.map((res, idx) =>
                                                                        <li className="list-group-item border border-secondary mb-1 ">
                                                                            <a target={res.open_new_window ? '_blank' : '_self'}
                                                                                href={res.file_path}
                                                                            >
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "pdf" ?
                                                                                        <i className="fas fa-file-pdf mr-1  "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "xls" ?
                                                                                        <i className="fas fa-file-excel mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "xlsx" ?
                                                                                        <i className="fas fa-file-excel mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "doc" ?

                                                                                        <i className="fas fa-file-word mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "docx" ?
                                                                                        <i className="fas fa-file-word mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "ppt" ?
                                                                                        <i className="ri-file-ppt-2-fill mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "pptx" ?
                                                                                        <i className="ri-file-ppt-2-fill mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "png" ?
                                                                                        <i className="ri-image-fill mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "jpeg" ?
                                                                                        <i className="ri-image-fill mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "jpg" ?
                                                                                        <i className="ri-image-fill mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "gif" ?
                                                                                        <i className="ri-file-gif-fill mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                {
                                                                                    res.type === "document" && res.mim_type === "zip" ?
                                                                                        <i className="ri-folder-zip-fill mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }

                                                                                {
                                                                                    res.type === "link" ?
                                                                                        <i className="mdi mdi-link mdi-18px mr-1 "></i>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                <span style={{ color: "#fff", }} className="fw-bolder" >{res.name}</span>
                                                                                <div className="row mt-1 ">
                                                                                    <span className="col-md-6 small" style={{fontSize:"14px"}} htmlFor="example-date"> {dateFormat(res.date, "dd.mm.yyyy")}, {res.author}</span>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )}
                                {this.state.no_record == true && this.state.norecord == 0 &&
                                    <div className="card mt-2">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 mt-1 ml-1">
                                                    <div className="fw-medium mt-2" >
                                                        <p className="col-md-4" htmlFor="example-date">{t("No documents available")}</p>
                                                    </div>
                                                    <div className="mb-4 mt-2">
                                                        <img className="" height="40px" src={NodataImg} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* <!-- container --> */}

                        </div>
                        {/* <!-- content --> */}
                    </div>

                    {/* <!-- Footer Start --> */}

                    {/* <!-- end Footer --> */}
                </div>

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
            </div>
        );
    }
}
export default withTranslation()(Index);