import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../../Admin/GeneralComponents/Sidebar";
import { Input, Spinner, Table, Modal, ModalBody,ModalHeader,ModalFooter,Label,Button } from "reactstrap";

import axios from "axios";
import { APIURL } from '../../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import Select from 'react-select'
import Pagination from "react-js-pagination";
import avatar from "../../../assets/images/avatar.png"
import Footer from '../../GeneralComponents/Footer'

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333"
        };
    }
};


export default class TeamMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Team_List: [],
            msg: "",
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Loader: false,
            modal: false,
            activePage: 1,
            limit: 15,
            totalItemsCount: 0,
            search: "",
            status: "",
            statusArray: [{ 'value': "", "label": "All" }, { 'value': "No", "label": "Inactive" }, { 'value': "Yes", "label": "Active" }],
            index: "",
            data: [],
            removeTeamModal: false,
            StatusModal: false,
            statusDefault: [{ 'value': "", "label": "All" }],
            OwnerModal: false,
            teamAdmins: [],
            team_id:0,
            errMsg: {},
            MemberModal:false,
            teamFunctions:[{value:"",label:"No function: Just owner / Teamadmin"}],
            function_id:[{value:"",label:"No function: Just owner / Teamadmin"}],
            member_email:""
        }
    }

    getTeamList() {
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('status', this.state.status);
        formData.append('limit', this.state.limit);
        var token = this.state.token
        axios
            .post(APIURL + "admin/team/list", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    Team_List: response.data.data.data,
                    activePage: response.data.data.current_page,
                    totalItemsCount: response.data.data.total,
                    teamId: response.data.data,
                    Loader: false
                })

            })
            .catch(error => {
                this.setState({
                    error,
                    Loader: false
                })
            });
    }
    componentDidMount() {
        this.getTeamList();

    }
    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState(
            { activePage: pageNumber }
            , () => { this.getTeamList() });
    }
    handleSearch(e) {
        this.setState(
            { search: e.target.value, activePage: 1 }
            , () => { this.getTeamList() });
    }

    handleStatus(e) {
        this.setState(  { 
                activePage:1,
                status: e.value ,
                statusDefault: [{value:e.value,label:e.label}]}
            , () => { this.getTeamList() });
    }

    handleRemoveTeamModal = (idx, e) => {
        this.setState({
            index: idx,
            data: e,
            removeTeamModal: !this.state.removeTeamModal
        })
    }
    RemoveModalClose() {
        this.setState({
            removeTeamModal: false
        })
    }

    Owner_modal_toggle() {
        this.setState({
            OwnerModal: !this.state.OwnerModal
        });
    }

    HandleOwner(index) {

        let teamAdmins=this.state.teamAdmins;
      
        teamAdmins.map(function (item,idx) {
             if(index === idx)
             {
                  item.is_teamleader=true;
             }
             else
             {
                 item.is_teamleader=false;
             }
            
        });
    
        this.setState({
            teamAdmins: teamAdmins
        })
    }

    getTeamAdmin(id){
      
       this.setState({
            team_id: id
        });
        

        const formData = new FormData();
        formData.append('team_id', id);
         var token = this.state.token
         axios
            .post(APIURL + "admin/team/get-team-admin",formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
            
                 this.setState({ teamAdmins: response.data.data,OwnerModal: !this.state.OwnerModal, errMsg: ""})

                
            });

       
    }

     memberToggle() {
        this.setState({
            MemberModal: !this.state.MemberModal
        });
    }

     onChangehandler = (e) => {
     
        this.setState({
            member_email: e.target.value,
        });
        
       
    };

    SelectFunctions = (e) => {
        this.setState({
            function_id: [{ value: e.value, label: e.label }],
        
        })
    };


    handleAddMembers = () => {
         this.setState({
                    Loader: true
                });
        
        const formData = new FormData();
        formData.append('team_id',  this.state.team_id);
        formData.append('member_email', this.state.member_email);
        formData.append('is_admin', 1);
        if (Array.isArray(this.state.function_id) && this.state.function_id.length) {
            formData.append('function_id', this.state.function_id[0].value);
        }
        else {
            formData.append('function_id', '');
        }
        formData.append('member_id', '');

        var app_url = APIURL + "admin/team/create-member";
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                
                this.setState({
                   
                    function_id:[{value:"",label:"No function: Just owner / Teamadmin"}],
                    member_email: "",
                    errMsg: "",
                    MemberModal: false,
                    Loader: false
                });

            })
            .catch(error => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }

            }
            );

    }

    getTeamFunction(id){
      
       this.setState({
            team_id: id,
            function_id:[{value:"",label:"No function: Just owner / Teamadmin"}],
            member_email:"",
            errMsg: "",
        });
        

        const formData = new FormData();
        formData.append('team_id', id);
         var token = this.state.token
         axios
            .post(APIURL + "admin/team/get-team-function",formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {

                 let functions = response.data.data;
                 let teamFunctions=[{value:"",label:"No function: Just owner / Teamadmin"}];
                    for (var c = 0; c < functions.length; c++) {
                       teamFunctions.push({ value: functions[c].function_id, label: functions[c].name })
                    }
            
                 this.setState({ teamFunctions: teamFunctions,MemberModal: !this.state.MemberModal })

                
            });

       
    }

    

    Submit_Owner_Change() {
     let owner_user_id="";
      let teamAdmins=this.state.teamAdmins;
        teamAdmins.map(function (item,idx) {
             if(item.is_teamleader === true)
             {
                owner_user_id=item.user_id;
             }
        });
         this.setState({
                    Loader: true
                });
       
      
        const formData = new FormData();
        formData.append('request_to_id', owner_user_id);
        formData.append('owner_user_id', this.state.user.user_id);
        var app_url = APIURL + "change_team_owner/" + this.state.team_id;
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                    this.setState({
                        msg: response.data.message,
                        errMsg: '',
                        OwnerModal: false,
                        Loader: false
                    })
            })
             .catch(error =>{
                if(error.response.data.message === "validation_error")
                {
                     this.setState({
                         errMsg:error.response.data.errors,
                         Loader: false
                     })
                }
                else
                {
                     this.setState({
                       error,
                       Loader: false
                     })
                }
                 
             }
           );   
    }

    doDelete() {
        const { index, data } = this.state;
        this.setState({ Loader: true });
        console.log('delete', data)
        const formData = new FormData();
        formData.append('team_id', data.team_id);
        var token = this.state.token

        /* fetch repos with axios */
        axios
            .post(APIURL + "admin/team/delete", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {
                const newRep = [...this.state.Team_List];
                newRep.splice(index, 1);
                this.setState({
                    Team_List: newRep,
                    Loader: false,
                    removeTeamModal: false
                });
            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false,
                    removeTeamModal: false
                })
            );
    }

    toggle(idx, item) {
        this.setState({
            index: idx,
            data: item,
            StatusModal: !this.state.StatusModal
        })
    }

    statusUpdate() {
        this.setState({ Loader: true });
        console.log('delete', this.state.data)
        const formData = new FormData();
        formData.append('team_id', this.state.data.team_id);
        formData.append('status', this.state.data.is_active === 'Yes' ? 'No' : 'Yes');
        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "admin/team/update_status", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {
                this.setState({
                    StatusModal: false
                })
                this.getTeamList();
            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false,
                    StatusModal: false
                })
            );
    }
    ModalClose() {
        this.setState({
            StatusModal: false
        })
    }

    render() {
        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate || !this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (this.state.user.user_type !== 'Admin') {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <Sidebar
                    data={{
                        ActivePage: "team_list",
                    }} />

                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">Super Admin | Team List </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-4 col-md-3">
                                    <Input type="text" onChange={(e) => this.handleSearch(e)} placeholder="Search .." />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder="Select Status"
                                        className="form-select-control"
                                        options={this.state.statusArray}
                                        value={this.state.statusDefault}
                                        onChange={(e) => this.handleStatus(e)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="table-responsive">
                                    {/*<ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button btn btn-warning"
                                        table="table-to-xls"
                                        filename="teamlist"
                                        sheet="teamlist"
                                        buttonText="Download Excel"
                                    />*/}
                                    <Table id="table-to-xls" hover className="table table-hover table-centered nowrap mt-3">
                                        <thead style={{ backgroundColor: "#0c3047" }}>
                                            <tr>
                                                <th className="fw-medium">#No</th>
                                                <th className="fw-medium ">Team Name</th>
                                                <th className="fw-medium">Team Type</th>
                                                <th className="fw-medium ">Owner Name</th>
                                                <th>Make Admin</th>
                                                <th>Change Owner</th>
                                                <th className="fw-medium text-center" width="120">Status</th>
                                                <th className="fw-medium text-center" width="120">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.Team_List.length > 0 ? this.state.Team_List.map((item, idx) => (
                                                <tr className="">
                                                    <td className="fw-medium">{(this.state.activePage - 1) * this.state.limit + (idx + 1)}</td>
                                                    <td>{item.team_name}</td>
                                                    <td>{item.team_type_name}</td>
                                                    <td>{item.owner_name}</td>
                                                    <td> <Button  size="sm" color="default" className="" onClick={(e) => this.getTeamFunction(item.team_id)}>Add</Button> </td>
                                                    <td> <Button  size="sm" color="default" className="" onClick={(e) => this.getTeamAdmin(item.team_id)}>Change Owner</Button> </td>
                                                    <td className="text-center">
                                                       
                                                        {
                                                            item.is_active === 'Yes' ?
                                                                <Button size="sm" color="success" className="" onClick={(e) => this.toggle(idx, item)}>Active</Button> :
                                                                <Button size="sm" color="default" className="" onClick={(e) => this.toggle(idx, item)}>Inactive</Button>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <Button size="sm" color="danger" className="" onClick={(e) => this.handleRemoveTeamModal(idx, item)}>Remove</Button>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan="7" className="text-center">
                                                        No Teams Available
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {this.state.totalItemsCount > 0 &&
                                <div className="pagination-rounded">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            }
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                </div>                

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}

                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.StatusModal}  >
                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">Are you sure want to {this.state.data.is_active === 'Yes' ? "inactive" : "active"}  team <br />{this.state.data.team_name}</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={() => this.statusUpdate()} >Confirm </Button>{' '}
                                <Button color="default" onClick={() => this.ModalClose()}>Cancel</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeTeamModal}  >
                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">Are you sure want to remove ?</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={() => this.doDelete()} >Confirm </Button>{' '}
                                <Button className="mx-2" color="default" onClick={() => this.RemoveModalClose()}>Cancel</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal size="md" isOpen={this.state.OwnerModal}  >
                    <ModalHeader toggle={() => this.Owner_modal_toggle()}>Change Owner</ModalHeader>
                    <ModalBody>
                        <div style={{ color: "#fff", }}>
                            {this.state.teamAdmins.length > 0 ?
                                this.state.teamAdmins.map((item, idx) => (
                                    <div className="d-flex align-items-center border-secondary border-bottom py-2 ">
                                        <Input type="radio" name="radio1" className=""  defaultChecked={item.is_teamleader ? true : false} onChange={() => this.HandleOwner(idx)} />
                                        <div className="ml-2 mr-1">
                                              
                                             {
                                                item.avatar_picture ?
                                                    <img alt="avatar" className="avatar-sm rounded-circle" src={item.avatar_picture} />
                                                    :
                                                    <img alt="avatar" className="avatar-sm rounded-circle" src={avatar} />
                                             }
                                        </div>
                                        <div className="ml-2 mr-1"><strong>{item.full_name}</strong></div>
                                    </div>
                                )) :
                                <span>Team admin not found</span>
                            }
                            <span className="text-danger">{this.state.errMsg.request_to_id}</span>

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 ">
                         
                            <Button className="btn btn-danger" onClick={() => this.Owner_modal_toggle()}>Cancel</Button>
                            {this.state.teamAdmins.length > 0 &&
                            <Button className="btn btn-success" width="50px" onClick={() => this.Submit_Owner_Change()}>Confirm </Button>
                         }</div>
                    </ModalFooter>
                </Modal>


                <Modal size="md" isOpen={this.state.MemberModal}   autoFocus={false}>
                    <ModalHeader  toggle={() => this.memberToggle()}  >Add Member Function</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>Member E-Mail</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter member email"
                                        id="useremail"
                                        name="email"
                                        required=""
                                        value={this.state.member_email}
                                        onChange={this.onChangehandler}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{this.state.errMsg.member_email}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>Function</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required="required"
                                        placeholder="Select Function"
                                        options={this.state.teamFunctions}
                                        value={this.state.function_id}
                                        onChange={this.SelectFunctions}
                                        styles={colourStyles}
                                    />

                                    <span className="text-danger">{this.state.errMsg.function_id}</span>
                                </div>
                            </div>
                           
                            
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                             <button className="btn btn-danger" onClick={() => this.memberToggle()}>Cancel </button>
                             <Button className="btn btn-success" onClick={() => this.handleAddMembers()}>Save </Button>
                            </div>
                    </ModalFooter>
                </Modal>

                <div className="rightbar-overlay"></div>
            </div>
        );
    }
}