import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import '../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../GeneralComponents/Sidebar";
import { Form, Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../constants/Common';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import Select from 'react-select'
import Footer from '../GeneralComponents/Footer';
import { Trans, withTranslation } from 'react-i18next';

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333"
        };
    }
};

const options = [
    { value: 0, label: 'No' },
    { value: 1, label: 'yes' },
];

 class TeamMembers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Member_Team_List: [],
            Member_Invitation_List: [],
            teamId: "",
            email: "",
            isOwner: "",
            setMember: "",
            function: "",
            func_List: [],
            msg: "",
            errMsg: {},
            scsMsg: "",
            teamId: '',
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Is_admin: [],
            function_name: [],
            team_name: "",
            Loader: false,
            modal: false,
            no_record:false
        }

    }

    confirmInvite(e) {
        const formData = new FormData();
        formData.append('id', e.id)
        var token = this.state.token
        axios.post(APIURL + "member_invitaion_accept", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                if (response.data.status === 200) {
                    this.getTeamMemberInvitationList();
                    this.setState({
                        ScsModal: true
                    }, () => {
                        this.setState({
                            ScsModal: false
                        })
                    })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    this.setState({
                        /*teamAttrs: this.state.teamAttrs.filter((s, sidx) => e.team_attrs_id !== s.team_attrs_id
                        )*/
                    });
                    // console.log("Next", this.state.teamAttrs
                }
            });
    }

    declineInvite(e) {
        console.log('decline', e)
        const formData = new FormData();
        formData.append('team_id', e.team_id)
        formData.append('user_id', e.user_id)
        var token = this.state.token
        axios.post(APIURL + "delete_member/" + e.id, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                if (response.data.status === 200) {
                    this.getTeamMemberInvitationList();
                    this.setState({
                        // toast: true,
                        // modal: false,
                        // ToastMsg: response.data.message
                    })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    this.setState({
                        /*teamAttrs: this.state.teamAttrs.filter((s, sidx) => e.team_attrs_id !== s.team_attrs_id
                        )*/
                    });
                    // console.log("Next", this.state.teamAttrs)


                }
            });
    }

    getTeamMemberInvitationList() {
        var token = this.state.token
        axios
            .get(APIURL + "get_team_member_invitation/" + this.state.user.user_id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({ Member_Invitation_List: response.data.data });
                }
                this.setState({ no_record: true });

            });
    }

    getTeamList(id) {
        var token = this.state.token
        axios
            .get(APIURL + "team_list/" + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    this.setState({ Member_Team_List: response.data.data });
                }
            });
    }

    componentDidMount() {
        this.getTeamMemberInvitationList();
        if (this.props.match.params.id > 0) {
            // this.getTeamList(this.props.match.params.id);
            // this.getTeamDetails();
        }
    }
    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    team_name: team_name
                })
            });

    }


    render() {
        const { t } = this.props;
        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate || !this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <Sidebar
                    data={{
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage: "team_invitation",
                        visible:this.props.location.visible
                    }} />
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">{t('Team invitations')} </h4>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="table-responsive">
                                    <Table hover className="table table-hover table-centered nowrap">
                                        <thead style={{ backgroundColor: "#0c3047" }}>
                                            <tr>
                                                <th className="fw-medium">{t('Team name')}</th>
                                                <th className="fw-medium">{t('Owner')}</th>
                                                <th className="fw-medium">{t('Address')}</th>
                                                <th className="fw-medium text-center" style={{ width: "180px" }}>{t('Action')}</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.Member_Invitation_List.length > 0 && this.state.Member_Invitation_List.map((item, idx) => (
                                                <React.Fragment>
                                                    <tr>
                                                        <td >{item.team_name}</td>
                                                        <td >{item.full_name}</td>
                                                        <td >{item.address}</td>
                                                        <td className="text-center">
                                                            <div>
                                                                <Button size="sm" color="warning" onClick={(e) => this.confirmInvite(item)} >{t('Confirm')}</Button>
                                                                {/* <Button size="sm" color="danger" className="ms-2" onClick={(e) => this.declineInvite(item)}>Decline</Button> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            {this.state.no_record==true && this.state.Member_Invitation_List.length == 0 &&
                                                <tr>
                                                    <td colspan="4">
                                                        {t('No Invitation Available')}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <Modal size="md" isOpen={this.state.modal} >
                                <ModalHeader>Add Member Function</ModalHeader>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>Member E-Mail</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter member E-Mail"
                                                    id="useremail"
                                                    name="email"
                                                    required=""
                                                    value={this.state.email}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.member_email}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>Function</Label>

                                                <Select
                                                    aria-label=".form-select-lg example"
                                                    required="required"
                                                    placeholder="Select Function"
                                                    options={this.state.func_List}
                                                    value={this.state.function_name}
                                                    onChange={this.SelectFunctions}
                                                    styles={colourStyles}
                                                />
                                                <span className="text-danger">{this.state.errMsg.function_id}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>Is-Team Admin</Label>
                                                <Select
                                                    placeholder="Select Is-Team Admin"
                                                    value={this.state.Is_admin}
                                                    onChange={this.handleChange}
                                                    options={options}
                                                    styles={colourStyles}
                                                />
                                                <span className="text-danger">{this.state.errMsg.Is_Admin}</span>
                                            </div>
                                        </div>
                                    </div>

                                </ModalBody>

                                <ModalFooter>
                                    <button className="btn btn-danger" onClick={() => this.toggle()}>Cancel </button>
                                    <Button className="btn btn-success mr-3" onClick={() => this.handleAddMembers()}>Save </Button>
 </ModalFooter>
                            </Modal>
                            <Modal size="md" isOpen={this.state.ChangePicModal} >
                                <ModalHeader toggle={this.ChangePicModal}>Change Picture</ModalHeader>
                                <ModalBody>
                                    <div className="col-sm-12 mt-3">
                                        <div className="mb-3">
                                            <Input
                                                type="file"
                                                onChange={this.handleChangeLogo}
                                                className="form-control"
                                                id="fileinput"
                                            />
                                            <span className="text-danger">{this.state.errMsg.icon}</span>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <div className="d-flex justify-content-between w-100">
                                       <button className="btn btn-danger" onClick={() => this.ChangePicModal()}>Cancel </button>
                                       <Button className="btn btn-success" onClick={() => this.iconDataSubmit()}>Save </Button>
                                         </div>
                                </ModalFooter>
                            </Modal>

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                </div>

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                <div className="rightbar-overlay"></div>

            </div>
        );
    }
}
export default withTranslation()(TeamMembers);