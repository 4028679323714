import React, { Component } from 'react'
import { Button, Form, Label, Input, Spinner, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from 'react-select'
import axios from "axios";
import { APIURL } from '../constants/Common';
// import '../../assets/css/Custom.css'
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { withTranslation } from 'react-i18next';

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",
        };
    }
};

class TeamInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamInfo: {
                team_name: "",
                logo: "",
                owner: "",
                teamtype: "",
                redirect: false,
            },
            msg: "",
            errMsg: {},
            scsMsg: "",
            errMsgAttr: '',
            teamTypes: [],
            setAttr: '',
            image: '',
            teamId: '',
            selected_type: [{value: 5, label:"Ohne Teamart"}],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            teamAttrs: [],
            Loader: false,
            modal: false,
            typeBothData:[]
        };
        this.handleAddTeamAttrs = this.handleAddTeamAttrs.bind(this);
        this.toggle = this.toggle.bind(this);

    }

    toggle(e) {
        this.setState({
            modal: !this.state.modal,
            errMsgAttr: '',
            setAttr: ''
        });
    }

    handleChange = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }
    updateInput = (event) => {
        this.setState({ setAttr: event.target.value })
    };

    typeSelect = (e) => {
        const { teamInfo } = this.state;
        teamInfo['teamtype'] = e.value;

        this.setState({ teamInfo, selected_type: [{ value: e.value, label: e.label }] });

    };

    onChangehandler = (e, key) => {
        const { teamInfo } = this.state;
        teamInfo[e.target.name] = e.target.value;
        this.setState({ teamInfo });
    };

    getTeamTypes() {
        var token = this.state.token
        axios
            .get(APIURL + "get_team_types",{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                console.log("teamtypes",response.data);
                if (response.data.status === 'success') {
                    //   console.log("teamtypes",response.data.team_types);
                     this.setState({
                        typeBothData: response.data.team_types
                    },()=>{this.setTeamTypes()})
                }
            });
    }

    compareBy(key) {
        return function (a, b) {
          if (""+a[key]<(""+b[key])) return -1;
          if (""+a[key]>(""+b[key])) return 1;
          return 0;
    };}


    setTeamTypes(){

         let team_types = this.state.typeBothData;
            let result=[]; let selectType=[{value: 1, label:"Ohne Teamart"}];
            if(this.props.i18n.language=="ge")
            { 
                team_types.sort(this.compareBy("name"));
              
            }
            else
            {
                team_types.sort(this.compareBy("name_en"));
            }

            for (var c = 0; c < team_types.length; c++) {
               if(this.props.i18n.language=="ge")
               { 
                   result.push({ value: team_types[c].team_type_id, label: team_types[c].name })
                   if(this.state.selected_type[0].value==team_types[c].team_type_id)
                   {
                      selectType=[{value: team_types[c].team_type_id, label:team_types[c].name}];
                   }
               }
               else
               {
                  if(team_types[c].name_en!=null)
                  {
                     result.push({ value: team_types[c].team_type_id, label: team_types[c].name_en })
                       if(this.state.selected_type[0].value==team_types[c].team_type_id)
                       {
                          selectType=[{value: team_types[c].team_type_id, label:team_types[c].name_en}];
                       }
                  }
                 
               }
                
            }
            

             this.setState({
                teamTypes: result,
                selected_type:selectType
            })
    }

    handleAddTeamAttrs = () => {
      
            if (this.props.data.teamId) {
                this.setState({
                    Loader: true
                })
                // console.log("idd",this.props.data, this.state.teamId)
                const formData = new FormData();
                formData.append('team_id', this.props.data.teamId);
                formData.append('team_member_attribute', this.state.setAttr);
                formData.append('team_member_id', '');
                formData.append('is_active', 1);
                var token = this.state.token
                var app_url = APIURL + "create_team_attribute";
                axios
                    .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((response) => {
                            this.getTeamInfo(this.props.data.teamId);
                            this.setState({
                                modal: false,
                                teamAttrs: this.state.teamAttrs.concat([{ name: this.state.setAttr, team_attrs_id: ""}]),
                                setAttr: '',
                                errMsg: '',
                                errMsgAttr: '',
                                msg: response.data.message,
                                Loader: false
                            });
                    })
                    .catch(error =>{
                           if(error.response.data.message === "validation_error")
                           {
                                this.setState({
                                    errMsg:error.response.data.errors,
                                    isLoading: false,
                                    Loader:false,
                                })
                           }
                           else
                           {
                                this.setState({
                                  message:error.response.data.message,
                                  isLoading: false,
                                  Loader:false,
                                })
                           }
                        }
                     );  
        }
        else {
                if(this.state.setAttr === "")
                {
                    this.setState({ errMsgAttr: 'Please enter team member attribute' });
                }
                else
                {
                    this.setState({
                        modal: false,
                        teamAttrs: this.state.teamAttrs.concat([{ name: this.state.setAttr, team_attrs_id: "" }]),
                        setAttr: '',
                        errMsgAttr: ''
                    });
                }            
        }
    }
    handleRemoveTeamAttrs = (idx) => () => {
        this.setState({ teamAttrs: this.state.teamAttrs.filter((s, sidx) => idx != sidx) });
    }

    handleRemoveAtr(e) {

        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        axios.post(APIURL + "delete_team_attribute/" + e.team_attrs_id, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        toast: true,
                        modal: false,
                        ToastMsg: response.data.message
                    })
                    this.setState({
                        teamAttrs: this.state.teamAttrs.filter((s, sidx) => e.team_attrs_id != s.team_attrs_id
                        )
                    });
                    console.log("Next", this.state.teamAttrs)


                }
            });
    }

    handleTeamAttrsNameChange = (idx) => (evt) => {
        const newShareholders = this.state.teamAttrs.map((shareholder, sidx) => {
            if (idx != sidx) return shareholder;
            return { ...shareholder, name: evt.target.value };
        });

        this.setState({ teamAttrs: newShareholders });
    }

    onTrigger = (event) => {
        this.props.parentCallback(event.target.myname.value);
        event.preventDefault();
    }



    onSubmit = (e) => {
        this.setState({
            Loader: true
        })
        // console.log("idd",this.props.data, this.state.teamId)
        const formData = new FormData();
        
        if (Array.isArray(this.state.selected_type) && this.state.selected_type.length) {
            formData.append('team_type_id', this.state.selected_type[0].value);
        }
        else{
            formData.append('team_type_id', "");
        }
        
        formData.append('team_name', this.state.teamInfo.team_name);
        formData.append('icon', this.state.image);
       // formData.append('team_owner', this.state.user.email);
        for (var i = 0; i < this.state.teamAttrs.length; i++) {
            formData.append('team_member_attributes[]', this.state.teamAttrs[i].name);
            formData.append('team_member_ids[]', this.state.teamAttrs[i].team_attrs_id);
        }
        var app_url = APIURL + "create_team";

        if (this.state.teamId || this.props.data.teamId) {
            app_url = APIURL + "edit_team/" + this.props.data.teamId;
        }
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({ isLoading: false });
              
                    this.props.data.teamInfo({ team_id: response.data.data });

                    this.props.data.handleCompleteTab(1);
                    //  this.props.data.handleActiveTab(1);
                    this.getTeamInfo(response.data.data);
                    this.setState({
                        teamId: response.data.data,
                        msg: response.data.message,
                        errMsg: '',
                        redirect: true,
                        Loader: false
                    });
               
            })
            .catch(error =>{
                if(error.response.data.message === "validation_error")
                {
                     this.setState({
                         errMsg:error.response.data.errors,
                         isLoading: false,
                         Loader:false,
                         
                     })
                }
                else
                {
                   
                     this.setState({
                    errMsg: { message: error.response.data.message },
                       isLoading: false,
                       Loader:false,
                       SubscriptionModal:true
                     })
                }
             }
          );  
    };

    getTeamInfo(id) {
        var token = this.state.token
        axios
            .get(APIURL + "team_info/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                // console.log("team Info" ,response.data.data)
                const { teamInfo } = this.state;
                teamInfo['team_name'] = response.data.data.team_name;
                teamInfo['teamtype'] = response.data.data.team_type_id;
                this.props.data.teamInfo({ team_id: id, Team_name: response.data.data.team_name });
                this.setState({ teamInfo });
                this.setState({
                    teamAttrs: response.data.data.team_attributes,
                    selected_type: [{
                        'value': response.data.data.team_type_id,
                        'label': response.data.data.team_type_name,
                    }]
                });
                // this.setState({ selected_type: response.data.data.team_type_name });
            });
    }

    CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    componentDidMount() {
    
        // this.getTeamInfo(this.props.data.teamId)
        this.getTeamTypes();
        if (this.props.data.teamId > 0) {
            this.getTeamInfo(this.props.data.teamId);
        }
    }
    componentWillReceiveProps() {
         this.setTeamTypes();
    }
    render() {
        // console.log('formValue', this.props);
        const { t } = this.props;
        const { user } = this.state;
        return (
            <>
                {this.state.Loader ? <div className="loader"><Spinner style={{ width: '3rem', height: '3rem' }} /></div> : ""}

                <Form id="teaminfoform" method="post" action="#" enctype='multipart/form-data' className="form-horizontal was-validated">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <h5>{t('Team information')}</h5>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="team_name">{t('Team name')}</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('Enter team name')}
                                                    id="team_name"
                                                    name="team_name"
                                                    required=""
                                                    data-maxzpsw="0"
                                                    value={this.state.teamInfo.team_name}
                                                    onChange={this.onChangehandler}
                                                    autoFocus={true}
                                                />
                                                <span className="text-danger">{t(this.state.errMsg.team_name)}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <Label  className="form-label" htmlFor="fileinput">{t('Logo')}</Label>

                                                <Input
                                                    id="filePicker"
                                                    type="file"
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    placeholder={t('No file chosen')}
                                                    id="fileinput"
                                                />
                                                <div className="">({t("Only png, jpg, jpeg format and size upto 2 MB is allowed")})</div>
                                                <span className="text-danger">{t(this.state.errMsg.icon)}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="team_type">{t('Team type')}</Label>
                                                <Select
                                                    aria-label=".form-select-lg example"
                                                    required=""
                                                    placeholder="Select Team Type"
                                                    className="form-select-control"
                                                    options={this.state.teamTypes}
                                                    value={this.state.selected_type}
                                                    onChange={this.typeSelect}
                                                    styles={colourStyles}
                                                />
                                                
                                                <span className="text-danger">{this.state.errMsg.team_type_id}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="team_owner">{t('Owner')}</Label>
                                                <Input
                                                    type="text"
                                                    id="team_owner"
                                                    name="team_owner"
                                                    className="form-control"
                                                    value={user.email}
                                                    required="" disabled
                                                />
                                                <div className="">{t("Team-Owner has always teamadmin rights, but will not receive infomails")}</div>
                                                <span className="text-danger">{this.state.errMsg.team_owner}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card col-lg-8">
                                <div className="card-body">
                                    <div className="mb-2">
                                        <h5>{t('Attributes')}</h5>
                                        <span style={{}} >{t('Attributes which can be defined per team. These attributes are to be filled in per team member (e.g. t-shirt size, COVID vaccination, license no., …) and can be filled in by each team member itself or centrally by the team administrator.')}</span>
                                    </div>
                                    <table className="table table-hover m-0 table-centered dt-responsive nowrap w-100" cellSpacing="0" id="tickets-table">
                                        <tbody className="font-14">
                                            {
                                                this.state.teamAttrs.length > 0 ? this.state.teamAttrs.map((attrs, idx) => (
                                                    <tr key={idx}>
                                                        <td>{attrs.name}</td>
                                                        <td className="text-right">
                                                            {!this.props.data.teamId &&
                                                                <i onClick={this.handleRemoveTeamAttrs(idx)} className="cursor_pointer far fa-trash-alt"></i>
                                                            }
                                                            {this.props.data.teamId &&
                                                                <i onClick={(e) => this.handleRemoveAtr(attrs)} className="cursor_pointer far fa-trash-alt"></i>
                                                            }

                                                        </td>
                                                    </tr>
                                                ))
                                                    :
                                                    <tr>
                                                        <td className="text-center">{t('No attributes available')}</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className=" mt-3">
                                        <Button
                                            type="button"
                                            id="add_attrs"
                                            onClick={this.toggle}
                                            className="btn btn-warning waves-effect waves-light mt-2 add_attrs fw-bolder"
                                        >{t('Add team attributes')}
                                           
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end col --> */}
                    </div>
                    {/* <!-- end row --> */}
                    <ul className="pager wizard mb-0 list-inline">
                        <li className="next list-inline-item disabled">
                            {<Button onClick={() => this.onSubmit()} to="/functions" type="button" className="btn btn-primary fw-bolder" >{t('Go to functions')} <i className="mdi mdi-arrow-right ms-1"></i></Button>}
                        </li>
                    </ul>
                </Form>

                <Modal size="md" isOpen={this.state.modal}  autoFocus={false}>
                    <ModalHeader toggle={this.toggle}>{t('Attribute name')} </ModalHeader>
                    <ModalBody>
                        <Input
                            type="text"
                            className="form-control"
                            id="team_attr"
                            onChange={this.updateInput}
                            placeholder={t('Enter team member attribute')} 
                            value={this.state.setAttr}
                            autoFocus={true}
                        />
                        
                        <span className="text-danger">{t(this.state.errMsgAttr)}</span>
                        <span className="text-danger">{this.state.errMsg.team_member_attribute}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                        <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}> {t('Cancel')}</button>
                        <button className="btn btn-success fw-bolder" onClick={() => this.handleAddTeamAttrs()}> {t('Save')} </button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal size="md" className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal}   >
                    <ModalHeader toggle={() => this.CloseModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                           {this.state.errMsg.message}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                           
                            <Button className="btn btn-danger fw-bolder" onClick={() => this.CloseModal()}>{t('Cancel')} </Button>
                            {this.state.is_owner === "Yes" ?<Button className="btn btn-success fw-bolder" onClick={() => this.goToSubscription()}>{t('Update license')}</Button> :""}
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
export default withTranslation()(TeamInfo);
