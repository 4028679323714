// // export const APIURL = "http://192.168.43.177:8000/api/"
// // export const BASEURL = "http://192.168.43.177:3000/"
// export const APIURL = "https://api.teamorganizer.ch/api/"
// export const BASEURL = "https://to.teamorganizer.ch/"
// export const APIDOMAIN = "https://to.teamorganizer.ch/api/"
// export const ICALURL = "webcal://api.teamorganizer.ch/api/"


// export const APIURL = "http://192.168.43.177:8000/api/"
// export const BASEURL = "http://192.168.43.177:3000/"
export const APIURL = "https://api.imenso.in/api/"
export const BASEURL = "https://to.imenso.in/"
export const APIDOMAIN = "https://to.imenso.in/api/"
export const ICALURL = "webcal://api.imenso.in/api/"