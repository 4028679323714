import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'

import no_img from '../../../assets/images/no_data_found.svg'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../../GeneralComponents/Sidebar";
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import dateFormat from 'dateformat';
import { Global, css } from "@emotion/core";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Footer from '../../GeneralComponents/Footer'
import { withTranslation } from 'react-i18next';

const editorConfigurationView = {
    toolbar: [

    ],
    ckfinder: {


    },
    readOnly: true
};
const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'bulletedList',
        'numberedList',
        '|',
        'imageUpload',
        'undo',
        'redo',
        '|',
        'imageStyle:alignLeft',
        'imageStyle:alignCenter',
        'imageStyle:alignRight'
    ],
    ckfinder: {
        // Upload the images to the server using the CKFinder QuickUpload command
        // You have to change this address to your server that has the ckfinder php connector
        //uploadUrl: 'https://ckeditor.com/apps/ckfinder/3.5.0/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'
        uploadUrl: APIURL + 'add-image'

    }
};

class TeamMembers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            News_list: [],
            news_id: "",
            text: "",
            subject: "",
            setMember: "",
            msg: "",
            errMsg: {},
            scsMsg: "",
            teamId: '',
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Is_admin: [],
            team_name: "",
            Loader: false,
            modal: false,
            addModal: false,
            hide: true,
            Show: false,
            NewsModal: false,
            newsContent: "",
            PublishingDate: "",
            Disable_Prev_Date: "",
            newsId: "",
            index: "",
            SubscriptionModal: false,
            subscriptionPlan: "No",
            is_owner: "No",
            day: 0,
            ActiveTeam: 0,
            isPermission: 1,
            no_record: false
        }
        this.toggle = this.toggle.bind(this)
        this.NewsModal = this.NewsModal.bind(this)
        this.Editclick = this.Editclick.bind(this)
        this.CloseModal = this.CloseModal.bind(this)
        this.handleChangeText = this.handleChangeText.bind(this);
        this.removeNewsModal = this.removeNewsModal.bind(this);
    }

    ckWrapperStyle = css`
    position: relative;
    z-index: 1;
    &::before {
      color: rgba(251, 50, 8, 1.0);
      content: attr(data-placeholder);
      position: absolute;
      margin: var(--ck-spacing-large) 0;
      top: 0;
      z-index: -1;
    }
  `;

    //   getDateTime = () => {
    //     let tempDate = new Date();
    //     let date = (tempDate.getMonth()+1) + '-' + tempDate.getDate() + '-' + tempDate.getFullYear(); 
    //     return date;
    //  }
    uploadAdapter(loader) {
        console.log("jhjgj")
        return {
            upload: () => {
                // return new Promise((resolve, reject) => {
                //   const body = new FormData();
                //   loader.file.then((file) => {
                //     body.append("files", file);
                //     fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
                //       method: "post",
                //       body: body
                //       // mode: "no-cors"
                //     })
                //       .then((res) => res.json())
                //       .then((res) => {
                //         resolve({
                //           default: `${API_URL}/${res.filename}`
                //         });
                //       })
                //       .catch((err) => {
                //         reject(err);
                //       });
                //   });
                // });
            }
        };
    }
    uploadPlugin(editor) {
        console.log("hi");
        //   ClassicEditor
        //   .create( document.querySelector( '#editor' ), {
        //       plugins: [ Image, ImageResize ]

        //   } )
    }

    DisablePrevdate = () => {
        let today = new Date();
        let month = today.getMonth() + 1
        if (month < 10) {
            month = "0" + month
        }
        let day = today.getDate()
        if (day < 10) {
            day = "0" + day
        }
        let date = today.getFullYear() + '-' + month + '-' + day;
        return date;
    }

    getDateTime = () => {
        let today = new Date();
        let month = today.getMonth() + 1
        if (month < 10) {
            month = "0" + month
        }
        let day = today.getDate()
        if (day < 10) {
            day = "0" + day
        }
        let date = today.getFullYear() + '-' + month + '-' + day;
        return date;
    }



    onChange(e) {
        console.log(e.target.getContent());
    }

    NewsModal = (e) => {
        if (this.state.ActiveTeam === 0) {
            this.CloseModal()
            return false;
        }

        this.setState({
            NewsModal: !this.state.NewsModal,
            newsContent: e
        })
    }


    toggle(e) {
        if (this.state.ActiveTeam === 0) {
            this.CloseModal()
            return false;
        }

        this.setState({
            addModal: !this.state.addModal,
            subject: "",
            text: "",
            news_id: "",
            errMsg: {},
            Is_admin: [],
            PublishingDate: this.getDateTime(),
            Disable_Prev_Date: this.DisablePrevdate()
        });

    }

    Editclick(e) {
        if (this.state.ActiveTeam === 0) {
            this.CloseModal()
            return false;
        }
        console.log('edit', e.text)
        this.setState({
            modal: !this.state.modal,
            subject: e.subject,
            text: e.readmore === "" ? e.text : e.readmore,
            news_id: e.id,
            PublishingDate: e.publish_date,
            Disable_Prev_Date: this.DisablePrevdate()
        });
    }

    CloseNewsModal() {
        this.setState({
            modal: !this.state.modal,
            text: ""
        });
    }



    HandleEventType(e) {
        this.setState({
            Event_id: e.event_type_id,
            Event_type: e.Event_type,
            Color_code: e.Color_code,
            default_deadline_days: e.default_deadline_days,
            default_duration_hours: e.default_duration_hours,
            default_infomail_uncertain: e.default_infomail_uncertain,
            default_infomail_unregistered: e.default_infomail_unregistered,
        })
    }

    onChangehandler = (e) => {
        if (e.target.value) {
            this.setState({
                subject: e.target.value,
                errMsg: { subject: '' }
            });
        }
        else {
            this.setState({
                subject: '',
                errMsg: { subject: "Please enter subject" },
            });
        }
    };

    handle_PublishingDate(e) {
        this.setState({
            PublishingDate: e
        }, () => {

        });
        console.log(this.state.PublishingDate)
    }


    handleChangeText(event) {
        this.setState({
            text: event
        })
        // console.log(this.state.text)
    }

    handleChange = (e) => {
        if (e) {
            this.setState({
                Is_admin: [{ value: e.value, label: e.label }],
                errMsg: { Is_Admin: [""] },
            })
        }
        else {
            this.setState({
                errMsg: { Is_Admin: ["Please Select Is_admin"] },
            });
        }
    };

    reloadPage() {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }


    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token

        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    team_name: team_name
                })

            });

    }

    handleAddNews = () => {

        const errMsg = this.state.errMsg;
        if (this.state.subject) {
            if (this.state.text) {
                this.setState({
                    Loader: true
                })
                // console.log("ids", this.state.News_list)
                const formData = new FormData();
                formData.append('team_id', this.props.match.params.id);
                formData.append('subject', this.state.subject);
                formData.append('text', this.state.text);
                formData.append('publish_date', this.state.PublishingDate);
                var token = this.state.token

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                }

                var app_url = APIURL + "news/create";

                axios
                    .post(app_url, formData, config)
                    .then((response) => {
                        console.log(response.data)
                        this.getTeamNews(this.props.match.params.id);
                        this.setState({
                            addModal: false,
                            // News_list:[...this.state.News_list,...response.data.data],
                            Loader: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            error,
                            Loader: false
                        })
                    });

            }
            else {
                errMsg['subject'] = '';
                errMsg['text'] = 'Please enter text';
                this.setState({ errMsg });
            }
        }
        else {
            errMsg['subject'] = 'Please enter subject';
            this.setState({ errMsg });
        }
    }

    removeNewsModal(e, idx) {
        if (this.state.ActiveTeam === 0) {
            this.CloseModal()
            return false;
        }
        console.log(e)
        this.setState({
            newsId: e.id,
            index: idx,
            removeNewsModal: !this.state.removeNewsModal
        })
    }

    RemoveModalClose() {
        this.setState({
            removeNewsModal: false
        })
    }

    handleRemoveNews = () => {


        this.setState({
            Loader: true
        });
        const formData = new FormData();
        formData.append('id', this.state.newsId);
        var token = this.state.token

        /* fetch repos with axios */
        axios
            .post(APIURL + "news/delete", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {

                const newRep = [...this.state.News_list];
                newRep.splice(this.state.index, 1);

                this.setState({
                    News_list: newRep,
                    Loader: false,
                    removeNewsModal: false
                });

            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false
                })
            );
    }

    onSubmit = (e) => {
        this.setState({
            Loader: true
        });
        const formData = new FormData();

        formData.append('subject', this.state.subject);
        formData.append('text', this.state.text);
        formData.append('id', this.state.news_id);
        formData.append('publish_date', this.state.PublishingDate);
        var app_url = APIURL + "news/update";
        var token = this.state.token

        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.getTeamNews(this.props.match.params.id);
                this.setState({
                    msg: response.data.message,
                    // errMsg: '',
                    redirect: true,
                    modal: false,
                    Loader: false
                });
            })
            .catch((error) => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }
            });
    };

    getTeamNews(id) {
        const formData = new FormData();
        formData.append('team_id', id);
        var token = this.state.token
        axios
            .post(APIURL + "news/list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    this.setState({ News_list: response.data.data });
                }
                this.setState({ no_record: true });
            })
            .catch(error => {
                this.setState({ no_record: true })
            })
    }


    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.getTeamNews(this.props.match.params.id);
            this.getTeamDetails();
            this.getExpirationPlanMsg()
        }
    }

    getExpirationPlanMsg = () => {
        console.log(this.state.user.user_id)
        const formData = new FormData();
        formData.append('user_id', this.state.user.user_id);
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("Subscription", response)
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan == "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }

                if (days > 60 || response.data.member.length == 0) {
                    isPermission = 0;
                }
                else if (response.data.member.length == 1) {
                    if (response.data.member[0].is_admin == 0 && response.data.member[0].is_teamleader == 0) {
                        isPermission = 0;
                    }
                }

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission

                })
            });
    }

    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }


    render() {
        const { t } = this.props;
        console.log('------------', this.props, '-----------');
        const { visible } = this.state
        const { navigate } = this.state;

        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <Sidebar
                    data={{
                        // teamInfo: this.teamInfo.bind(this),
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage: "news",
                        visible:this.props.location.visible
                    }} />
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                                <h6 className="mt-3">Upgrade Package.</h6>
                            }
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>
                            }
                            {
                                this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                            }
                            {
                                this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Imenso if the team is to be reactivated.</h6>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                            <Button className="btn btn-danger" onClick={() => this.CloseModal()}>Cancel </Button>
                            {this.state.is_owner === "Yes" ? <Button className="btn btn-success me-3" onClick={() => this.goToSubscription()}> Upgrade Package</Button> : ""}
                        </div>
                    </ModalFooter>
                </Modal>
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        {/* <h4 className="page-title">{this.state.teamInfo.team_name}| Member</h4> */}
                                        <h4 className="page-title">{this.state.team_name} | {t("News")} </h4>

                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-12">
                                            <h6>
                                                <Button
                                                    id="add_attrs"
                                                    onClick={this.toggle}
                                                    className="btn btn-primary waves-effect waves-light mt-0 add_attrs"
                                                >
                                                    <i className="mdi mdi-plus"></i> {t("Add news")}
                                                </Button>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/*<h5>Member</h5>*/}
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <span className="text-success">{this.state.scsMsg}</span>
                                                <Table hover className="table table-hover table-centered dt-responsive nowrap  w-100">
                                                    <thead style={{ backgroundColor: "#0c3047" }}>
                                                        <tr className="fw-medium" >
                                                            <th ></th>
                                                            <th >{t("Author")}</th>
                                                            <th >{t("Subject")}</th>
                                                            <th>{t("Text")}</th>
                                                            <th >{t("Creation date")}</th>
                                                            <th>{t("Publishing date")}</th>
                                                            <td style={{ width: "150px", textAlign: "center" }}>{t("Action")}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {this.state.News_list.length > 0 && this.state.News_list.map((item, idx) => (
                                                            <tr>
                                                                {/* <td>#{item.id}</td> */}
                                                                <td></td>
                                                                <td>{item.author}</td>
                                                                <td>{item.subject} </td>
                                                                <td >
                                                                    <Global
                                                                        styles={css`
                                                                            :root {
                                                                                p {
                                                                                    margin :  0;
                                                                                    padding : 0;
                                                                                    }
                                                                            }
                                                                            .min_ck .ck-editor__editable{
                                                                                min-height:40px;
                                                                            }
                                                                            .ck.ck-editor__editable_inline {
                                                                            padding:0;
                                                                            }
                                                                            .ck.ck-editor__editable_inline>:first-child {
                                                                                margin-top:0;
                                                                            }
                                                                           
                                                                `}
                                                                    />
                                                                    <span className="ck_view min_ck">
                                                                        <CKEditor
                                                                            editor={Editor}
                                                                            config={editorConfigurationView}
                                                                            disabled={true}
                                                                            data={item.text}
                                                                            onReady={editor => {
                                                                                // You can store the "editor" and use when it is needed.
                                                                                console.log('Editor is ready to use!', editor);
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.handleChangeText(data)
                                                                                console.log({ event, editor, data });
                                                                            }}

                                                                            onBlur={(event, editor) => {
                                                                                console.log('Blur.', editor);
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                console.log('Focus.', editor);
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    {item.readmore ? <span><Button onClick={(e) => this.NewsModal(item.readmore)} className="btn btn-link pl-0 fw-bolder" style={{ color: "#f39330" }}>{t("Read more")}</Button></span> : ""}

                                                                </td>
                                                                <td >{dateFormat(item.created_at, "dd.mm.yyyy")}</td>
                                                                <td>{dateFormat(item.publish_date, "dd.mm.yyyy")}</td>
                                                                <td>
                                                                    <Button size="sm" color="warning" className="mobile-icon" onClick={(e) => this.Editclick(item)} > <i className="ri-pencil-fill"></i> <span>{t("Edit")}</span></Button>
                                                                    <Button size="sm" color="danger" className="ms-2 mobile-icon" onClick={() => this.removeNewsModal(item, idx)}>
                                                                        <i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                        {this.state.no_record == true && this.state.News_list.length == 0 &&
                                                            <tr>
                                                                <td colSpan="11">
                                                                    <div className="nodata">
                                                                        <img src={no_img} />
                                                                        <div>{t("No News Added")}</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeNewsModal}  >
                                <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                                    <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                                        <h6 className="mt-3">Are you sure want to remove ?</h6>
                                        <p className="mt-4 col-12">
                                            <Button className="me-4" width="50px" color="light" onClick={() => this.handleRemoveNews()} >Confirm </Button>{' '}
                                            <Button className="" color="default" onClick={() => this.RemoveModalClose()}>Cancel</Button>
                                        </p>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <Modal size="lg" isOpen={this.state.addModal} autoFocus={false}>
                                <ModalHeader toggle={this.toggle}>{t("Add news")}</ModalHeader>
                                <ModalBody >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>{t("Subject")}</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("Enter subject")}
                                                    name="subject"
                                                    required=""
                                                    value={this.state.subject}
                                                    onChange={this.onChangehandler}
                                                    autoFocus={true}
                                                />
                                                <span className="text-danger">{t(this.state.errMsg.subject)}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <Label>{t("Publishing date")}</Label>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    name="date"
                                                    id="example-date"
                                                    defaultValue={this.date}
                                                    min={this.state.Disable_Prev_Date}
                                                    value={this.state.PublishingDate}
                                                    onChange={(e) => this.handle_PublishingDate(e.target.value)}
                                                />
                                                <span className="text-danger">{t(this.state.errMsg.publish_date)}</span>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>{t("Text")}</Label>
                                                <Global
                                                    styles={css`
                                            :root {
                                                p {
                                                    margin : 1px 0;
                                                    padding : 0;
                                                    }
                                            --ck-border-radius: 4px;
                                            --ck-color-focus-border: rgba(142, 140, 140, 0.25);
                                            --ck-color-shadow-inner: rgba(142, 140, 140, 1);
                                            --ck-inner-shadow: 0 0 0 2px var(--ck-color-shadow-inner);
                                            --ck-spacing-large: var(--ck-spacing-standard);
                                            height: 200px;
                                            }
                                            .ck.ck-editor__editable_inline {
                                                
                                            border: 1px solid rgba(142, 140, 140, 0.25);
                                            transition: all 0.3s;
                                            color: rgba(19, 19, 18, 1);
                                            height: 200px;
                                            &:hover {
                                                border-color: rgba(19, 19, 18, 0.25);
                                                border-right-width: 1px !important;
                                              color: rgba(19, 19, 18, 1); !important;

                                            }
                                            }
                                            .ck-editor__editable.ck-read-only {
                                              color: rgba(19, 19, 18, 1);;
                                            opacity: 1;
                                            cursor: not-allowed;
                                             color: rgba(19, 19, 18, 1);
                                             height: 200px;
                                            &:hover {
                                                border-color: rgba(142, 140, 140, 0.25);
                                            }
                                            }
                                        `}
                                                />
                                                <CKEditor
                                                    editor={Editor}
                                                    config={editorConfiguration}
                                                    data={this.state.text}
                                                    onReady={editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        console.log('Editor is ready to use!', editor);
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleChangeText(data)
                                                        // console.log({ event, editor, data });
                                                    }}

                                                    onBlur={(event, editor) => {
                                                        // console.log('Blur.', editor);
                                                    }}
                                                    onFocus={(event, editor) => {
                                                        // console.log('Focus.', editor);
                                                    }}
                                                />
                                                {/* <CKEditor
                                                // config={{ plugins: [ImageResize] }}
                                                 config={{
                                                    // plugins: [ImageResize],
                                                    extraPlugins: [this.uploadPlugin ]
                                                  }}
                                                    style={{ height: "500px" }}
                                                    editor={ Editor }
                                                    data={this.state.text}
                                                    config={{
                                                        
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "|",
                                                            "undo",
                                                            "redo",
                                                            "|",
                                                            "imageTextAlternative",
                                                            "imageUpload",
                                                        ],
                                                        ckfinder: {
                                                    // Upload the images to the server using the CKFinder QuickUpload command
                                                    // You have to change this address to your server that has the ckfinder php connector
                                                    uploadUrl: 'https://ckeditor.com/apps/ckfinder/3.5.0/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'
                                                }
                                                        
                                                    }}
                                                   
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleChangeText(data)
                                                        console.log({ event, editor, data });
                                                    }}

                                                /> */}

                                                <span className="text-danger">{t(this.state.errMsg.text)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>

                                <ModalFooter>
                                    <div className="d-flex justify-content-between w-100">
                                        <Button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </Button>
                                        <Button className="btn btn-success fw-bolder me-3" onClick={() => this.handleAddNews()}>{t("Save")} </Button>
                                    </div>
                                </ModalFooter>
                            </Modal>
                            <Modal size="lg" isOpen={this.state.modal} autoFocus={false}>
                                <ModalHeader toggle={() => this.CloseNewsModal()}>{t("Edit news")}</ModalHeader>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>Subject</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("Enter subject")}
                                                    name="subject"
                                                    required=""
                                                    value={this.state.subject}
                                                    onChange={this.onChangehandler}
                                                    autoFocus={true}
                                                />
                                                <span className="text-danger">{t(this.state.errMsg.subject)}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <Label>{t("Publishing date")}</Label>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    name="date"
                                                    id="example-date"
                                                    min={this.state.Disable_Prev_Date}
                                                    value={this.state.PublishingDate}
                                                    onChange={(e) => this.handle_PublishingDate(e.target.value)}
                                                />
                                                <span className="text-danger">{t(this.state.errMsg.publish_date)}</span>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-4">
                                            <div className="form-group">
                                                <Label>Date</Label>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    name="date"
                                                    id="example-date"
                                                    defaultValue={this.date}
                                                    defaultValue={this.state.PublishingDate}
                                                    value={this.state.PublishingDate}
                                                    onChange={(e) => this.handle_PublishingDate(e.target.value)}
                                                />
                                                <span className="text-danger">{t(this.state.errMsg.subject)}</span>
                                            </div>
                                        </div> */}
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>{t("Text")}</Label>
                                                <Global
                                                    styles={css`
                                            :root {
                                                p {
                                                    margin : 1px 0;
                                                    padding : 0;
                                                    }
                                            --ck-border-radius: 4px;
                                            --ck-color-focus-border: rgba(142, 140, 140, 0.25);
                                            --ck-color-shadow-inner: rgba(142, 140, 140, 1);
                                            --ck-inner-shadow: 0 0 0 2px var(--ck-color-shadow-inner);
                                            --ck-spacing-large: var(--ck-spacing-standard);
                                            height: 200px;
                                            }
                                            .ck.ck-editor__editable_inline {
                                            border: 1px solid rgba(142, 140, 140, 0.25);
                                            transition: all 0.3s;
                                            color: rgba(19, 19, 18, 1);
                                            height: 200px;
                                            &:hover {
                                                border-color: rgba(19, 19, 18, 0.25);
                                                border-right-width: 1px !important;
                                              color: rgba(19, 19, 18, 1); !important;
                                            }
                                            }
                                            .ck-editor__editable.ck-read-only {
                                              color: rgba(19, 19, 18, 1);;
                                            opacity: 1;
                                            cursor: not-allowed;
                                             color: rgba(19, 19, 18, 1);
                                             height: 200px;
                                            &:hover {
                                                border-color: rgba(142, 140, 140, 0.25);
                                            }
                                            }
                                        `}
                                                />
                                                <CKEditor
                                                    editor={Editor}
                                                    config={editorConfiguration}
                                                    data={this.state.text}
                                                    onReady={editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        console.log('Editor is ready to use!', editor);
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleChangeText(data)
                                                        console.log({ event, editor, data });
                                                    }}

                                                    onBlur={(event, editor) => {
                                                        console.log('Blur.', editor);
                                                    }}
                                                    onFocus={(event, editor) => {
                                                        console.log('Focus.', editor);
                                                    }}
                                                />
                                                {/* <CKEditor
                                                    editor={ClassicEditor}
                                                    data={this.state.text}
                                                    config={{
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "|",
                                                            "undo",
                                                            "redo",
                                                            "|",
                                                            "imageTextAlternative",
                                                            "imageUpload",
                                                            "imageStyle:full",
                                                            "imageStyle:side",
                                                            "resizeImage:50",
                                                            "resizeImage:75",
                                                            "resizeImage:original"

                                                        ],
                                                         ckfinder: {
                                                    // Upload the images to the server using the CKFinder QuickUpload command
                                                    // You have to change this address to your server that has the ckfinder php connector
                                                    uploadUrl: 'https://ckeditor.com/apps/ckfinder/3.5.0/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'
                                                },
                                                resizeOptions: [
                                                {
                                                    name: 'resizeImage:original',
                                                    value: null,
                                                    icon: 'original'
                                                },
                                                {
                                                    name: 'resizeImage:50',
                                                    value: '50',
                                                    icon: 'medium'
                                                },
                                                {
                                                    name: 'resizeImage:75',
                                                    value: '75',
                                                    icon: 'large'
                                                }
                                            ],
                                                    }}
                                                    onInit={editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                        console.log("Editor is ready to use!", editor);
                                                        console.log(
                                                            "toolbar: ",
                                                            Array.from(editor.ui.componentFactory.names())
                                                        );
                                                        console.log(
                                                            "plugins: ",
                                                            ClassicEditor.builtinPlugins.map(plugin => plugin.pluginName)
                                                        );
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleChangeText(data)
                                                        console.log({ event, editor, data });
                                                    }}

                                                /> */}
                                                <span className="text-danger">{t(this.state.errMsg.text)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <div className="d-flex justify-content-between w-100">
                                        <Button className="btn btn-danger fw-bolder" onClick={() => this.CloseNewsModal()}> {t("Cancel")} </Button>
                                        <Button className="btn btn-success fw-bolder" onClick={() => this.onSubmit()}>{t("Save")} </Button>
                                    </div>
                                </ModalFooter>
                            </Modal>
                            <Modal size="lg" isOpen={this.state.NewsModal} autoFocus={false}>
                                <ModalHeader toggle={() => this.NewsModal('')}>{t("News Content")}</ModalHeader>
                                <ModalBody className="ck_view" style={{ maxHeight: "65vh", overflow: "auto", }}>
                                    <div className="form-group">
                                        <CKEditor
                                            editor={Editor}
                                            config={editorConfigurationView}
                                            disabled={true}
                                            data={this.state.newsContent}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleChangeText(data)
                                                console.log({ event, editor, data });
                                            }}

                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />

                                        {/*  <div className="col modal_inner-image" dangerouslySetInnerHTML={{ __html: this.state.newsContent }}>
                                         
                                        </div>*/}
                                    </div>
                                </ModalBody>
                                <ModalFooter >
                                    <div className="d-flex justify-content-between w-100 text-center">
                                        <Button className="btn btn-danger" onClick={() => this.NewsModal('')}> Cancel </Button>
                                    </div>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                <div className="rightbar-overlay"></div>
            </div>
        );
    }
}
export default withTranslation()(TeamMembers);