import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import logo_svg from '../../assets/images/sortimensologo.png'
import logo_lg_svg from '../../assets/images/sortimensologo2.png'
import avatar from "../../assets/images/avatar.png"
import switchUserIcon from "../../assets/images/avatar_user.png"
import { Link, Redirect } from "react-router-dom";
import { Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import axios from "axios";
import { APIURL } from '../constants/Common';
import '../../assets/css/Custom.css'
import TeamList from "../TeamList/TeamList";
import Language from "./Language";

import { Trans, withTranslation } from 'react-i18next';

class Sidebar extends Component {
//export default class Sidebar extends Component {
    constructor() {
        super()
        this.state = {
            sidebarClass:"sidebar-disable",
            navigate: false,
            visible: false,
            Fullvisible: false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            switchUser: JSON.parse(localStorage.getItem("switchUser")),
            redirectToHome: false,
            SelectedTeamId: "",
            SelectedTeamName: "",
            Is_Administration: true,
            team_name: "",
            AdminShow: true,
            ShowAdminIcon: true,
            Sub_message: "",
            full_name: "",
            owner_profile: "",
            is_owner: "",
            day: 0,
            upgradePackage: false,
            AccessTo_list: [],
            invitation_Notification:0,
            changeOwner_Notification: 0,
            news_Notification:0,
            document_Notification:0,
            event_Notification: 0,
            AddLanClass:false,
            remaining_days:-1,
            pakageData:null


        };

        this.CloseModal = this.CloseModal.bind(this);
    }
    componentDidMount() {
        this.setState({
            visible: this.props.data.visible,
        },() => {
            if(this.state.visible){
                document.body.classList.add('sidebar-disable');
            }
        })
        this.getAccessToUsers()
        this.myProfileInfo()
        this.remaningDays()
        this.SetTeamId()
        this.getTeamDetails();
        this.updateNotification();
        this.getNotification();
        this.setupBeforeUnloadListener();

      
        if(JSON.parse(localStorage.getItem("language")) === null)
        {
            localStorage.setItem("language",JSON.stringify('en'));
        }

        if(window.innerWidth<786)
        {
          
             this.setState({
                visible: false,
                Fullvisible: true,
            });
        }
         document.body.classList.remove('sidebar-disable');
         document.body.classList.remove('front-end')
    }

    handleAdminShow() {
        this.setState({
            AdminShow: !this.state.AdminShow
        })
    }

    HandleSideBar = () => {

        this.setState({
            visible: !this.state.visible,
        });

        if (this.state.visible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.visible) {
            document.body.classList.add('sidebar-disable');
        }
        if(this.props.data.ActivePage=="regi_deregistration" || this.props.data.ActivePage=="regi_deregistration_dashboard")
        {
           
           this.props.data.handleScrollTop();
        }
    };
    HandleSideBarRes = () => {
        this.setState({
            Fullvisible: !this.state.Fullvisible,
        });
        if (this.state.Fullvisible) {
           document.body.classList.remove('sidebar-disable');
        //    document.body.classList.remove('sidebar-enable');
        }
        if (!this.state.Fullvisible) {
            document.body.classList.add('sidebar-disable');
            document.body.classList.add('sidebar-enable');
        }
    };

    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.removeItem("switchUser");
    //    const check = localStorage.getItem("checkbox");
       // if (check === 'false' || check === false)
            localStorage.removeItem("checkbox");
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("session_login");
        this.setState({
            navigate: true,
        });
    };



    setupBeforeUnloadListener = () => {

        window.addEventListener("scroll", (ev) => {
        ev.preventDefault();
        let session_login= JSON.parse(localStorage.getItem("session_login"));
        if(session_login !== null) 
        {
            if(session_login.rememberMe==false)
            {
                const currentDateTime = new Date();
                const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
                if(currentTimestamp>session_login.expirationTime)
                {
                    this.onLogoutHandler();
                }
            }
          
        }

        });

        let session_login= JSON.parse(localStorage.getItem("session_login"));
        if(session_login !== null) 
        {
            if(session_login.rememberMe==false)
            {
                const currentDateTime = new Date();
                const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
                if(currentTimestamp>session_login.expirationTime)
                {
                    this.onLogoutHandler();
                }
            }
        }
       

       /* window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
        });*/
    };


    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        let user_id = 0;
        if (this.state.switchUser !== null) {
            user_id = this.state.switchUser.user_id;
        }
        if(this.state.user !== null)
        {
           user_id=this.state.user.user_id
        }

        var token = this.state.token


        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let Is_Administration = false;
                let team_name = "";
                if (response.data.data != null) {
                    if (response.data.data.is_teamleader == 1 || response.data.data.is_admin == 1) {
                        Is_Administration = true;
                    }
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    Is_Administration: Is_Administration,
                    team_name: team_name
                })

            });
    }

    SetTeamId(item) {
        if (item) {
            this.setState({
                SelectedTeamId: item.team_info.team_id,
                SelectedTeamName: item.team_info.team_name
            })
        }
    }



    CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    goToSubscription() {
        this.setState({
            upgradePackage: true
        })
    }


    remaningDays = () => {
        const formData = new FormData();
        let user_id = 0;
        if (this.state.switchUser != null) {
            user_id = this.state.switchUser.user_id;
        }
         if(this.state.user!=null)
        {
           user_id=this.state.user.user_id
        }
        formData.append('user_id', user_id);
        // formData.append('team_id',  this.props.data.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/remaningDays", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let days = response.data.remaining_days;
                let Owner = response.data.is_owner;
                let Sub_true = false;
                if (days < 30) {
                    Sub_true = true;
                }
                this.setState({
                    // SubscriptionModal:Sub_true,
                    remaining_days: response.data.remaining_days,
                    pakageData: response.data.data,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active
                })
            });
    }

    // for showing Access to user in dropdown
    getAccessToUsers = () => {
        const formData = new FormData();
       
        var token = this.state.token
        axios
            .post(APIURL + "profile/access_to_users_list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({ AccessTo_list: response.data.data });
                }
            });

    }

    // getting owner details
    myProfileInfo() {
        if (this.state.user) {
            const formData = new FormData();
            formData.append('user_id', this.state.user.user_id);
            var app_url = APIURL + "my_profile_info";
            var token = this.state.token
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    this.setState({
                        full_name: response.data.data.full_name,
                        owner_profile: response.data.data.avatar_picture
                    })
                })
                .catch((error) => {
                    if (error.response.data.message == "validation_error") {
                        this.setState({
                            Loader: false
                        })
                    }
                    else {
                        this.setState({
                            Loader: false
                        })
                    }
                });
        }
    }

    switchUser(id) {
        localStorage.setItem("switchUser", JSON.stringify(id));
        this.setState({
            redirectToHome: true
        })
    }
    switchUserToSelf() {
        localStorage.removeItem('switchUser');
        this.setState({
            redirectToHome: true
        })
    }

       updateNotification = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        let type=null;
       
        if(this.props.data.ActivePage=="news_page")
        {
             type="news";
        }
        else if(this.props.data.ActivePage=="document_page")
        {
             type="document";
        }
        else if(this.props.data.ActivePage=="regi_deregistration")
        {
             type="event";
        }
        else if(this.props.data.ActivePage=="team_request")
        {
             type="change_owner";
        }
        else if(this.props.data.ActivePage=="team_invitation")
        {
             type="team_invitaion";
        } 


        
        formData.append('type', type);
        let user_id=0;
        if(this.state.switchUser!=null)
        {
           user_id=this.state.switchUser.user_id;
        }
         if(this.state.user!=null)
        {
           user_id=this.state.user.user_id
        }

         formData.append('user_id', user_id);
        
       
        var token = this.state.token
     
         if(type!="team_invitaion" && type!="change_owner" )
         {

            axios
            .post(APIURL + "notification/update", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
               
               
            });
        }
          
     }

     getNotification = () => {
        const formData = new FormData();
         formData.append('team_id', this.props.data.teamId);
        let user_id=0;
        if(this.state.switchUser!=null)
        {
           user_id=this.state.switchUser.user_id;
        }
         if(this.state.user!=null)
        {
           user_id=this.state.user.user_id
        }

         formData.append('user_id', user_id);
        
       
        var token = this.state.token
     

        axios
            .post(APIURL + "notification/get-invitation-change-owner", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    invitation_Notification: response.data.invitation,
                    changeOwner_Notification: response.data.changeOwner,
                    news_Notification: response.data.news,
                    document_Notification: response.data.document,
                    event_Notification: response.data.events
                    
               })
               
            });
    }


    render() {
        const { t } = this.props;
        const { user, visible, Fullvisible, AccessTo_list, redirectToHome } = this.state
        const { navigate, upgradePackage } = this.state;
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        if (upgradePackage && this.props.data.ActivePage != "upgrade_package") {
            return <Redirect to={"/upgrade_package"} push={true} />;
        }
        if (redirectToHome) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <>
              
                <div className="navbar-custom">
                    <div className="container-fluid">
                        <ul className="list-unstyled topnav-menu float-end mb-0">
                              <li> <Language /></li>
                            {
                                this.state.remaining_days < 30 && this.state.remaining_days > 0 &&
                                    <li className="Subscription_msg">
                                        <div onClick={() => this.goToSubscription()} className="btn btn-danger mt-3 text-uppercase">
                                            <span className="fw-medium"> Subscription expires in <span className="text-warning" style={{ color: "#ffcc00" }}> {this.state.remaining_days} days</span> - Upgrade Now</span>
                                        </div>
                                    </li>
                                  
                            }
                             {
                                this.state.remaining_days === 0 && this.state.pakageData!=null  &&
                                    <li className="Subscription_msg">
                                        <div onClick={() => this.goToSubscription()} className="btn btn-danger mt-3 text-uppercase">
                                            <span className="fw-medium">Your subscription plan will  expire today - Upgrade Now</span>
                                        </div>
                                    </li>
                            }
                            <li className="dropdown notification-list topbar-dropdown">
                                <UncontrolledDropdown setActiveFromChild>
                                    <DropdownToggle className=" dropdown-toggle me-0 waves-effect waves-light" tag="a"  caret>
                                        <div className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                                            {
                                                this.state.switchUser !== null ? <img src={switchUserIcon} alt="user-image" className="rounded-circle" />:
                                                this.state.owner_profile ?
                                                    <img src={this.state.owner_profile} alt="user-image" className="rounded-circle" /> :
                                                    <img src={avatar} alt="user-image" className="rounded-circle" />
                                            }

                                            {this.state.switchUser == null ?
                                                <b className="pro-user-name ml-1">
                                                    {this.props.data.owner_name ? this.props.data.owner_name : this.state.full_name}
                                                    <i className="mdi mdi-chevron-down"></i>
                                                </b>
                                                :
                                                <b className="pro-user-name ml-1">
                                                    {this.state.switchUser.access_from_name}
                                                    <i className="mdi mdi-chevron-down"></i>
                                                </b>
                                            }
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu style={{ marginLeft: "30px" }}>
                                        {AccessTo_list.length > 0 && AccessTo_list.map((result, index) =>

                                            <div key={result.access_from_name}>
                                                {this.state.switchUser != null && this.state.switchUser.user_id != result.user_id &&
                                                    <DropdownItem className="dropdown-item notify-item" style={{display:"flex",alignItems:"center"}} onClick={() => this.switchUser(result)}>
                                                        <div className="bg-profile-pic cursor-pointer avatar-sm rounded-circle mr-1" style={{ backgroundImage: `url(${switchUserIcon})` }}></div>
                                                        <span >{result.access_from_name}</span>
                                                    </DropdownItem>
                                                }
                                                {this.state.switchUser == null &&
                                                    <DropdownItem className="dropdown-item notify-item" style={{display:"flex",alignItems:"center"}}  onClick={() => this.switchUser(result)}>
                                                        <div className="bg-profile-pic cursor-pointer avatar-sm rounded-circle mr-1" style={{ backgroundImage: `url(${switchUserIcon})` }}></div>
                                                        <span >{result.access_from_name}</span>
                                                    </DropdownItem>
                                                }
                                            </div>

                                        )}
                                        {this.state.switchUser != null &&
                                            <DropdownItem key="puser" className="dropdown-item notify-item" style={{display:"flex",alignItems:"center"}} onClick={() => this.switchUserToSelf()}>
                                               <img src={this.state.owner_profile} alt="user-image" className="bg-profile-pic cursor-pointer avatar-sm rounded-circle mr-1" />
                                                <span >Primary User</span>
                                            </DropdownItem>
                                        }

                                        <DropdownItem className="dropdown-item notify-item" onClick={() => this.onLogoutHandler()} >
                                            <i className="fe-log-out me-1"></i>
                                            <span>Logout</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                        {/* <!-- LOGO --> */}
                        {visible ?
                            <div className={visible ? "logo-box logo-box-width" : "logo-box"}>
                                <Link to="/home" className="logo text-center">
                                    <span  >
                                        <img src={logo_svg} alt="" height="30" />
                                    </span>

                                </Link>
                            </div>
                            :
                            <div className="logo-box">
                                <Link to="/home" className="logo text-center">
                                    <span className="logo-sm" >
                                        <img src={logo_svg} alt="" height="24" />
                                    </span>

                                    <span className="logo-lg">
                                        <img src={logo_lg_svg} alt="" height="24" />
                                    </span>
                                </Link>
                            </div>
                        }

                        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                            <li>
                                <button onClick={() => this.HandleSideBar()} className=" MenuIcon button-menu-mobile waves-effect waves-light">
                                    <i className="fe-menu"></i>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => this.HandleSideBarRes()} className="hidebar button-menu-mobile waves-effect waves-light">
                                    <i className="fe-menu"></i>
                                </button>
                            </li>
                        </ul>
                       
                        <div className="clearfix"></div>
                    </div>
                </div>
                {/* <div className="sidebar-show"> */}
                {
                    Fullvisible ? ""
                        :
                        <div className={visible || Fullvisible ? "left-side-menu hidetxt-Width side-sm" : " left-side-menu "}>
                            {/* <!-- LOGO --> */}
                            <div className="h-100" data-sidebar >
                                {/* <!-- User box --> */}
                                {visible || Fullvisible ? "" :
                                    <div className={visible || Fullvisible ? "user-box text-center hidetxt" : "user-box text-center "}>
                                        <div className="rounded-circle avatar-xl m-auto bg-profile-pic" style={{ backgroundImage: `url(${this.state.switchUser !== null ? switchUserIcon : this.state.owner_profile ? this.state.owner_profile : avatar})` }}>     </div>
                                        <div className="text-reset h5 mt-2 mb-1 d-block">
                                            {this.state.switchUser == null ?
                                                <span className="pro-user-name ms-1" style={{ color: "white" }}>
                                                    {this.props.data.owner_name ? this.props.data.owner_name : this.state.full_name} 
                                                    {/* <i className="mdi mdi-chevron-down"></i> */}
                                                </span>
                                                :
                                                <span className="pro-user-name ms-1" style={{ color: "white" }}>
                                                    {this.state.switchUser.access_from_name}
                                                    {/* <i className="mdi mdi-chevron-down"></i> */}
                                                </span>
                                            }
                                        </div>
                                        <UncontrolledDropdown setActiveFromChild>
                                            <DropdownToggle tag="a" caret>
                                                <div className=" dropdown-toggle text-white" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                                                    {this.state.switchUser == null ?
                                                        <p className="text-reset"><span>{user.email}</span> 
                                                        <i className="mdi mdi-chevron-down"></i>
                                                        </p>
                                                        :
                                                        <p className="text-reset"><span>{this.state.switchUser.access_from_email_id}</span>
                                                         <i className="mdi mdi-chevron-down"></i>
                                                         </p>
                                                    }
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu style={{ marginLeft: "30px" }}>
                                                {AccessTo_list.length > 0 && AccessTo_list.map((result, index) =>
                                                    <div key={result.access_from_name}>
                                                        {this.state.switchUser != null && this.state.switchUser.user_id != result.user_id &&
                                                            <DropdownItem  className="dropdown-item notify-item " style={{display:"flex",alignItems:"center"}} onClick={() => this.switchUser(result)}>
                                                                <div className="bg-profile-pic cursor-pointer avatar-sm rounded-circle mr-1" style={{ backgroundImage: `url(${switchUserIcon})` }}></div>
                                                                <span >{result.access_from_name}</span>
                                                            </DropdownItem>
                                                        }
                                                        {this.state.switchUser == null &&
                                                            <DropdownItem className="dropdown-item notify-item" style={{display:"flex",alignItems:"center"}} onClick={() => this.switchUser(result)}>
                                                                <div className="bg-profile-pic cursor-pointer avatar-sm rounded-circle mr-1" style={{ backgroundImage: `url(${switchUserIcon})` }}></div>
                                                                <span >{result.access_from_name}</span>
                                                            </DropdownItem>
                                                        }
                                                    </div>
                                                )}
                                                {this.state.switchUser != null &&
                                                    <DropdownItem className="dropdown-item notify-item" style={{display:"flex",alignItems:"center"}} onClick={() => this.switchUserToSelf()}>
                                                        <img src={this.state.owner_profile} alt="user-image" className="bg-profile-pic cursor-pointer avatar-sm rounded-circle mr-1" />
                                                        <span >Primary User</span>
                                                    </DropdownItem>
                                                }
                                                <DropdownItem className="dropdown-item notify-item" onClick={() => this.onLogoutHandler()} >
                                                    <i className="fe-log-out me-1"></i>
                                                    <span>Logout</span>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                }


                                {/* <!--- Sidemenu --> */}
                                <div id="sidebar-menu" className="menu">
                                    <ul id="side-menu">
                                        <li className={visible || Fullvisible  ? "menu-title hidetxt" : "menu-title"}><h4 className="header-title"> {t('teams')}</h4></li>
                                        <li>
                                            <TeamList
                                                data={{
                                                    visible: this.state.visible,
                                                    SetTeamId: this.SetTeamId.bind(this),
                                                    ActiveId: this.props.data.teamId,

                                                }}
                                            />
                                        </li>
                                        {
                                            this.state.team_name &&
                                              <React.Fragment>
                                                    <li className={visible || Fullvisible  ? " menu-title mt-2 hidetxt" : "menu-title mt-2"}><h4 className="header-title">{this.state.team_name ? this.state.team_name : <span></span>}</h4></li>
                                                  
                                                        <li className="mainmenu notification_parent">
                                                            <Link
                                                             to={{ pathname: "/regi-deregistration/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                // to={"/regi-deregistration/" + this.props.data.teamId}
                                                                className={this.props.data.ActivePage === "regi_deregistration" ? "waves-effect d-inline-flex active" : "waves-effect d-inline-flex"}
                                                            >
                                                                <i className="ri-calendar-check-line"></i>
                                                                <span className={visible || Fullvisible  ? " hidetxt" : "ms-3 waves-effect "}> {t('Registrations')}</span>
                                                           
                                                                {this.state.event_Notification>0 && this.props.data.ActivePage != "regi_deregistration" &&
                                                                  <span className="badge rounded-pill  bg-danger badge-absolute">{this.state.event_Notification}</span>
                                                                }
                                                            </Link>
                                                            <Link
                                                            to={{ pathname: "/team_news/"+ this.props.data.teamId,  visible: this.state.visible  }}
                                                                // to={"/team_news/" + this.props.data.teamId}
                                                                data={{ team_name: this.props.data.team_name }}
                                                                className={this.props.data.ActivePage === "news_page" ? "waves-effect d-inline-flex active" : "waves-effect d-inline-flex"}

                                                            >
                                                                <i className="ri-article-line"></i>
                                                                <span className={visible || Fullvisible  ? " hidetxt" : "ms-3 "}>  {t('News')} </span>
                                                                 {this.state.news_Notification>0 && this.props.data.ActivePage != "news_page" &&
                                                                  <span className="badge rounded-pill bg-danger badge-absolute">{this.state.news_Notification}</span>
                                                                }
                                                            </Link>
                                                            <Link
                                                             to={{ pathname: "/document-list/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                               
                                                                // to={"/document-list/" + this.props.data.teamId}
                                                                data={{ team_name: this.props.data.team_name }}
                                                                className={this.props.data.ActivePage === "document_page" ? "waves-effect d-inline-flex active" : "waves-effect d-inline-flex"}
                                                            >
                                                                <i className="ri-stack-line"></i>
                                                                <span className={visible || Fullvisible  ? " hidetxt" : "ms-3 "}> {t('Documents / links')} </span>
                                                                 {this.state.document_Notification>0 && this.props.data.ActivePage != "document_page" &&
                                                                 <span className="badge rounded-pill  bg-danger badge-absolute">{this.state.document_Notification}</span>
                                                                }
                                                            </Link>
                                                            <Link 
                                                             to={{ pathname: "/teamlist/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                              
                                                            // to={"/teamlist/" + this.props.data.teamId}
                                                                data={{ team_name: this.props.data.team_name }}
                                                                className={this.props.data.ActivePage === "team_list" ? "waves-effect d-inline-flex active" : "waves-effect d-inline-flex"}

                                                            >
                                                                <i className="ri-user-line"></i>
                                                                <span className={visible || Fullvisible  ? " hidetxt" : "ms-3  "}> {t('Team list')} </span>
                                                            </Link>
                                                            {this.state.Is_Administration && this.state.switchUser == null ?
                                                                <label disabled={this.state.Is_Administration} onClick={() => this.setState({ ShowAdminIcon: !this.state.ShowAdminIcon })} htmlFor="products" data-bs-toggle="collapse" aria-expanded="false" className="waves-effect d-inline-flex" >
                                                                    <i className="ri-vip-crown-line"></i>
                                                                    <span className={visible ? " hidetxt" : "ms-3 semibold "} > {t('Administration')} </span>
                                                                    {this.state.ShowAdminIcon ? <span className={visible ? "hidetxt" : ""}><i style={{ fontSize: "16px" }} className="ri-arrow-down-s-line ml-3"></i></span> : <span className={visible ? "hidetxt" : ""}><i style={{ fontSize: "16px" }} className="ri-arrow-right-s-line ml-3"></i></span>}

                                                                </label> : ""}

                                                            <input checked={this.state.AdminShow} type="checkbox" id={document.body.classList.contains(this.state.sidebarClass) ? "" : "products" }  onChange={() => this.handleAdminShow()} />
                                                            {this.state.Is_Administration && this.state.switchUser == null &&
                                                                <ul className="submenu" style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link 
                                                                           to={{ pathname: "/team_setting/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                            // to={"/team_setting/" + this.props.data.teamId}
                                                                            className={this.props.data.ActivePage === "team_setting" ? "waves-effect d-inline-flex active" : "waves-effect d-inline-flex"}
                                                                        >
                                                                            <i className="ri-tools-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}> {t('Team settings')} </span>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                         to={{ pathname: "/event/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                           
                                                                            // to={"/event/" + this.props.data.teamId}
                                                                            className={this.props.data.ActivePage === "events" ? "waves-effect active" : "waves-effect"}
                                                                        >
                                                                            <i className="ri-calendar-check-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}>{t('Events')} </span>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                        to={{ pathname: "/member/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                          
                                                                            // to={"/member/" + this.props.data.teamId}
                                                                            data={{ team_name: this.props.data.team_name }}
                                                                            className={this.props.data.ActivePage === "members" ? "waves-effect active" : "waves-effect"}
                                                                        >
                                                                            <i className="ri-user-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}>{t('Member')} </span>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                         to={{ pathname:"/news/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                        
                                                                            // to={"/news/" + this.props.data.teamId}
                                                                            data={{ team_name: this.props.data.team_name }}
                                                                            className={this.props.data.ActivePage === "news" ? "waves-effect active" : "waves-effect"}
                                                                        >
                                                                            <i className="ri-article-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}>{t('News')} </span>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                         to={{ pathname:"/documents/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                        
                                                                            // to={"/documents/" + this.props.data.teamId}
                                                                            data={{ team_name: this.props.data.team_name }}
                                                                            className={this.props.data.ActivePage === "document" ? "waves-effect active" : "waves-effect"}
                                                                        >
                                                                            <i className="ri-stack-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}>{t('Documents / links')} </span>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link 
                                                                        to={{ pathname:"/team-mailings/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                        
                                                                        // to={"/team-mailings/" + this.props.data.teamId} data={{ team_name: this.props.data.team_name }} 
                                                                        className={this.props.data.ActivePage === "mailing" ? "waves-effect active" : "waves-effect"}>
                                                                            <i className="ri-mail-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}>{t('Team mailings')} </span>
                                                                        </Link>
                                                                    </li>
                                                                    {/* <li>
                                                                        <Link
                                                                        to={{ pathname:"/eventArchive/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                        
                                                                            // to={"/eventArchive/" + this.props.data.teamId}
                                                                            className={this.props.data.ActivePage === "events_archive" ? "waves-effect active" : "waves-effect"}
                                                                        >
                                                                            <i className="ri-archive-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}>{t('Events archive')}</span>
                                                                        </Link>
                                                                    </li> */}
                                                                    <li>
                                                                        <Link
                                                                            to={{ pathname:"/statistics/" + this.props.data.teamId,  visible: this.state.visible  }}
                                                                            className={this.props.data.ActivePage === "statistics" ? "waves-effect active" : "waves-effect"}
                                                                        >
                                                                            <i className="ri-line-chart-line"></i>
                                                                            <span className={visible || Fullvisible ? " hidetxt" : "ms-3  "}>{t('Statistics')}</span>
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                               
                                                            }
                                                    </li>
                                                     </React.Fragment>
                                            
                                        }
                                        {
                                            this.state.switchUser == null &&
                                            <React.Fragment>
                                                <li className={visible || Fullvisible ? "menu-title hidetxt" : "menu-title"}><h4 className="header-title">{user.full_name}</h4></li>

                                                <li>
                                                    <div className="team-list">
                                                        <Link
                                                        to={{ pathname:"/profile",  visible: this.state.visible  }}
                                                                        
                                                            // to={"/profile"}
                                                            className={ this.props.data.ActivePage === "manage_profile" ? "waves-effect waves-flex active" :"waves-effect waves-flex"
                                                            // this.state.AddLanClass ? "waves-effect waves-flex" : "waves-effect" 
                                                        }
                                                        >
                                                            <i className="ri-settings-3-line"></i>
                                                            <span className={visible || Fullvisible ? " hidetxt" : ""}><span>{t('My profile settings')}</span> </span>
                                                        </Link>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="team-list">
                                                        <Link
                                                            to={{ pathname:"/addteam"  }}
                                                            // to="/addteam"
                                                            className={this.props.data.ActivePage === "add_team" ? "waves-effect waves-flex  active" : "waves-effect waves-flex  "}
                                                        >
                                                            <i className="ri-shield-cross-line"></i>
                                                            <span className={visible || Fullvisible ? " hidetxt" : ""}> <span>{t('Add new team')} </span></span>
                                                        </Link>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="team-list">
                                                        <Link
                                                        to={{ pathname:"/upgrade_package"  }}
                                                            // to={"/upgrade_package"}
                                                            className={this.props.data.ActivePage === "upgrade_package" ? "waves-effect  waves-flex  active" : "waves-effect waves-flex  "}
                                                        >
                                                            <i className="ri-money-dollar-circle-line"></i>
                                                            <span className={visible || Fullvisible ? " hidetxt" : ""}> <span>{t('Update license')} </span></span>
                                                        </Link>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="team-list">
                                                        <Link
                                                            to={"/invitaion"}
                                                            className={this.props.data.ActivePage === "team_invitation" ? "waves-effect waves-flex   active" : "waves-effect waves-flex "}
                                                        >
                                                            <i class="fas fa-user-friends"></i>
                                                            <span className={visible || Fullvisible ? " hidetxt" : ""}>{t('Invitation list')}</span>
                                                            {this.state.invitation_Notification>0  &&
                                                             <span className="badge rounded-pill bg-danger badge-absolute"> <span>{this.state.invitation_Notification}</span></span>
                                                           }

                                                        </Link>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="team-list">
                                                        <Link
                                                            to={"/team_request"}
                                                            className={this.props.data.ActivePage === "team_request" ? "waves-effect waves-flex  active" : "waves-effect waves-flex "}
                                                        >
                                                            <i className="ri-user-shared-2-line"></i>
                                                            <span className={visible || Fullvisible ? " hidetxt" : ""}>{t('Change team owner requests')}</span>
                                                            {/* <span className={visible || Fullvisible ? " hidetxt" : ""}>{t('')}</span> */}
                                                            {this.state.changeOwner_Notification>0  &&
                                                             <span className="badge rounded-pill bg-danger badge-absolute"><span>{this.state.changeOwner_Notification}</span></span>
                                                            }

                                                        </Link>
                                                    </div>
                                                </li>
                                            </React.Fragment>
                                        }
                                        {this.state.switchUser != null &&
                                            <li>
                                                <div className="team-list">
                                                    <Button

                                                        className="btn-link btn" onClick={() => this.switchUserToSelf()}
                                                    >

                                                        
                                                    </Button>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                }

                {/* </div> */}
            </>
        )
    }
}
export default withTranslation()(Sidebar);
