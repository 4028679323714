import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import '../../assets/css/Custom.css'
import Sidebar from "../GeneralComponents/Sidebar";
import axios from "axios";
import { APIURL } from '../constants/Common';
import Footer from '../GeneralComponents/Footer';

export default class Home extends Component {
    constructor() {
        super()
        this.state = {
            navigate: false,
            visible: false,
            Fullvisible:false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            switchUser: JSON.parse(localStorage.getItem("switchUser")),

            TeamList:[]
        };
    }
    
    componentDidMount() {
       this.getTeamList();
    } 

    HandleSideBar = () => {
        this.setState({
            visible: !this.state.visible,
        },  );
        
        if (this.state.visible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.visible) {
            document.body.classList.add('sidebar-disable');
        }
    };
    HandleSideBarRes = () => {
        this.setState({
            Fullvisible: !this.state.Fullvisible,
        });
        if (this.state.Fullvisible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.Fullvisible) {
            document.body.classList.add('sidebar-disable');
        }
    };

    onLogoutHandler = () => {
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };
    

    getTeamList() {
        this.setState({
            Loader:true
        })
         let user_id=0;
        if(this.state.user!=null)
        {
            user_id=this.state.user.user_id;  
        }
        
       
        if(this.state.switchUser!=null)
        {
           user_id=this.state.switchUser.user_id;
        }
        var token = this.state.token;
        axios
            .get(APIURL + "my_teams/" + user_id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
               
                if (response.data.status === 200) {
                    this.setState({
                        TeamList: response.data.data,
                        Loader:false
                     })
                }
                else {
                    this.setState({
                        Loader:false
                    })
                }
            });
    }

    render() {
        if(!this.state.user){
            return <Redirect to="/login" push={true} />;
        }
        else if(this.state.user && this.state.user.user_type=="Admin"){
             return <Redirect to="/admin/team" push={true} />;
        }
        else if(this.state.TeamList.length>0)
        {
           
          return <Redirect to={"/dashboard/"+this.state.TeamList[0]['team_id']} push={true} />;
        }
       

        const { visible  } = this.state
        const { navigate } = this.state;
        if (navigate) {
            alert(this.state.navigate)
            return <Redirect to="/login" push={true} />;
        }      
        return (
            <div id="wrapper">
                
                <Sidebar
                    data={{
                        // teamInfo: this.teamInfo.bind(this),
                        teamId: this.props.match.params.id,
                        team_name:this.state.team_name,
                        visible:this.props.location.visible
                    }} />
               
                <div className={visible ? "content-page content-margin" : "content-page"}>
                
                    <div className="content">

                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">Welcome to TEAMORGANIZER</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <!-- <h4 className="header-title mb-3">Next event</h4> --> */}
                                            
                                            <p>Please select a team or create a new team to perform additional activity.</p>
                                            {/* <!-- <span className="badge bg-success">Registered</span> --> */}
                                        </div>
                                    </div>
                                    {/* </div><!-- end col --> */}
                                </div>
                                {/* <!-- end row --> */}

                            </div>
                            {/* <!-- container --> */}

                        </div>
                        {/* <!-- content --> */}
                    </div>

                    {/* <!-- Footer Start --> */}
                    
                    {/* <!-- end Footer --> */}

                </div>
                

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
            </div>
        );
    }
}
