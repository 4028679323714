    import React, { Component } from "react";
    import axios from "axios";
    import { APIURL } from '../../constants/Common';
    import '../../../assets/css/style.css'
    import '../../../assets/css/bootstrap.min.css'
    import '../../../assets/css/icons.css'
    import '../../../assets/css/Custom.css'
    import { Redirect, Link } from "react-router-dom";
    import Sidebar from "../../Admin/GeneralComponents/Sidebar";
    import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
    import Footer from '../../GeneralComponents/Footer'

   

    export default class Package extends Component {
        constructor(props) {
            super(props);
            this.state = {
                Package_List: [],
                teamId: "",
                description: "",
                name: "",
                function: "",
                msg: "",
                errMsg: {},
                scsMsg: "",
                user: JSON.parse(localStorage.getItem("userData")),
                token: JSON.parse(localStorage.getItem("token")),
                Is_admin: [],
                function_name: [],
                team_name: "",
                package_id: "",
                Loader: false,
                modal: false,
                addModal: false,
                teamAttrs: [
                    { 
                        id: '', 
                        month: '', 
                        price:'', 
                        number:'',
                        defaultValue:'Yes'
                    }
                ],
                removePkgModal:false,
                pkgId:"",
                index:""
            }
            this.toggle = this.toggle.bind(this)
            this.Editclick = this.Editclick.bind(this)
        }

       
        componentDidMount() {
          this.getPackageList();
        }

        toggle(e) {
            this.setState({
                addModal: !this.state.addModal,
                name: "",
                package_id: "",
                description: "",
                teamAttrs: [
                    { 
                        id: '', 
                        month: '', 
                        price:'', 
                        number:'',
                        defaultValue:'Yes'
                    }
                ],
                errMsg: {},
            });
        }

        CloseModal() {
            this.setState({
                modal: !this.state.modal
            });
        }

        Editclick(e) {
            console.log('edit', e, this.state.teamAttrs)
            this.setState({
                modal: !this.state.modal,
                name: e.name,
                description: e.description,
                package_id: e.id,
                errMsg: ""
            });
            const teamAttrs = [];
            const packages = e.license_type;
           
            for (var i = 0; i < packages.length; i++) {
                teamAttrs.push({
                    id:packages[i].id,
                    month:packages[i].duration_month,
                    price: packages[i].amount, 
                    number:packages[i].number_of_teams,
                    defaultValue:packages[i].is_default
                })                 
            }
            this.setState({ 
                teamAttrs: teamAttrs
            })            
        }

        handle_description(e) {
            this.setState({
                description: e
            })
        }

        handle_name(e) {
            this.setState({
                name: e
            })
        }

        updateMonth = (e ,idx) => {
            const teamAttrs = this.state.teamAttrs;
            teamAttrs[idx]['month'] = e.target.value; 
            this.setState({ 
                teamAttrs: teamAttrs
            })
        };
        updatePrice = (e ,idx) => {
            const teamAttrs = this.state.teamAttrs;
            teamAttrs[idx]['price'] = e.target.value; 
            this.setState({ 
                teamAttrs: teamAttrs
            })
        };
        updateNumber = (e ,idx) => {
            const teamAttrs = this.state.teamAttrs;
            teamAttrs[idx]['number'] = e.target.value; 
            this.setState({ 
                teamAttrs: teamAttrs
            })
        };
        updateDefaltvalue = (e ,idx) => {
            console.log('sdfsd', e.target.value, idx)
            const teamAttrs = this.state.teamAttrs;
            for (var i = teamAttrs.length - 1; i >= 0; i--) {
                teamAttrs[i]['defaultValue'] = 'No'
            }
            this.setState({ 
                teamAttrs: teamAttrs
            })
            teamAttrs[idx]['defaultValue'] = e.target.value ? 'Yes' : 'No'; 
            this.setState({ 
                teamAttrs: teamAttrs
            })
        };

        getPackageList() {
            this.setState({
                Loader:true
            })
            const formData = new FormData();
            formData.append('user_id', this.state.user.user_id);
            var token = this.state.token
            axios
                .post(APIURL + "admin/package/list", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (response.data.status === 200) {
                        this.setState({
                            Package_List: response.data.data,
                            Loader:false
                        })
                    }
                    else {
                        this.setState({
                            Loader:false
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        error,
                        Loader: false
                    })
                });
        }

        removePkgModal = (idx,e) =>{
            this.setState({
                team_attrs_id:e.team_attrs_id,
                removePkgModal:!this.state.removePkgModal,
                index:idx ,
                pkgId:e.id

            })
        }
     

        doDelete() {
            this.setState({ Loader: true });
            const formData = new FormData();
            formData.append('id', this.state.pkgId);
            var token = this.state.token

            /* fetch repos with axios */
            axios
            .post(APIURL + "admin/package/delete", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {
                const newRep = [...this.state.Package_List];
                newRep.splice(this.state.index, 1);

                this.setState({
                    Package_List: newRep,
                    Loader: false,
                    removePkgModal:false
                });

            })
            .catch(error =>{
                this.setState({
                   removePkgModal:false,
                   errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
        }

        handleAddTeamAttrs = () => {
            this.setState({
                teamAttrs: this.state.teamAttrs.concat([{ id: '', month: '', price: '', defaultValue: 'No', number: '' }]), errMsgAttr: ''
            });
        }

        removePkgModal = (idx,e) =>{
            this.setState({
                team_attrs_id:e.team_attrs_id,
                removePkgModal:!this.state.removePkgModal,
                index:idx ,
                pkgId:e.id
            })
        }
        RemoveModalClose(){
            this.setState({
                removePkgModal:false
            })
        }

        handleRemoveTeamAttrs = (idx,id) => () => {          
           if(id)
           {
                this.setState({ Loader: true });              
                const formData = new FormData();
                formData.append('id',id);
                var token = this.state.token
                /* fetch repos with axios */
                axios
                    .post(APIURL + "admin/license/delete", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(result => {
                       this.setState({ Loader: false,teamAttrs: this.state.teamAttrs.filter((s, sidx) => idx != sidx) });
                    })
                    .catch(error =>{
                        this.setState({
                           errMsg: { message: error.response.data.message },
                           Loader: false,
                        })
                        setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
                    }
                );
            }
            else
            {
                this.setState({ teamAttrs: this.state.teamAttrs.filter((s, sidx) => idx != sidx) }); 
            }
          
        }

        handleTeamAttrsNameChange = (idx) => (evt) => {
            const newShareholders = this.state.teamAttrs.map((shareholder, sidx) => {
                if (idx !== sidx) return shareholder;
                return { ...shareholder, name: evt.target.value };
            });

            this.setState({ teamAttrs: newShareholders });
        }

        onSubmit = (e) => {
            this.setState({ Loader: true });
            const formData = new FormData();
            formData.append('name', this.state.name);
            formData.append('description', this.state.description);
            for (var i = this.state.teamAttrs.length - 1; i >= 0; i--) {
                formData.append('duration_month[]', this.state.teamAttrs[i]['month']);
                formData.append('amount[]', this.state.teamAttrs[i]['price']);
                formData.append('number_of_teams[]', this.state.teamAttrs[i]['number']);
                formData.append('is_default[]', this.state.teamAttrs[i]['defaultValue']);
            }
            var token = this.state.token
            /* fetch repos with axios */
            axios
                .post(APIURL + "admin/package/create", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(result => {
                    this.getPackageList();
                    this.setState({
                        // redirectToEvent: true,
                        addModal: false,
                        Loader: false,
                        package_id: '',
                        name: '',
                        description: '',
                        teamAttrs: [
                            { 
                                id: '', 
                                month: '', 
                                price:'', 
                                number:'',
                                defaultValue:'No'
                            }
                        ]
                    })

                })
                .catch(error => {
                    // console.log('eeeeeeeeeeeeeeeeeeeee', error)
                    if (error.response.data.message === "validation_error") {
                        // console.log('hi')
                        this.setState({
                            errMsg: error.response.data.errors,
                            Loader: false
                        })
                    }
                    else {
                        // console.log('hiiiiiii')
                        this.setState({
                            error,
                            Loader: false
                        })
                    }

                }
                );
        }

        onEdit = (e) => {
            this.setState({
                Loader: true
            });
            const formData = new FormData();
            formData.append('package_id', this.state.package_id);
            formData.append('name', this.state.name);
            formData.append('description', this.state.description);
            for (var i = this.state.teamAttrs.length - 1; i >= 0; i--) {
                formData.append('id[]', this.state.teamAttrs[i]['id']);
                formData.append('duration_month[]', this.state.teamAttrs[i]['month']);
                formData.append('amount[]', this.state.teamAttrs[i]['price']);
                formData.append('number_of_teams[]', this.state.teamAttrs[i]['number']);
                formData.append('is_default[]', this.state.teamAttrs[i]['defaultValue']);
            }
            var app_url = APIURL + "admin/package/update";
            var token = this.state.token

            axios
                .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.getPackageList();
                    this.setState({
                        // msg: response.data.message,
                        // errMsg: '',
                        // redirect: true,
                        package_id: '',
                        name: '',
                        description: '',
                        teamAttrs: [
                            { 
                                id: '', 
                                month: '', 
                                price:'', 
                                number:'',
                                defaultValue:'No'
                            }
                        ],
                        modal: false,
                        Loader: false
                    });
                })
                .catch((error) => {
                    if (error.response.data.message === "validation_error") {
                        this.setState({
                            errMsg: error.response.data.errors,
                            Loader: false
                        })
                    }
                    else {
                        this.setState({
                            error,
                            Loader: false
                        })
                    }
                });
        };

        render() {
            const { visible } = this.state
            const { navigate } = this.state;
            console.log(this.state.teamAttrs, 'errorrrr', this.state.errMsg.name)
            if (navigate || !this.state.user) {
                return <Redirect to="/login" push={true} />;
            }
            else if (this.state.user.user_type !== 'Admin') {
                return <Redirect to="/login" push={true} />;
            }
            return (
                <div id="wrapper">
                    {
                        this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                    }
                    <Sidebar
                    data={{
                        ActivePage:"package",
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        
                    }} />

                    <div className={visible ? "content-page content-margin" : "content-page"}>
                        <div className="content">
                            {/* <!-- Start Content--> */}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt">
                                            <h4 className="page-title">Super Admin | Packages </h4>
                                        </div>
                                            { this.state.errMsg.message &&
                                            <span className="text-danger">{this.state.errMsg.message}</span>
                                        }

                                    </div>
                                </div>
                                <div className="card p-2">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <div className="row">
                                                    <h5>Package</h5>
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                        <Button
                                                            onClick={this.toggle}
                                                            className="btn btn-primary waves-effect waves-light mt-0 add_attrs"
                                                            >
                                                                <i className="mdi mdi-plus"></i>Add Package
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end col --> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="table-responsive">
                                    {/*<ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button btn btn-warning"
                                        table="table-to-xls"
                                        filename="teamlist"
                                        sheet="teamlist"
                                        buttonText="Download Excel"
                                    />*/}
                                        <Table id="table-to-xls" hover className="table table-hover table-centered nowrap mt-3">
                                            <thead style={{ backgroundColor: "#0c3047" }}>
                                                <tr>
                                                    <th className="fw-medium">#ID</th>
                                                    <th className="fw-medium">Name</th>
                                                    <th className="fw-medium">Description</th>
                                                    <th className="fw-medium text-center" width="170">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {this.state.Package_List.length > 0 ? this.state.Package_List.map((item, idx) => (
                                                <tr className="">
                                                    <td className="fw-medium">{idx+1}</td>
                                                    <td >{item.name}</td>
                                                    <td>{item.description}</td>
                                                    <td className="text-center">
                                                        <Button size="sm" color="warning" onClick={(e) => this.Editclick(item)} >Edit</Button>
                                                        {item.name === 'Demo' ? 
                                                            '' : 
                                                                <Button size="sm" color="danger" className="ms-2" onClick={(e) => this.removePkgModal(idx, item)}>Remove</Button>
                                                        }
                                                    </td>
                                                </tr>
                                                )) :

                                                <tr>
                                                    <td colSpan="7" className="text-center">
                                                        No Packages Available
                                                    </td>
                                                </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal size="md" isOpen={this.state.addModal}  >
                        <ModalHeader toggle={this.toggle} >Add Package</ModalHeader>
                        <ModalBody style={{ backgroundColor: "#376295", maxHeight: "450px", overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="mb-3">
                                        <Label className="form-label">Name</Label>
                                        <Input
                                            type="text"
                                            className="mr-5 mt-1"
                                            id="team_attr"
                                            onChange={(e) => this.handle_name(e.target.value)}
                                            placeholder="Enter name"
                                            value={this.state.name}
                                        />
                                        <span className="text-danger">{this.state.errMsg.name}</span>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Description</Label>
                                        <textarea
                                            className="form-control"
                                            id="example-textarea"
                                            rows="5"
                                            placeholder="Enter description"
                                            value={this.state.description}
                                            onChange={(e) => this.handle_description(e.target.value)}
                                        ></textarea>
                                        <span className="text-danger">{this.state.errMsg.description}</span>
                                    </div>
                                    <div className="mb-3">
                                        <table className="table m-0 table-centered dt-responsive nowrap w-100" cellSpacing="0" id="tickets-table">
                                            <thead>
                                                <th className="pl-0">Month</th>
                                                <th className="pl-0">Price</th>
                                                <th className="pl-0">Number of teams</th>
                                                <th className="pl-0 text-center">Default</th>
                                            </thead>
                                            <tbody className="font-14 text-center">
                                                {this.state.teamAttrs.map((attrs, idx) => (
                                                    <tr key={idx}>
                                                        <td className="pl-0">
                                                             <Input
                                                                type="number"
                                                                className="mr-5 mt-1 form-control form-control-sm input-arrow-hide" 
                                                                min="0"
                                                                onKeyDown={ e => ( e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                                onChange={(e) => this.updateMonth(e ,idx)}
                                                                placeholder="Month"
                                                                value={attrs.month}
                                                            />
                                                        </td>
                                                        <td className="pl-0">
                                                            <Input
                                                                type="number"
                                                                className="mr-5 mt-1 form-control form-control-sm input-arrow-hide" 
                                                                min="0"
                                                                onKeyDown={ e => ( e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                                onChange={(e) => this.updatePrice(e ,idx)}
                                                                placeholder="Price"
                                                                value={attrs.price}
                                                            />
                                                        </td>
                                                        <td className="pl-0">
                                                            <Input
                                                                type="number"
                                                                className="mr-5 mt-1 form-control form-control-sm input-arrow-hide" 
                                                                min="0"
                                                                onKeyDown={ e => ( e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                                onChange={(e) => this.updateNumber(e ,idx)}
                                                                placeholder="Number of teams"
                                                                value={attrs.number}
                                                            />
                                                        </td>
                                                        <td className="text-center vertical-middle pl-0">
                                                            <Input
                                                                type="radio"
                                                                className="mx-auto"
                                                                onChange={(e) => this.updateDefaltvalue(e ,idx)}
                                                                placeholder="default radio"
                                                                checked={attrs.defaultValue === 'Yes'}
                                                            />
                                                        </td>
                                                        <td className="text-center pl-0">
                                                            {idx>0 && 
                                                            <i onClick={this.handleRemoveTeamAttrs(idx,attrs.id)} className="far fa-trash-alt"></i>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                   <td className="text-left" style={{borderBottom:"0"}}>
                                                    <div className="text-danger">
                                                      { 
                                                        this.state.errMsg.duration_month
                                                      }
                                                    </div>
                                                    </td>
                                                    <td className="text-left" style={{borderBottom:"0"}}>
                                                    
                                                     <div className="text-danger">
                                                      { 
                                                        this.state.errMsg.amount
                                                      }
                                                    </div>
                                                    </td>
                                                    <td className="text-left" style={{borderBottom:"0"}}>
                                                     <div className="text-danger">
                                                      { 
                                                        this.state.errMsg.number_of_teams
                                                      }
                                                    </div>
                                                    </td>
                                                    <td colSpan="2" className="text-left" style={{borderBottom:"0"}}>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="text-end mt-3">
                                            <Button
                                                type="button"
                                                id="add_attrs"
                                                onClick={this.handleAddTeamAttrs}
                                                className="btn btn-success waves-effect waves-light add_attrs"
                                            >
                                                Add More +
                                            </Button>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <div className="d-flex justify-content-between w-100">
                                 <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>Cancel </button>
                                 <Button className="btn btn-success fw-bolder" onClick={() => this.onSubmit()}>Save </Button>
                               </div>
                        </ModalFooter>
                    </Modal>

                    <Modal size="md" isOpen={this.state.modal} >
                        <ModalHeader>Edit Package</ModalHeader>
                        <ModalBody style={{ backgroundColor: "#376295", maxHeight: "450px", overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-sm-12">                                
                                    <div className="mb-3">
                                        <Label className="form-label">Name</Label>
                                        <Input
                                            type="text"
                                            className="mr-5 mt-1"
                                            id="team_attr"
                                            onChange={(e) => this.handle_name(e.target.value)}
                                            placeholder="Enter name"
                                            value={this.state.name}
                                            disabled={this.state.name === 'Demo' ? true : false}
                                        />
                                        <span className="text-danger">{this.state.errMsg.name}</span>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Description</Label>
                                        <textarea
                                            className="form-control"
                                            id="example-textarea"
                                            rows="5"
                                            placeholder="Enter description"
                                            value={this.state.description}
                                            onChange={(e) => this.handle_description(e.target.value)}
                                        ></textarea>
                                        <span className="text-danger">{this.state.errMsg.description}</span>
                                    </div>
                                    <div className="mb-3">
                                         { this.state.errMsg.message &&
                                            <span className="text-danger text-center">{this.state.errMsg.message}</span>
                                        }
                                        <table className="table table-hover m-0 table-centered dt-responsive nowrap w-100" cellSpacing="0" id="tickets-table">
                                            <thead>
                                                <th className="pl-0">Month</th>
                                                <th className="pl-0">Price</th>
                                                <th className="pl-0">Number of teams</th>
                                                <th className="pl-0 text-center">Default</th>
                                            </thead>
                                            <tbody className="font-14 text-center">
                                                {this.state.teamAttrs.map((attrs, idx) => (
                                                    <tr key={idx}>
                                                        <td className="pl-0">
                                                             <Input
                                                                type="number"
                                                                className="mr-5 mt-1 form-control input-arrow-hide" 
                                                                min="0"
                                                                onKeyDown={ e => ( e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                                onChange={(e) => this.updateMonth(e ,idx)}
                                                                placeholder="Month"
                                                                value={attrs.month}
                                                            />
                                                        </td>
                                                        <td className="pl-0">
                                                            <Input
                                                                type="number"
                                                                className="mr-5 mt-1 form-control input-arrow-hide" 
                                                                min="0"
                                                                onKeyDown={ e => ( e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                                onChange={(e) => this.updatePrice(e ,idx)}
                                                                placeholder="Price"
                                                                value={attrs.price}
                                                            />
                                                        </td>
                                                        <td className="pl-0">
                                                            <Input
                                                                type="number"
                                                                className="mr-5 mt-1 form-control input-arrow-hide" 
                                                                min="0"
                                                                onKeyDown={ e => ( e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                                                                onChange={(e) => this.updateNumber(e ,idx)}
                                                                placeholder="Number of teams"
                                                                value={attrs.number}
                                                            />
                                                        </td>
                                                        <td className="vertical-middle text-center">
                                                            <Input
                                                                type="radio"
                                                                onChange={(e) => this.updateDefaltvalue(e ,idx)}
                                                                placeholder="default radio"
                                                                checked={attrs.defaultValue === 'Yes'}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            {idx>0 && 
                                                            <i onClick={this.handleRemoveTeamAttrs(idx,attrs.id)} className="far fa-trash-alt"></i>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td className="text-left" style={{borderBottom:"0"}}>
                                                        <span className="text-danger">
                                                            { 
                                                            this.state.errMsg.duration_month
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className="text-left" style={{borderBottom:"0"}}>
                                                        <span className="text-danger">
                                                            { 
                                                            this.state.errMsg.amount
                                                            }
                                                        </span>
                                                    </td>
                                                    <td className="text-left" style={{borderBottom:"0"}}>
                                                        <span className="text-danger">
                                                            { 
                                                            this.state.errMsg.number_of_teams
                                                            }
                                                        </span>
                                                    </td>
                                                    <td colSpan="2" className="text-left" style={{borderBottom:"0"}}>
                                                        <span className="text-danger">
                                                            { 
                                                            this.state.errMsg.is_default
                                                            }
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.name === 'Demo' ? 
                                            '' : 
                                            <div className="text-end mt-3">
                                                <Button
                                                    type="button"
                                                    id="add_attrs"
                                                    onClick={this.handleAddTeamAttrs}
                                                    className="btn btn-success waves-effect waves-light add_attrs"
                                                >
                                                    Add More +
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-between w-100">
                                 <Button className="btn btn-danger" onClick={() => this.CloseModal()}>Cancel </Button>
                                 <Button className="btn btn-success" onClick={() => this.onEdit()}>Save </Button>
                               </div>
                        </ModalFooter>
                    </Modal>
                    <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removePkgModal}  >
                        <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                                <h6 className="mt-3">Are you sure want to remove ?</h6>
                                <p className="mt-4 col-12">
                                    <Button className="mx-2" width="50px" color="light" onClick={()=> this.doDelete()} >Confirm </Button>{' '}
                                    <Button className="mx-2" color="default" onClick={()=> this.RemoveModalClose()}>Cancel</Button>
                                </p>
                            </div>
                        </ModalBody>
                    </Modal>

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                    <div className="rightbar-overlay"></div>
                </div>
            );
        }
    }