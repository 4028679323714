import React, { Component } from "react";
import { Button, Form, Label, Input } from "reactstrap";
import axios from "axios";
import Select from 'react-select'
import {APIURL} from './constants/Common';
import { Redirect } from "react-router-dom";
// import "./signup.css";
// import { Link } from "react-router-dom";
import logoDark from '../assets/images/logo-dark.svg';
import queryString from 'query-string'

import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/icons.css"; 
import ErrorPage from "./GeneralComponents/ErrorPage";

export default class Register extends Component {
  userData;
  constructor(props) {
    super(props);
    this.state = {
      signupData: {
        firstname: "",
        lastname: "",
        address: "",
        zip: "",
        country: "",
        phone: "",
        countrycode: "",
        dob: "",
        redirect: false,
        email: "",
        password: "",
        isLoading: "",
        tnc: "",
      },
      msg: "",
      errMsg: {},
      scsMsg: "",
      startDate: '',
      days: [],
      months: [],
      years: [],
      countries: [],
      countryCode: 0,
      day: 0,
      month: 0,
      year: 0,
      showPassword: false,
      checked: false,
      tokenIsValid:true,
      // startDate: new Date(),
    };

    this.handleCheck = this.handleCheck.bind(this);
  }

  countrySelect = (e) => {
    console.log(e);
    const { signupData } = this.state;
    signupData['country'] = e.value;
    this.setState({ signupData, countryCode: e.code });
  };

  daySelect = (e) => {
    this.state.day = e.value;
    console.log(e);
  };

  monthSelect = (e) => {
    this.state.month = e.value;
    console.log(e);
  };

  yearSelect = (e) => {
    this.state.year = e.value;
    console.log(e);
  };

  onChangehandler = (e, key) => {
    const { signupData } = this.state;
    signupData[e.target.name] = e.target.value;
    this.setState({ signupData });
  };

  onAlertClose = () => {
    this.setState({
      msg: "", 
      scsMsg: "", 
    });
  };

  handleClick = () => {
    // alert(this.state.showPassword)
    this.setState({ showPassword: this.state.showPassword ? false : true });
  };


  onSubmitHandler = (e) => {
    e.preventDefault();
    const { signupData } = this.state;
    if(this.state.year && this.state.month && this.state.day)
      signupData['dob'] = this.state.year+'-'+this.state.month+'-'+this.state.day;

    if(this.state.signupData.phone)
      signupData['countrycode'] = this.state.countryCode;


    signupData['license_type_id'] = "";
    signupData['package_id'] = "";
    const value=queryString.parse(this.props.location.search);
    const token=value.team_member_invitation;
    const success = this.props.location;
    if(token){
      signupData['memberships_token'] = token;
    }

    // console.log(signupData);
    this.setState({ isLoading: true });
    axios
      .post(APIURL+"user-signup", this.state.signupData)
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data.status === 200) {
          this.setState({
            // msg: response.data.message+' Please activate your account from an email',
            signupData: {
              firstname: "",
              lastname: "",
              address: "",
              zip: "",
              country: "",
              phone: "",
              countrycode: "",
              dob: "",
              email: "",
              password: "",
              tnc: ""
            },
          });
          this.setState({
            // msg: 'Your account is created. Please check your mail for the activation link',
          });  
          // setTimeout(() => {
            this.setState({
              redirect: true,
            });
          // }, 2000); 
          /*setTimeout(() => {
            this.setState({ msg: "" });
            window.location.href = "/login"; 
          }, 2000);*/
        }

        if (response.data.status === "failed") {
          this.setState({ 
            scsMsg: "",
            errMsg: response.data.errors,
            // msg: response.data.message
          });
          setTimeout(() => {
            this.setState({ 
              // errMsg: {} 
              // msg: "" 
            });
          }, 2000);
        }
      });
  };

  getDays(){
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = new Date();
    var n = d.getFullYear();
    for (var i = 0; i < 31; i++) {
        this.state.days.push({value: i+1, label: (i+1).toString()})
    } 

    for (var j = 0; j < 12; j++) {
        this.state.months.push({value: j+1, label: months[j]})
    }

    for (var k = n; k > 1900; k--) {
        this.state.years.push({value: k, label: k.toString()})
    }
    // console.log(this.state.days);
    // console.log(this.state.months);
    // console.log(this.state.years);
  }

  getCountries(){
    axios
      .get(APIURL+"get_countries")
      .then((response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.countrys);
          let countries = response.data.countrys;
          for (var c = 0; c < countries.length; c++) {
              this.state.countries.push({value: countries[c].id, label: countries[c].country, code: countries[c].country_code_mobile})
          }
          this.state.countries.sort(function(a, b) {
              var textA = a.label;
              var textB = b.label;
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });

          // console.log(this.state.countries);
        }
      });
      const query=queryString.parse(this.props.location.search);
      console.log('val', query)
      const email=query.email_id;
      const token=query.team_member_invitation;
      const { signupData } = this.state;
      if(email){
        signupData['email'] = email;
      }
      console.log('state', signupData)
  }

  componentDidMount(){
    this.varifyToken()
    this.getDays()
    this.getCountries()
  }

  handleCheck(e){
      console.log(e.target.checked)
      this.setState({
        checked: e.target.checked,
      })
      const { signupData } = this.state;
      signupData['tnc'] = e.target.checked ? "tnc" : "";
      this.setState({ signupData });
   }

  
   varifyToken(){
     const formData = new FormData();
    const value=queryString.parse(this.props.location.search);
    const token=value.team_member_invitation;
     const email_id=value.email_id;
    if(token && email_id){
      const { signupData } = this.state;
      signupData['email'] = email_id;
      this.setState({ signupData });
      formData.append('activation_code', token);
      axios
      .post(APIURL+"user/varify-token",formData
                    )
      .then((response) => {
         if(response.data.data.length==0)
         {
           this.setState({ tokenIsValid:false });
         }
         
      });
    }
    else
     {
       this.setState({ tokenIsValid:false });
     }
   }


  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: '/login', state: { success: 'registered' } }} />;
    }
    const isLoading = this.state.isLoading;
    return (
      <>
      {
        this.state.tokenIsValid==false?
        <ErrorPage />
        :
      <div className="auth-fluid">
        <div className="auth-fluid-right">
          <div className="auth-user-testimonial">
             <h3 className="mb-3 text-white">Organize your teams!</h3>
             <p className="lead fw-normal"><i className="mdi mdi-format-quote-open"></i> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. <i className="mdi mdi-format-quote-close"></i>
             </p>
             <h5 className="text-white">
                Regula PPlacemann - Zürich Oberland Pumas
             </h5>
          </div>
        </div>

        <div className="auth-fluid-form-box">
          <div className="d-flex">
             <div className="card-body">
                <div className="auth-brand text-center text-lg-start">
                   <div className="auth-logo">
                      <a href="index.html" className="text-center">
                        <img src={logoDark} alt="Dark logo" height="22"/>
                      </a>
                   </div>
                </div>
                <h4 className="mt-0 form-heading">Create account</h4>
                <p className=" mb-4"></p>
                <div className={`alert ${this.state.scsMsg ? "alert-success msg-show" : ""} msg-stripe ${this.state.msg ? "alert-danger msg-show" : ""}`} role="alert">
                   <strong></strong> {this.state.scsMsg} {this.state.msg}
                   <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                </div>
                <Form action="#">
                  <div className="mb-3">
                    <div className="row">
                        <div className="col">
                          <Label className="form-label">First Name <span>*</span></Label>
                          <Input
                            className="form-control"
                            required=""
                            type="text"
                            name="firstname"
                            placeholder="Enter first name"
                            value={this.state.signupData.firstname}
                            onChange={this.onChangehandler}
                          />
                          <span className="text-danger">{this.state.errMsg.firstname}</span>
                        </div>
                        <div className="col">
                          <Label className="form-label">Last Name <span>*</span></Label>
                          <Input
                            className="form-control"
                            required=""
                            type="text"
                            name="lastname"
                            placeholder="Enter last name"
                            value={this.state.signupData.lastname}
                            onChange={this.onChangehandler}
                          />
                          <span className="text-danger">{this.state.errMsg.lastname}</span>
                        </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label  className="form-label">Address <span>*</span></Label>
                    <Input
                      className="form-control"
                      required=""
                      type="text"
                      name="address"
                      placeholder="Enter address"
                      value={this.state.signupData.address}
                      onChange={this.onChangehandler}
                    />
                    <span className="text-danger">{this.state.errMsg.address}</span>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Zip / Place <span>*</span></Label>
                    <Input
                      className="form-control"
                      required=""
                      type="text"
                      name="zip"
                      placeholder="Enter zip / place"
                      value={this.state.signupData.zip}
                      onChange={this.onChangehandler}
                    />
                    <span className="text-danger">{this.state.errMsg.zip}</span>
                  </div>
                  <div className="mb-3 zIndex_4" style={{position: "relative"},{zIndex: 4}}>
                    <Label  className="form-label">Country <span>*</span></Label>
                    <Select
                      required=""
                      placeholder="Select Country"
                      className="form-select-control"
                      options={this.state.countries} 
                      value={this.state.countries.label} 
                      onChange={this.countrySelect}
                    />
                    <span className="text-danger">{this.state.errMsg.country}</span>
                  </div>
                  <div className="mb-3">
                     <label htmlFor="phone" className="form-label">Phonenumber</label>
                     <div className="row">
                        <div className="col-3">
                          <Input 
                            className="form-control" 
                            type="text" 
                            disabled
                            value={this.state.countryCode ? this.state.countryCode : ''}
                            placeholder="+00" 
                          />
                        </div>
                        <div className="col-9">
                          <Input 
                            className="form-control input-arrow-hide" 
                            type="number"
                            name="phone"
                            min="0"
                            onKeyDown={ e => ( e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                            placeholder="Enter phone number"
                            value={this.state.signupData.phone}
                            onChange={this.onChangehandler} 
                          />
                        </div>
                     </div>
                    <span className="text-danger">{this.state.errMsg.phone}</span>
                  </div>
                  <div className="mb-3">
                    <Label  className="form-label">Date of birth</Label>
                    <div className="row">
                      <div className="col">
                        <Select
                          placeholder="Select"
                          className="form-select-control"
                          options={this.state.days} 
                          value={this.state.days.label} 
                          onChange={this.daySelect}
                        />
                      </div>
                      <div className="col">
                        <Select
                          placeholder="Select"
                          className="form-select-control"
                          options={this.state.months} 
                          value={this.state.months.label} 
                          onChange={this.monthSelect}
                        />
                      </div>
                      <div className="col">
                        <Select
                          placeholder="Select"
                          className="form-select-control"
                          options={this.state.years} 
                          onChange={this.yearSelect}
                        />
                      </div>
                   </div>

                    <span className="text-danger">{this.state.errMsg.dob}</span>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">E-Mail Address <span>*</span></Label>
                    <Input
                      className="form-control"
                      required=""
                      type="email"
                      name="email"

                      autoComplete="off"
                      placeholder="Enter email address"
                      value={this.state.signupData.email}
                     
                    />
                    <span className="text-danger">{this.state.errMsg.email}</span>
                  </div>
                  <div className="mb-3">
                    <Label  className="form-label">Password <span>*</span></Label>
                    <div className="input-group input-group-merge">
                      <Input
                        className="form-control"
                        required=""
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        autoComplete="new-password"
                        placeholder="Enter password"
                        value={this.state.signupData.password}
                        onChange={this.onChangehandler}
                      />
                      <div className={`${this.state.showPassword ? "show-password" : ""}`} data-password={this.state.showPassword}>
                        <a className="input-group-text" href="javascript: void(0);">
                          <span className="password-eye" onClick={this.handleClick}></span>
                        </a>
                      </div>
                    </div>
                    <span className="text-danger">{this.state.errMsg.password}</span>
                  </div>
                  <div className="mb-3">
                      <div className="form-check">
                        <Input 
                          className="form-check-input" 
                          type="checkbox" 
                          required="" 
                          checked={this.state.checked}
                          onChange={this.handleCheck}
                          id="checkbox-signup" 
                        />
                        <Input 
                          type="hidden" 
                          required="" 
                          name="tnc" 
                          value={this.state.signupData.tnc}
                        />
                        <label className="form-check-label" >
                         I accept the <a href="javascript: void(0);" className="text-white">GTCs</a>
                        </label>
                      </div>
                    <span className="text-danger">{this.state.errMsg.tnc}</span>
                  </div>
                  <div className="d-grid text-center">
                     <Button
                        className="btn btn-primary"
                        color="success"
                        onClick={this.onSubmitHandler}
                        style={{float:"right"}}
                      >
                        Create account
                        {isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm ml-5"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <span></span>
                        )}
                      </Button>
                  </div>
                </Form>
                <span className="text-success">{this.state.msg}</span>
                <div className="text-center mt-5">
                   <p className="">Already an account?<a href="login" className="text-primary fw-medium ms-1">Login here</a></p>
                </div>
             </div>
            </div>
        </div>
      </div>
    }
    </>
    );
  }
}