import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import logo_svg from '../../assets/images/logo-sm.svg'
import logo_lg_svg from '../../assets/images/logo-lg.svg'
import avatar from "../../assets/images/avatar.png"
import { Redirect, Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import TeamList from '../TeamList/TeamList'
import dateFormat from 'dateformat';
import TeamAttributes from "./TeamAttributes";
import axios from "axios";
import { APIURL } from '../constants/Common';
import Sidebar from "../GeneralComponents/Sidebar";
import { Form, Input, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import Select from 'react-select'
import Switch from "react-switch";
import Footer from '../GeneralComponents/Footer';
import { withTranslation } from 'react-i18next';

class Profile extends Component {
    constructor() {
        super()
        var today = new Date();
        var defultMonth = today.getMonth() + 1;

        if (defultMonth < 10) {
            defultMonth = "0" + defultMonth;
        }

        var defultDay = today.getDate();

        if (defultDay < 10) {
            defultDay = "0" + defultDay;
        }

        var todayDate = today.getFullYear() + '-' + defultMonth + '-' + defultDay;

        this.state = {
            todayDate: todayDate,
            teamAttrs: [],
            register_switch: false,
            events_switch: false,
            new_news_switch: false,
            new_documents: false,
            navigate: false,
            visible: false,
            Fullvisible: false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            TeamList: [],
            OwnerTeamList: [],
            MemberTeamList: [],
            team_id: "",
            teamInfo: [],
            uniqueid: "",
            AccessTo_list: [],
            AllowAccess_list: [],
            email_access_to: "",
            errMsg: {},
            Loader: true,
            error: [],
            ManageInfo: {},
            countries: [],
            isLoading: "",
            userInfo: {
                firstname: "",
                lastname: "",
                address: "",
                zip: "",
                place: "",
                country: "",
                phone: "",
                countrycode: "",
                dob: "",
                email: "",
                password: "",
                isLoading: "",
                user_id: "",
                full_name: ""
            },
            errMsg: {},
            scsMsg: "",
            startDate: '',
            days: [],
            months: [],
            years: [],
            countrySelected: {},
            countryCode: 0,
            day: 0,
            month: 0,
            year: 0,
            showPassword: false,
            checked: false,
            bookingDetails: [],
            ChangePicModal: false,
            RemoveAcmodal: false,
            DeleteModal: false,
            avatar_picture: '',
            image: '',
            avatar_picture: '',
            owner_name: "",
            no_record_of_Owner_following_teams: false,
            no_record_of_Member_following_teams: false,
            no_record_of_Access_to: false,
            no_record_of_AllowAccessUsers: false,
            no_record_of_team_specific_configuration: false,
            no_record_of_myprofile: false,
            system_subscription: false,
            user_subscription: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.ChangePicModal = this.ChangePicModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.CloseAcmodal = this.CloseAcmodal.bind(this);
        this.closeChangePicModal = this.closeChangePicModal.bind(this);
    }

    componentDidMount() {
        this.getTeamList()
        this.teamInfo()
        this.getAccessToUsers();
        this.getAllowAccessUsers();
        this.myProfileInfo();
        this.getCountries()
        this.getDays()
        this.getPackage()
    }
    componentWillReceiveProps() {

        this.getDays()
    }
    //   this.state.no_record_of_Owner_following_teams === true || this.state.no_record_of_Member_following_teams === true || this.state.no_record_of_Access_to === true &&

    getTeamAttributes(id) {
        var token = this.state.token
        axios
            .get(APIURL + "team_info/" + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("profile Attributes", response.data)
                if (response.data.status === 200) {
                    this.setState({ teamAttrs: response.data.data.team_attributes });
                }
            })
    }
    onSubmit = (e) => {
        const formData = new FormData();
        formData.append('remindermail_reg_events', this.state.register_switch);
        formData.append('infomail_new_events', this.state.events_switch);
        formData.append('infomail_new_news', this.state.new_news_switch);
        formData.append('infomail_new_documents', this.state.new_documents);
        formData.append('id', this.state.uniqueId);
        formData.append('team_id', this.state.team_id);

        var token = this.state.token
        var app_url = APIURL + "update_team_specific_configuration";
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.data.status === 200) {
                    // this.props.data.teamInfo({team_id:response.data.data});
                    this.getTeamInfo(response.data.data);
                    this.setState({
                        teamId: response.data.data,
                        msg: response.data.message,
                        errMsg: '',
                    });
                }
                if (
                    response.data.status === "failed" &&
                    response.data.success === undefined
                ) {
                    // this.props.data.teamInfo(response.data)
                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.errors,
                    });
                    setTimeout(() => {
                        // this.setState({ errMsgEmail: "", errMsgPwd: "" });
                    }, 2000);
                } else if (
                    response.data.status === "failed" &&
                    response.data.success === false
                ) {
                    // this.props.data.teamInfo(response.data)
                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.message,
                        errMsgEmail: '',
                        errMsgPwd: '',
                    });
                    setTimeout(() => {
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    handle_register_switch() {
        this.setState({
            register_switch: !this.state.register_switch
        })
        console.log(this.state.register_switch)
    }
    handle_events_switch_switch() {
        this.setState({
            events_switch: !this.state.events_switch
        })
        console.log(this.state.events_switch)
    }
    handle_new_news_switch_switch() {
        this.setState({
            new_news_switch: !this.state.new_news_switch
        })
        console.log(this.state.new_news_switch)
    }
    handle_new_documents_switch() {
        this.setState({
            new_documents: !this.state.new_documents
        })
        console.log(this.state.new_documents)
    }

    handleChange(checked) {
        this.setState({ checked });
        console.log(this.state.checked)
    }

    toggle() {
        this.setState({
            RemoveAcmodal: !this.state.RemoveAcmodal
        });
    }

    CloseAcmodal() {
        this.setState({
            RemoveAcmodal: false
        })
    }



    onDeleteClick = (e) => {
        this.setState({
            Loader: true
        })

        const formData = new FormData();


        var token = this.state.token
        axios.post(APIURL + "delete_my_account", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                this.setState({
                    errMsg: response.data.message,
                    RemoveAcmodal: false,
                    errMsg: { message: response.data.message },
                    DeleteModal: true,
                    Loader: false
                });
                setTimeout(() =>
                    this.setState({
                        DeleteModal: false,
                    }, () => {
                        this.onLogoutHandler()
                    })
                    , 2000);

            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: { messageDeelete: error.response.data.message },
                        Loader: false,
                        RemoveAcmodal: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false,
                        RemoveAcmodal: false,
                        errMsg: { messageDeelete: error.response.data.message }
                    })
                }
            }
            );
    };

    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        const check = localStorage.getItem("checkbox");
        if (check == 'false' || check == false)
            localStorage.removeItem("checkbox");
        // localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    ChangePicModal() {
        this.setState({
            ChangePicModal: !this.state.ChangePicModal,
            errMsg: ""
        })
    }

    closeChangePicModal() {
        this.setState({
            ChangePicModal: false,
            errMsg: ""
        })
    }
    handleChangeLogo = (e) => {
        // console.log("imafwe",e.target.files[0])
        this.setState({ image: e.target.files[0] }, () => {
            console.log(this.state.image, 'image1');
        });
    }

    iconDataSubmit = (e) => {
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('avatar_picture', this.state.image);


        var token = this.state.token
        var app_url = APIURL + "change_profile_picture";
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                this.setState({
                    msg: response.data.message,
                    Logo: response.data.data,
                    errMsg: '',
                    Loader: false,
                    ChangePicModal: false,
                    image: ""
                });
                setTimeout(() => {
                    window.location.reload();
                }, 100)
            })
            .catch(error => {
                this.setState({
                    errMsg: { message: error.response.data.errors.avatar_picture },
                    Loader: false
                })
            }
            );
    };
    countrySelect = (e) => {
        console.log(e);
        const { userInfo } = this.state;
        userInfo['country'] = e.value;
        this.setState({
            userInfo,
            countryCode: e.code,
            countrySelected: { label: e.label, value: e.value, code: e.code }
        });
    };

    daySelect = (e) => {
        this.setState({
            day: [{ "value": e.value, 'label': e.label }]
        })
    };

    monthSelect = (e) => {
        this.setState({
            month: [{ "value": e.value, 'label': e.label }]
        })
    };

    yearSelect = (e) => {
        this.setState({
            year: [{ "value": e.value, 'label': e.label }]
        })
    };

    allowAccess(e) { this.setState({ email_access_to: e }) }

    getDays() {
        const { t } = this.props;
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var d = new Date();
        var n = d.getFullYear();
        let daysA = []; let monthsA = []; let yearsA = [];
        daysA.push({ value: "", label: t("Day") })
        monthsA.push({ value: "", label: t("Month") })
        yearsA.push({ value: "", label: t("Year") })
        for (var i = 0; i < 31; i++) {
            daysA.push({ value: i + 1, label: (i + 1).toString() })
        }

        for (var j = 0; j < 12; j++) {
            monthsA.push({ value: j + 1, label: months[j] })
        }

        for (var k = n; k > 1900; k--) {
            yearsA.push({ value: k, label: k.toString() })
        }
        this.setState({
            years: yearsA,
            days: daysA,
            months: monthsA
        })
    }

    getPackage() {
        const formData = new FormData();


        var token = this.state.token
        axios
            .post(APIURL + "booking/details", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("bookingDetails", response.data.data);
                this.setState({
                    bookingDetails: response.data.data,
                })

            });
    }

    getTeamList() {
        if (this.state.user) {

            var token = this.state.token
            axios
                .get(APIURL + "get_team_specific_configuration/" + this.state.user.user_id, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("Team List", response.data.data)
                    if (response.data.status === 200) {
                        this.setState({
                            TeamList: response.data.data

                        })
                    }
                    this.setState({
                        no_record_of_team_specific_configuration: true
                    })

                    let self = this;
                    setTimeout(function () {
                        self.firstLoader();
                    }, 1000);
                });

            const formData = new FormData();

            var app_url = APIURL + "owner_of_teams_list";
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("Owner Team List", response.data.data)
                    if (response.data.status === 200) {
                        this.setState({
                            OwnerTeamList: response.data.data,
                            no_record_of_Owner_following_teams: true
                        })
                    }
                    this.setState({

                        no_record_of_Owner_following_teams: true
                    })
                    let self = this;
                    setTimeout(function () {
                        self.firstLoader();
                    }, 1000);

                });

            axios
                .get(APIURL + "my_teams/" + this.state.user.user_id, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("Member Team List", response.data.data)
                    if (response.data.status === 200) {

                        this.setState({
                            MemberTeamList: response.data.data,
                            no_record_of_Member_following_teams: true
                        })
                    }
                    let self = this;
                    setTimeout(function () {
                        self.firstLoader();
                    }, 1000);

                });


        }
    }

    firstLoader() {

        /* console.log("1", this.state.no_record_of_myprofile);
           console.log( this.state.no_record_of_AllowAccessUsers);
             console.log( this.state.no_record_of_team_specific_configuration);
               console.log( this.state.no_record_of_Owner_following_teams);
                 console.log( this.state.no_record_of_Owner_following_teams);
                   console.log("6",this.state.no_record_of_Access_to);*/

        if (this.state.no_record_of_myprofile == true && this.state.no_record_of_AllowAccessUsers == true && this.state.no_record_of_team_specific_configuration == true && this.state.no_record_of_Owner_following_teams == true && this.state.no_record_of_Access_to == true) {
            this.setState({
                Loader: false
            })
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        // alert(this.state.showPassword)
        this.setState({ showPassword: this.state.showPassword ? false : true });
    };

    getCountries() {
        axios
            .get(APIURL + "get_countries")
            .then((response) => {
                if (response.data.status === 'success') {
                    // console.log(response.data.countrys);
                    let countries = response.data.countrys;
                    for (var c = 0; c < countries.length; c++) {
                        this.state.countries.push({ value: countries[c].id, label: countries[c].country, code: countries[c].country_code_mobile })
                    }
                    this.state.countries.sort(function (a, b) {
                        var textA = a.label;
                        var textB = b.label;
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });

                    // console.log(this.state.countries);
                }
            });
    }

    myProfileInfo() {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (this.state.user) {
            const formData = new FormData();
            var token = this.state.token
            var app_url = APIURL + "my_profile_info";
            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const info = response.data.data;
                    console.log("userinfo", info.firstname, response.data.data)
                    var dob = [];
                    if (info.date_of_birth != '0000-00-00' && info.date_of_birth != '1970-01-01') {
                        dob = info.date_of_birth.split("-"); console.log('date_of_birth', dob)
                        let monthlabel = months[info.date_of_birth.split('-')[1] - 1]
                        console.log(monthlabel)
                        this.setState({
                            owner_name: info.full_name
                        })

                        this.setState({
                            // day: info.date_of_birth.split('-')[2], 
                            // month: info.date_of_birth.split('-')[1], 
                            // year: info.date_of_birth.split('-')[0] ,
                            day: [{
                                'value': info.date_of_birth.split('-')[2],
                                'label': info.date_of_birth.split('-')[2],
                            }],
                            month: [{
                                'value': info.date_of_birth.split('-')[1],
                                'label': monthlabel,
                            }],
                            year: [{
                                'value': info.date_of_birth.split('-')[0],
                                'label': info.date_of_birth.split('-')[0],
                            }]
                        });

                    }
                    // if (response.data.status === 200) {
                    this.setState({
                        no_record_of_myprofile: true,
                        avatar_picture: info.avatar_picture,
                        countrySelected: [{
                            'value': info.country,
                            'label': info.country_name,
                            'code': info.country_code_mobile
                        }],
                        countryCode: info.country_code_mobile,
                        system_subscription: info.system_subscription,
                        user_subscription: info.user_subscription,
                        userInfo: {
                            // user_id: info.user_id,
                            firstname: info.first_name,
                            lastname: info.last_name,
                            address: info.address,
                            zip: info.postcode,
                            place: info.place,
                            country: info.country,
                            phone: info.phone,
                            countrycode: info.country_code_mobile,
                            dob: "",
                            email: info.email,
                            password: "",
                            isLoading: "",
                            avatar_picture: info.avatar_picture,
                            full_name: info.full_name,

                        },
                    })
                    let self = this;
                    setTimeout(function () {
                        self.firstLoader();
                    }, 1000);

                })
                .catch((error) => {
                    if (error.response.data.message == "validation_error") {
                        this.setState({
                            // errMsg: error.response.data.errors,
                            Loader: false
                        })
                    }
                    else {
                        this.setState({
                            // errMsg: {'email_access_to': error.response.data.message},
                            Loader: false
                        })
                    }
                });
        }
    }



    handleAllowAccess = () => {
        // this.reloadPage()
        console.log('save', this.state);
        const errMsg = this.state.errMsg;
        this.setState({
            Loader: true
        })
        // console.log("ids", this.state.DocTypes_list)
        const formData = new FormData();

        formData.append('email_access_to', this.state.email_access_to);
        formData.append('email_access_from', this.state.user.email);

        var token = this.state.token
        var app_url = APIURL + "profile/access_to";

        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log('succcsssss', response.data)
                this.getAllowAccessUsers();
                this.setState({
                    // addModal: false,
                    errMsg: '',
                    email_access_to: '',
                    Loader: false
                })

            })
            .catch((error) => {
                console.log('erroorrrr', error.response)
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        errMsg: { 'email_access_to': error.response.data.message },
                        Loader: false
                    })
                }
            });
    }

    getAccessToUsers = () => {
        const formData = new FormData();


        var token = this.state.token
        axios
            .post(APIURL + "profile/access_to_users_list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({ AccessTo_list: response.data.data });
                    console.log("accessss", this.state.AccessTo_list)
                }
                this.setState({ no_record_of_Access_to: true });

                let self = this;
                setTimeout(function () {
                    self.firstLoader();
                }, 1000);

            });

    }

    resendEmail(e) {
        console.log('resend', e);
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('email_access_to', e.email_access_to);
        formData.append('email_access_from', e.email_access_from);

        var token = this.state.token
        axios
            .post(APIURL + "profile/resend_access_to", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    Loader: false,
                    scsMsg: response.data.message,
                })
                setTimeout(() => {
                    this.setState({
                        scsMsg: '',
                    })
                }, 2000);
            });
    }

    getAllowAccessUsers = () => {
        const formData = new FormData();


        var token = this.state.token
        axios
            .post(APIURL + "profile/allow_access_to_users_list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({ AllowAccess_list: response.data.data });
                }
                this.setState({ no_record_of_AllowAccessUsers: true });
                let self = this;
                setTimeout(function () {
                    self.firstLoader();
                }, 1000);
            });

    }

    AllowAccessDelete(index, item) {
        this.setState({ Loader: true });
        // console.log('delte', index, item)
        const formData = new FormData();
        formData.append('id', item.id);


        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "profile/allow_access_to_user_delete", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {

                const newRep = [...this.state.AllowAccess_list];
                newRep.splice(index, 1);

                this.setState({
                    AllowAccess_list: newRep,
                    Loader: false
                });

            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false
                })
            );
    }
    HandleSideBar = () => {
        this.setState({
            visible: !this.state.visible,
        });

        if (this.state.visible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.visible) {
            document.body.classList.add('sidebar-disable');
        }
    };
    HandleSideBarRes = () => {
        this.setState({
            Fullvisible: !this.state.Fullvisible,
        });
        if (this.state.Fullvisible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.Fullvisible) {
            document.body.classList.add('sidebar-disable');
        }
    };

    onLogoutHandler = () => {
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    teamInfo(item) {
        console.log("manage profile", item)
        if (item) {
            this.setState({
                ManageInfo: item
            })
        }
    }

    onChangehandler = (e, key) => {
        const { userInfo } = this.state;
        userInfo[e.target.name] = e.target.value;
        this.setState({ userInfo });
    };

    onSubmitHandler = (e) => {
        e.preventDefault();
        const { userInfo } = this.state;
        if (this.state.year && this.state.month && this.state.day)
            userInfo['dob'] = this.state.year[0].value + '-' + this.state.month[0].value + '-' + this.state.day[0].value;

        // if(this.state.userInfo.phone)
        userInfo['countrycode'] = this.state.countryCode;

        var token = this.state.token
        // console.log(userInfo);
        this.setState({ Loader: true });
        axios
            .post(APIURL + "update_my_profile", this.state.userInfo, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Loader: false });
                if (response.data.status === 200) {
                    this.setState({
                        errMsg: {},
                        redirect: true,
                    }, () => {
                        this.myProfileInfo();
                    });
                }
            })
            .catch((error) => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: { msg: error.response.data.errors },
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        errMsg: { msg: error.response.data.error },
                        Loader: false
                    })
                }
            });
    };

    showProfilePic = () => {
        this.setState({
            viewProfileModal: !this.state.viewProfileModal
        })
    }


    handleStep(type, index) {
        const sort_List = this.state.MemberTeamList;
        let sortRow = []; let selfsort; let othersort;

        if (type == "up") {

            const self = sort_List[index];
            const other = sort_List[index - 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index - 1] = self;
            sort_List[index] = other;


            this.setState({
                MemberTeamList: sort_List
            })

        }
        else if (type == "down") {
            const self = sort_List[index];
            const other = sort_List[index + 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index + 1] = self;
            sort_List[index] = other;


            this.setState({
                MemberTeamList: sort_List
            })
        }


    }

    handleOrder() {

        this.setState({
            Loader: true
        })

        var token = this.state.token
        const formData = new FormData();

        formData.append('teams', JSON.stringify(this.state.MemberTeamList))
        axios.post(APIURL + "profile/update-team-order", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                this.setState({
                    Loader: false
                })
            })
            .catch(error => {

                this.setState({
                    Loader: false
                })
            }
            );
    }

    handleAutoLicense(e) {

        this.setState({
            Loader: true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('subscription', e)
        axios.post(APIURL + "profile/update-subscription", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                var subscription = "deactive";
                if (this.state.user_subscription == "deactive") {
                    subscription = "active";
                }
                this.setState({
                    Loader: false,
                    user_subscription: subscription
                })

            })
            .catch(error => {

                this.setState({
                    Loader: false
                })
            }
            );
    }




    render() {
        const { t } = this.props;
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        const { user, visible, Fullvisible, AllowAccess_list, AccessTo_list } = this.state
        const { navigate, OwnerTeamList, MemberTeamList } = this.state;

        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        const isLoading = this.state.isLoading;
        return (
            <div id="wrapper">
                {
                    this.state.Loader === true &&
                    <div className="loader">
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                }
                <Sidebar
                    data={{
                        // teamInfo: this.teamInfo.bind(this),
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        owner_name: this.state.owner_name,
                        avatar_img: this.state.userInfo.avatar_picture,
                        visible:this.props.location.visible
                    }} />
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <div className="d-flex align-items-center">
                                            <h4 className="page-title">{t("Imenso profile")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-sm-12">
                                                    <div className="text-center">
                                                    {
                                                            this.state.avatar_picture ?
                                                            <div className="rounded-circle cursor-pointer avatar-xl m-auto" style={{border: ".25rem solid #19435e"}}>
                                                                <img src={this.state.avatar_picture} onClick={() => this.showProfilePic()} className="avatar-img" alt="Profile-image" />
                                                            </div>
                                                                
                                                            :
                                                            <div className="rounded-circle cursor-pointer avatar-xl m-auto" style={{border: ".25rem solid #19435e"}}>
                                                                <img src={avatar} className="avatar-img" />
                                                            </div>
                                                                
                                                        }

                                                        <h6 className="mt-3 mb-0">{this.state.userInfo.full_name}</h6>
                                                        <p className="">{this.state.userInfo.email}</p>
                                                    </div>
                                                    <Modal size="md" isOpen={this.state.viewProfileModal} toggle={() => this.showProfilePic()}>
                                                        <ModalHeader className="header-less" toggle={() => this.showProfilePic()}></ModalHeader>
                                                        <img width="500" height="auto" src={this.state.avatar_picture} />
                                                    </Modal>
                                                    <div className="text-start text-center mt-2 mb-3">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center mt-1">
                                                <h6 className="mb-0">{t("License")}</h6>
                                                {this.state.system_subscription == "active" &&
                                                    <React.Fragment>
                                                        {this.state.user_subscription == "active" ?
                                                            <div className="mt-1">{t("License")} Auto
                                                                <Switch
                                                                    checked={true}
                                                                    onChange={(e) => this.handleAutoLicense(e)}
                                                                    onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={10}
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                    height={15}
                                                                    width={30}
                                                                    className="form-check-input ms-2"
                                                                />
                                                            </div>
                                                            :
                                                            <div className="mt-1">{t("License")} Auto
                                                                <Switch
                                                                    checked={false}
                                                                    onChange={(e) => this.handleAutoLicense(e)}
                                                                    onColor="#86d3ff"
                                                                    onHandleColor="#2693e6"
                                                                    handleDiameter={10}
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                    height={15}
                                                                    width={30}
                                                                    className="form-check-input ms-2"
                                                                />
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                }



                                                {this.state.bookingDetails.length > 0 && this.state.bookingDetails.map((result, index) =>
                                                    <div className="col-sm-12 mt-1">
                                                        {result.license_type.license_package.name != "Unlimited" &&
                                                            <React.Fragment>
                                                                {this.state.todayDate <= result.end_date &&
                                                                    <React.Fragment>
                                                                        <div> License of {result.number_of_team} teams for {result.month / 12} {result.month > 12 ? "years" : "year"} </div>
                                                                        <div>{result.start_date.split('-')[2]}.{result.start_date.split('-')[1]}.{result.start_date.split('-')[0]}
                                                                            - {result.end_date.split('-')[2]}.{result.end_date.split('-')[1]}.{result.end_date.split('-')[0]}
                                                                        </div>
                                                                        {/*<div> ({dateFormat(result.start_date, "dd.mm.yyyy")} - {dateFormat(result.end_date, "dd.mm.yyyy")})</div>*/}
                                                                    </React.Fragment>
                                                                }
                                                                <div className="my-2">
                                                                    <div className=" mt-3">
                                                                    </div>
                                                                    <Link to="/upgrade_package" className=" add_attrs btn btn-warning">
                                                                        {t("Add license")}
                                                                    </Link>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        {result.license_type.license_package.name == "Unlimited" &&
                                                            <React.Fragment>
                                                                {this.state.todayDate <= result.end_date &&
                                                                    <React.Fragment>
                                                                        <div>License of Unlimited teams for {result.month / 12} {result.month > 12 ? "years" : "year"}</div>
                                                                        <div>{result.start_date.split('-')[2]}.{result.start_date.split('-')[1]}.{result.start_date.split('-')[0]}
                                                                            - {result.end_date.split('-')[2]}.{result.end_date.split('-')[1]}.{result.end_date.split('-')[0]}
                                                                        </div>
                                                                        {/* <div> ({dateFormat(result.start_date, "dd.mm.yyyy")} - {dateFormat(result.end_date, "dd.mm.yyyy")})</div>*/}
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                        {this.state.todayDate > result.end_date &&
                                                            <React.Fragment>
                                                                <div> Your subscription plan has expired. Please purchase a plan to reactivate the team.</div>
                                                            </React.Fragment>
                                                        }

                                                    </div>
                                                )}
                                                {this.state.bookingDetails.length == 0 &&
                                                    <div className="col-sm-12 mt-1">
                                                        <div> No Plan </div>

                                                        <div className="my-2">
                                                            <Link to="/upgrade_package" className="mt-2 add_attrs">
                                                                <i className="mdi mdi-plus-circle"></i>{t("Add license")}
                                                            </Link>
                                                        </div>
                                                    </div>

                                                }

                                            </div>

                                        </div>
                                    </div>
                                    {/* <!-- end card-box --> */}

                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title">{t("Owner of the following teams")}</h4>
                                            <p className="mb-3">{t("Teams can be managed via team settings")}</p>
                                            <ul className="list-group text-center">
                                                {OwnerTeamList.length > 0 && OwnerTeamList.map((result, index) =>
                                                    <li className="list-group-item"><i className="mdi mdi-account-group mx-1"></i>
                                                        {result.team_name}
                                                    </li>
                                                )}
                                                {this.state.no_record_of_Owner_following_teams == true && OwnerTeamList.length == 0 &&
                                                    <li className="list-group-item" >No Teams available</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- end card-box--> */}

                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title">{t("Member of the following teams")}</h4>
                                            <p className=" mb-3">{t("Are you missing a team? Ask the team administrator to add your e-mail address to the team.")}</p>

                                            <div className="custom-dd dd" id="nestable_list_1">
                                                <ul className="list-group text-center">
                                                    {MemberTeamList.length > 0 && MemberTeamList.map((result, index) =>
                                                        <li className="list-group-item" data-id={result.id}>
                                                            {index != 0 &&
                                                                <i onClick={(e) => this.handleStep('up', index)} className="mdi mdi-arrow-up"></i>
                                                            }
                                                            {MemberTeamList.length != index + 1 &&
                                                                <i onClick={(e) => this.handleStep('down', index)} className="mdi mdi-arrow-down"></i>
                                                            }
                                                            <div className="dd-handle">
                                                                <i className="mdi mdi-account-group"></i> {result.team_name}
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                                <ul className="pl-0 list-group text-center">
                                                    {this.state.no_record_of_Member_following_teams == true && !MemberTeamList.length &&
                                                        <li className="list-group-item" >No Teams available</li>
                                                    }
                                                </ul>
                                            </div>
                                            {this.state.no_record_of_Member_following_teams == true && MemberTeamList.length > 0 &&
                                                <button type="button" onClick={(e) => this.handleOrder()} className="btn btn-success waves-effect waves-light mt-2 fw-bolder">{t("Save order")}</button>
                                            }
                                        </div>
                                        {/* <!-- end card-box--> */}
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title">{t("Access to the following accounts")}</h4>
                                            <p className="mb-3">{t("With your profile you are allowed to manage the following users")}</p>
                                            <ul className="list-group text-center">
                                                {AccessTo_list.length > 0 && AccessTo_list.map((result, index) =>
                                                    <li className="list-group-item"><i className="mdi mdi-account"></i> {result.access_from_name} ({result.access_from_email_id})</li>
                                                )}
                                                {this.state.no_record_of_Access_to == true && !AccessTo_list.length &&
                                                    <li className="list-group-item">No List Found</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- end card-box--> */}

                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title">{t("Delete account")}</h4>
                                            <p className="mb-3">{t("I would like to delete my acccount completely")}</p>
                                            {this.state.errMsg.messageDeelete &&
                                                <div className="text-danger">{this.state.errMsg.messageDeelete}</div>
                                            }
                                            <div className="text-right">
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger waves-effect waves-light mt-2 fw-bolder"
                                                    onClick={() => this.toggle()}
                                                >{t("Delete your account")}</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end card-box--> */}
                                </div>
                                {/* <!-- end col--> */}

                                <div className="col-sm-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <div id="settings">
                                                <form>
                                                    <h6 className="mb-3  bg-light p-2">
                                                        <i className=" ri-image-fill  me-1"></i> {t("PROFILE PICTURE")}
                                                    </h6>

                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-2 form-group">
                                                                <label htmlFor="firstname" className="form-label"> {t("Change profile picture")}</label>
                                                                <Input
                                                                    type="file"
                                                                    onChange={this.handleChangeLogo}
                                                                    className="form-control"
                                                                    id="fileinput"
                                                                    style={{ paddingLeft: "1.25rem" }}
                                                                /></div>
                                                            {this.state.errMsg.message &&
                                                                <div className="text-danger">{t(this.state.errMsg.message)}</div>
                                                            }
                                                            ({t("Only png, jpg, jpeg format and size upto 2 MB is allowed")})
                                                        </div>
                                                    </div>
                                                    <div className="text-end">
                                                        <Button className="btn btn-success waves-effect waves-light mt-2 fw-bolder" onClick={() => this.iconDataSubmit()}>{t("Save")} </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div id="settings">

                                                <h6 className="mb-3  bg-light p-2"><i className="mdi mdi-account-circle me-1"></i> {t("User data")}</h6>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <label htmlFor="firstname" className="form-label">{t("Username")}<big className="text-danger ">*</big></label>
                                                            <Input
                                                                className="form-control"
                                                                type="email"
                                                                name="username"
                                                                placeholder={t("Enter username")}
                                                                value={this.state.userInfo.email}
                                                                onChange={this.onChangehandler}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <label htmlFor="lastname" className="form-label">{t("E-Mail Address")}<big className="text-danger ">*</big></label>
                                                            <Input
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                placeholder={t("Enter e-mail address")}
                                                                value={this.state.userInfo.email}
                                                                onChange={this.onChangehandler}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <!-- end col --> */}
                                                </div>
                                                {/* <!-- end row --> */}

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <label htmlFor="firstname" className="form-label">{t("First name")}<big className="text-danger ">*</big></label>
                                                            <Input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                name="firstname"
                                                                placeholder={t("Enter first name")}
                                                                value={this.state.userInfo.firstname}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.firstname)}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <label htmlFor="lastname" className="form-label">{t("Surname")}<big className="text-danger ">*</big></label>
                                                            <Input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                name="lastname"
                                                                placeholder={t("Enter last name")}
                                                                value={this.state.userInfo.lastname}
                                                                onChange={this.onChangehandler}
                                                            />

                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.lastname)}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <!-- end col --> */}
                                                </div>
                                                {/* <!-- end row --> */}

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="address" className="form-label">{t("Address")}<big className="text-danger ">*</big></label>
                                                            <Input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                name="address"
                                                                placeholder={t("Enter address")}
                                                                value={this.state.userInfo.address}
                                                                onChange={this.onChangehandler}
                                                            />

                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.address)}</span>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* /</div><!-- end row --> */}

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <label htmlFor="zip" className="form-label">{t("ZIP")}<big className="text-danger ">*</big></label>
                                                            <Input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                name="zip"
                                                                placeholder={t("Enter ZIP / place")}
                                                                value={this.state.userInfo.zip}
                                                                onChange={this.onChangehandler}
                                                            />

                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.zip)}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-2">
                                                            <label htmlFor="place" className="form-label">{t("Place")}<big className="text-danger ">*</big></label>
                                                            <Input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                name="place"
                                                                placeholder={t("Place")}
                                                                value={this.state.userInfo.place}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.place)}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <!-- end col --> */}
                                                </div>
                                                {/* <!-- end row --> */}

                                                <div className="row">
                                                    <div className="col-12 view-element">
                                                        <div className="mb-2">
                                                            <label htmlFor="country" className="form-label">{t("Country")}<big className="text-danger ">*</big></label>
                                                            <Select
                                                                required=""
                                                                placeholder="Select Country"
                                                                className="form-select-control"
                                                                options={this.state.countries}
                                                                value={this.state.countrySelected}
                                                                onChange={this.countrySelect}
                                                            />
                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.country)}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- end row --> */}

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="phone" className="form-label">{t("Phone")}</label>
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        value={this.state.countryCode ? this.state.countryCode : ''}
                                                                        placeholder="+00"
                                                                    />
                                                                </div>
                                                                <div className="col-9">
                                                                    <Input
                                                                        className="form-control input-arrow-hide"
                                                                        type="number"
                                                                        name="phone"
                                                                        min="0"
                                                                        onKeyDown={e => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                                        placeholder={t("Enter phone number")}
                                                                        value={this.state.userInfo.phone}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.phone)}</span>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <!-- end row --> */}

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="firstname" className="form-label">{t("Date of birth")}</label>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <Select
                                                                        placeholder={t("Day")}
                                                                        className="form-select-control"
                                                                        options={this.state.days}
                                                                        value={this.state.day}
                                                                        onChange={this.daySelect}
                                                                        autoComplete={'nope'}
                                                                    />
                                                                </div>
                                                                <div className="col">
                                                                    <Select
                                                                        placeholder={t("Month")}
                                                                        className="form-select-control"
                                                                        options={this.state.months}
                                                                        value={this.state.month}
                                                                        onChange={this.monthSelect}
                                                                        autoComplete={'nope'}
                                                                    />
                                                                </div>
                                                                <div className="col">
                                                                    <Select
                                                                        ref={ref => {
                                                                            this.selectRef = ref;
                                                                        }}
                                                                        placeholder={t("Years")}
                                                                        className="form-select-control"
                                                                        options={this.state.years}
                                                                        value={this.state.year}
                                                                        onChange={this.yearSelect}
                                                                        autoComplete={'nope'}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.dob)}</span>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* <!-- end row --> */}

                                                <div className="row">

                                                    <div className="col-12">
                                                        <div className="mb-2">
                                                            <label htmlFor="userpassword" className="form-label">{t("Password")}</label>
                                                            <div className="input-group input-group-merge">
                                                                <Input
                                                                    className="form-control"
                                                                    type={this.state.showPassword ? "text" : "password"}
                                                                    name="password"
                                                                    placeholder={t("Enter password")}
                                                                    value={this.state.userInfo.password}
                                                                    onChange={this.onChangehandler}
                                                                />
                                                                <div className={`${this.state.showPassword ? "show-password" : ""}`} data-password={this.state.showPassword}>
                                                                    <Button className="input-group-text">
                                                                        <span className="password-eye" onClick={(e) => this.handleClick(e)} ></span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {this.state.errMsg.msg &&
                                                                <span className="text-danger">{t(this.state.errMsg.msg.password)}</span>
                                                            }
                                                            <span className="form-text "><small>({t("Leave empty to not change the password")}.)</small></span>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end col --> */}
                                                </div>
                                                {/* <!-- end row --></div> */}

                                                <div className="text-end">
                                                    <Button
                                                        className="btn btn-success fw-bolder"
                                                        color="success"
                                                        onClick={this.onSubmitHandler}
                                                        style={{ float: "right" }}
                                                    >
                                                        {t("Save")}
                                                        {isLoading ? (
                                                            <span
                                                                className="spinner-border spinner-border-sm ml-5"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </Button>
                                                </div>

                                            </div>
                                            {/* <!-- end settings content--> */}

                                        </div>
                                    </div>
                                    {/* <!-- end card--> */}

                                    {/* <div className="card">
                                        <div className="card-body">
                                            <div id="settings">
                                                <form>
                                                    <h4 className="header-title">{t("Language")}</h4>

                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-2">
                                                                <label htmlFor="firstname" className="form-label">{t("Change the language")}</label>
                                                                <select className="form-control">
                                                                    <option>English</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-success waves-effect waves-light mt-2">{t("Save")}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="card">
                                        <div className="card-body">
                                            <TeamAttributes />
                                        </div>
                                    </div>


                                    <div className="card">
                                        <div className="card-body">
                                            <div id="settings">
                                                <form>
                                                    <h6 className="mb-3  bg-light p-2"><i className="mdi mdi-axis-arrow-lock me-1 me-1"></i>{t("Access to my account")}</h6>
                                                    <div className="row">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover mb-0">
                                                                <tbody>
                                                                    {AllowAccess_list.length > 0 && AllowAccess_list.map((result, index) =>
                                                                        <tr>
                                                                            <td><b>{index + 1}</b></td>
                                                                            <td>{result.access_to_name}</td>
                                                                            <td>{result.access_to_email_id}</td>
                                                                            <td>
                                                                                {
                                                                                    (result.activation_status ? 'Granted' : t('Waiting for confirmation'))
                                                                                }
                                                                                {
                                                                                    (result.activation_status ? '' : (<Button onClick={(e) => this.resendEmail(result)}>({t("send e-mail again")})</Button>))
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <Button size="sm" color="danger" className="ms-2" onClick={(e) => this.AllowAccessDelete(index, result)}><i className="far fa-trash-alt"></i></Button>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {!AllowAccess_list.length &&
                                                                        <tr>
                                                                            <td colSpan="5" className="text-center">No List Found</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-2">
                                                                <label htmlFor="firstname" className="form-label">{t("I would like to grant the following user access to my account")}</label>
                                                                <Input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder={t("Enter E-Mail")}
                                                                    name="email_access_to"
                                                                    required=""
                                                                    value={this.state.email_access_to}
                                                                    onChange={(e) => this.allowAccess(e.target.value)}
                                                                />
                                                                <span className="text-danger">{t(this.state.errMsg.email_access_to)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end row --> */}
                                                    <div className="text-end">
                                                        <Button onClick={() => this.handleAllowAccess()} className="btn btn-success waves-effect waves-light fw-bolder mt-2">{t("Add")}</Button>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* <!-- end settings content--> */}
                                        </div>
                                    </div>
                                    {/* <!-- end card--> */}

                                </div>
                                {/* <!-- end col --> */}
                            </div>
                            {/* <!-- end row--> */}
                            {/* </div> <!-- container-fluid --> */}
                        </div>
                    </div>


                    <Footer />

                    {/*<footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Link to="#"><i className="fe-facebook"></i></Link>
                                    <Link to="#"><i className="fe-instagram"></i></Link>
                                    TeamOrganizer GMbH
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end footer-links d-none d-sm-block">
                                        <Link to="/">imprint</Link>
                                        <Link to="/">privacy</Link>
                                        <Link to="/">design by stoz</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>*/}

                    {/* <!-- end Footer --> */}
                    <Modal className="modal-dialog" style={{ width: "300px", borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.RemoveAcmodal}  >
                        <ModalBody color="primary" style={{ height: "250px", borderRadius: "5px", backgroundColor: "#E85368" }}>
                            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                                <i style={{ fontSize: "50px" }} className="fas fa-exclamation-triangle mt-4"></i>
                                <p className="mt-3">{t("Please Confirm To Delete Account")} </p>
                                <p><h5>{this.state.teamInfo.team_name}</h5></p>
                                <p className="mt-4">
                                    <Button className="mx-2" width="50px" color="light" onClick={() => this.onDeleteClick()}>{t("Confirm")} </Button>{' '}
                                    <Button className="mx-2" color="default" onClick={() => this.CloseAcmodal()}>{t("Cancel")}</Button></p>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal className="modal-dialog" style={{ width: "300px", borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.DeleteModal} >
                        <ModalBody color="primary" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                                {this.state.errMsg.message}
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Profile);
