import React, { Component } from 'react'
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'

import { Form, Input, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../constants/Common';
import Select from 'react-select'
import { withTranslation } from 'react-i18next';


const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333"
        };
    }
};

class Member extends Component {
    constructor() {
        super();
        this.state = {
            Member_list: [],
            teamId: "",
            email: "",
            isOwner: "",
            setMember: "",
            function: "",
            func_List: [{ value: "", label: "No function: Just owner / Teamadmin" }],
            msg: "",
            errMsg: {},
            scsMsg: "",
            atLeast: '',
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Is_admin: [{ value: 0, label: "No" }],
            function_name: [],
            Loader: false,
            modal: false,
            IsAdminOwner: true,
            yesNoList: []

        }
        this.toggle = this.toggle.bind(this)
    }

    toggle(e) {
        const { t } = this.props;
        this.setState({
            modal: !this.state.modal,
            email: "",
            errMsg: '',
            Is_admin: [{ value: 0, label: t("No") }],
            function_name: [],
        });
    }
    onChangehandler = (e) => {
        if (e) {
            this.setState({
                email: e.target.value,
                errMsg: { errMsgEmail: [""] }
            });
        }
        else {
            this.setState({
                errMsg: { errMsgEmail: ["Please Enter Email"] },
            });
        }
    };

    SelectFunctions = (e) => {
        if (e) {
            this.setState({
                function_name: [{ value: e.value, label: e.label }],
                // errMsg: { function_id:["Please Enter Email"]},
            })
        }
        else {
            this.setState({
                errMsg: { function_id: ["Please select function"] }
            });
        }
    };

    handleChange = (e) => {
        if (e) {
            this.setState({
                Is_admin: [{ value: e.value, label: e.label }],
                errMsg: { Is_Admin: [""] },
            })

        }
        else {
            this.setState({
                errMsg: { Is_Admin: ["Please Select Is_admin"] },
            });
        }
    };



    handleAddMembers = () => {

        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('owner_id', this.state.user.user_id);
        formData.append('member_email', this.state.email);
        formData.append('is_admin', this.state.Is_admin[0].value);
        if (Array.isArray(this.state.function_name) && this.state.function_name.length) {
            formData.append('function_id', this.state.function_name[0].value);
        }
        else {
            formData.append('function_id', '');
        }
        formData.append('member_id', '');
        // }
        var app_url = APIURL + "create_member_function";
        var token = this.state.token
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.getMemberInfo(this.props.data.teamId);
                this.setState({
                    Is_admin: [{ value: 0, label: 'No' }],
                    function_name: [],
                    email: "",
                    function_id: "",
                    modal: false,
                    Loader: false,
                    msg: response.data.message,
                });
            })
            .catch(error => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }

            }
            );

    }

    handleRemoveMember = (idx, member_id) => () => {

        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('id', member_id);
        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "delete_member", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {
                this.setState({
                    Loader: false,
                    Member_list: this.state.Member_list.filter((s, sidx) => idx != sidx)
                });
            })
            .catch(error =>
                this.setState({
                    Loader: false
                })
            );


    }

    getAlert() {
        alert('getAlert from Child');
    }

    handleIsAdmin(e, idx) {
        let Member_list = this.state.Member_list;
        Member_list[idx]['is_admin'] = e.target.checked;
        this.setState({
            Member_list: Member_list
        })

        const formData = new FormData();
        formData.append('is_admin', e.target.checked);
        formData.append('id', Member_list[idx]['id']);
        formData.append('team_id', this.props.data.teamId);
        formData.append('type', "member");
        var token = this.state.token
        axios
            .post(APIURL + "membership/update-admin", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {

            })
            .catch(error => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }

            }
            );
    }

    getFunctions() {
        let function_list = [];
        var token = this.state.token
        axios
            .get(APIURL + "get_functions/" + this.props.data.teamId, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.status === 200) {
                    let functions = response.data.data;
                    for (var c = 0; c < functions.length; c++) {
                        function_list.push({ value: functions[c].function_id, label: functions[c].name })
                        // this.state.func_List.push({ value: functions[c].function_id, label: functions[c].name })
                    }
                    this.setState({
                        func_List: function_list
                    })
                }
            });
    }

    onSubmit = (e) => {
        if (this.state.Member_list.length < 1) {
            this.setState({
                atLeast: 'Atleast one Member must be defined for next step'
            });
            setTimeout(() => this.setState({ atLeast: '' }), 2000);

        }
        else {
            this.setState({
                atLeast: ''
            });
            this.props.data.handleCompleteTab(3);
        }

        //this.props.data.handleActiveTab(3);
        // console.log("ids", this.state.Member_list)
    };

    getMemberInfo(id) {
        var token = this.state.token
        axios
            .get(APIURL + "get_member_functions/" + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                // console.log("member",response.data.data);
                if (response.data.data.length > 0) {
                    /* const arr = response.data.data;
 
                     const syncRes = arr.map((item) => {
                         var fnArr = [{value: item.function_id, label: item.function_name}];
                         const newItem ={
                             email: item.email,
                             function_name: fnArr,
                             isOwner: item.is_teamleader,
                             Is_admin: item.is_admin,
                             id:item.id
                         }
                         return newItem;
                     });*/
                    /*var fnArr = [{value: 11, label: 'aaaaaa'}];
                    const newItem ={
                        email: 'abc@dfsdf.sdfsd',
                        function_name: fnArr,
                        isOwner: 0,
                        Is_admin: 0,
                        id:item.''
                    }
                    var itmArr[0] = newItem;*/
                    // const self = this;
                    // console.log('syncRes', syncRes);
                    // setTimeout(function(){ self.setState({ Member_list: syncRes }); }, 3000);
                    this.setState({ Member_list: response.data.data });
                }
            });
    }

    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getFunctions()
            this.getMemberInfo(this.props.data.teamId);
            // this.getAnswerOption()
        }
    }


    componentWillReceiveProps(newProps) {
        if (this.props.data.teamId > 0) {
            this.getFunctions()
            this.getMemberInfo(this.props.data.teamId);
            this.getOption();
        }
    }
    getOption() {
        const { t } = this.props;
        let yesNoList = [];
        yesNoList.push({ value: 0, label: t('No') })
        yesNoList.push({ value: 1, label: t('Yes') })

        let Is_admin = this.state.Is_admin;
        Is_admin = [{ value: Is_admin[0].value, label: Is_admin[0].label }]

        this.setState({
            yesNoList: yesNoList,
            Is_admin: Is_admin
        });
    }

    render() {
        const { t } = this.props;
        const string1 = 'User has an activated Imenso account, An invitation is sent to the user when the teams are created.';
        const string2 = 'User does not have a Imenso account yet, The user will receive an invitation mail to create a Imenso account (incl. confirmation for team membership) when creating the teams.';
        return (
            <>
                {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Form id="teaminfoform" method="post" action="#" enctype='multipart/form-data' className="form-horizontal was-validated">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <h5>{t("Team members")}</h5>
                                        <span>{t("Members represent all persons belonging to your new team. When adding members, you must define which function they have and if the person has admin rights in the team. With admin rights the member is allowed to change team settings, add members, create/edit events, etc.If a new member is added, the status of the member appears, i.e. whether the person already has an account with Imenso. If a member does not have a Imenso account yet, he will be automatically prompted to create an account.At the end of the team creation all members listed here will be informed with a mail that they have been added to the newly created team. This participation to the team must be confirmed first by each new member.")} </span>
                                    </div>

                                    <div className="table-responsive table-wrapper">
                                        <table className="table table-hover tableFixHead">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th className="fw-medium">{t("E-mail address")}</th>
                                                    <th className="fw-medium">{t("Function")}</th>
                                                    <th className="fw-medium">{t("Is owner")}</th>
                                                    <th className="fw-medium">{t("Is team administrator")}</th>
                                                    <th className="fw-medium">{t("State")}</th>
                                                    <th className="fw-medium text-center">{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className="font-14">
                                                {/*<tr>
                                                    <td> {this.state.user.email} </td>
                                                    <td> No function: Just owner / Teamadmin </td>
                                                    <td> X </td>
                                                    <td>
                                                        <input className="form-check-input" checked={this.state.IsAdminOwner}  type="checkbox" value="" id="flexCheckDefault"  />
                                                    </td>
                                                    <td> Owner<br />User has an activated TeamOrganizer account </td>
                                                    <td></td>
                                                </tr>*/}
                                                {this.state.Member_list.map((Member, idx) => (
                                                    <tr>
                                                        <td> {Member.email} </td>
                                                        <td>
                                                            {Member.function_name ? (!Array.isArray(Member.function_name) ?
                                                                (<div> {Member.function_name} </div>)
                                                                : (<div> {Member.function_name[0].label} </div>)
                                                            ) : <div>No function: Just owner / Teamadmin</div>}

                                                            {/* { Member.is_teamleader === 1?
                                                     <div>No function: Just owner / Teamadmin</div> :""
                                                    } */}

                                                        </td>
                                                        <td>  {Member.is_teamleader === 1 ? "X" : ""}</td>
                                                        <td>
                                                            {(Member.is_admin >= 0 ?
                                                                (<input className="form-check-input" onChange={(e) => this.handleIsAdmin(e, idx)} type="checkbox" value={Member.is_admin} checked={Member.is_admin ? true : false} id="flexCheckDefault" />)
                                                                : (<input className="form-check-input" onChange={(e) => this.handleIsAdmin(e, idx)} type="checkbox" checked={Member.Is_admin[0].value ? true : false} value={Member.Is_admin[0].value} id="flexCheckDefault" />)
                                                            )}
                                                        </td>
                                                        <td>{Member.state ? Member.state.split(', ').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        )) : Member.activation_status ? string1.split(', ').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        )) : string2.split(', ').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))}</td>

                                                        <td className="text-center">
                                                            {Member.is_teamleader === 0 ?
                                                                <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={this.handleRemoveMember(idx, Member.id)}><span>{t("Remove")}</span></Button>
                                                                // <i onClick={this.handleRemoveMember(idx , Member.id)} className="cursor_pointer far fa-trash-alt"></i>
                                                                : ""
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <span className="text-danger">{this.state.atLeast}</span>
                                    </div>


                                    <div className="mt-3">
                                        <Button
                                            type="button"
                                            id="add_attrs"
                                            onClick={this.toggle}
                                            className="btn btn-warning waves-effect waves-light mt-2 add_attrs fw-bolder"
                                        > {t("Add team member")} </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- end row --> */}
                    <ul className="pager wizard mb-0 list-inline">
                        <li className="previous list-inline-item">
                            <button onClick={() => this.props.data.handleActiveTab(1)} type="button" className="btn btn-warning mr-2  fw-bolder"><i className="mdi mdi-arrow-left me-1 fw-bolder"></i> {t("Back to functions")}</button>
                        </li>
                        <li className="next list-inline-item disabled">
                            <button onClick={() => this.onSubmit()} type="button" className="btn btn-primary fw-bolder">{t("Go to tasks")} <i className="mdi mdi-arrow-right ms-1 fw-bolder"></i></button>
                        </li>
                    </ul>
                </Form>

                <Modal size="md" isOpen={this.state.modal} autoFocus={false}>
                    <ModalHeader toggle={this.toggle}>{t("Add function")}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Member e-mail")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Enter member e-mail address")}
                                        id="useremail"
                                        name="email"
                                        required=""
                                        value={this.state.email}
                                        onChange={this.onChangehandler}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.member_email)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12 view-element">
                                <div className="form-group">
                                    <Label>{t("Function")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required="required"
                                        placeholder={t("Select")}
                                        options={this.state.func_List}
                                        value={this.state.function_name}
                                        onChange={this.SelectFunctions}
                                        styles={colourStyles}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.function_id)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Is team administrator")}</Label>
                                    <Select
                                        placeholder="Select Is-Team Admin"
                                        value={this.state.Is_admin}
                                        onChange={this.handleChange}
                                        options={this.state.yesNoList}
                                        styles={colourStyles}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.Is_Admin)}</span>
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </button>
                            <Button className="btn btn-success fw-bolder " onClick={() => this.handleAddMembers()}>{t("Save")} </Button>

                        </div>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
export default withTranslation()(Member);