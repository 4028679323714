import React, { Component } from 'react'
import { Row, Col, Spinner, Jumbotron, Label, Input, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';
import axios from "axios";
import { APIURL } from '../../constants/Common';
import { Redirect, Link } from "react-router-dom";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '../../../assets/css/Custom.css'
import Sidebar from "../../GeneralComponents/Sidebar";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import Select from 'react-select'
import { Global, css } from "@emotion/core";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { withTranslation } from 'react-i18next';


const editorConfiguration = {
    toolbar: [ 
        'heading',
      '|',
      'bold',
      'italic',
      'bulletedList',
      'numberedList',
      '|',
      'imageUpload',
      'undo',
      'redo',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:alignCenter',
       'imageStyle:alignRight',
       ],
    ckfinder: {
        // Upload the images to the server using the CKFinder QuickUpload command
        // You have to change this address to your server that has the ckfinder php connector
        uploadUrl: 'https://ckeditor.com/apps/ckfinder/3.5.0/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'
    }
};

class Add extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        this.state = {
            teamInfo: {
                team_name: "",
                logo: "",
                owner: "",
                teamtype: "",
                redirect: false,
                isLoading: "",
            },
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            errMsg: {},
            error: [],
            subject: '',
            body: '',
            selectedFiles: [],
            progressInfos: [],
            teamMember: [],
            fileInfos: [],
            team_name: "",
            MailModal: false,
            Loader: false,
            selectedOptions: []
        };
    }
    ckWrapperStyle = css`
    position: relative;
    z-index: 1;
    &::before {
      color: rgba(251, 50, 8, 1.0);
      content: attr(data-placeholder);
      position: absolute;
      margin: var(--ck-spacing-large) 0;
      top: 0;
      z-index: -1;
    }
  `;

    componentDidMount() {
        this.getTeamDetails()
        this.getTeamMember()
    }


    fileSelectedHandler = (e) => {
        this.setState({
            selectedFiles: [...this.state.selectedFiles, ...e.target.files]
        }, () => {
            this.myRef.current.value = "";
        })
    }
    handleRemoveTeamAttrs = (index) => () => {
        this.setState({ selectedFiles: this.state.selectedFiles.filter((s, sidx) => index != sidx) });
    }
    handleSubject(e) {
        this.setState({
            subject: e
        })
    }

    handleBody(e) {
        this.setState({
            body: e
        })
    }
    handleBody(event) {
        this.setState({
            body: event
        })
        console.log(this.state.body)
    }

    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }
                this.setState({
                    team_name: team_name
                })
            });
    }

    getTeamMember = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "team/get-team-member", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("maili", response.data.data)
                let teamMember = [];
                // teamMember.push({ value: "", label: "All" })
                response.data.data.map((list, index) => {
                    if (list.active === 1) {
                        teamMember.push({ value: list.email, label: list.full_name, active: list.active })
                    }
                });

                this.setState({
                    teamMember: teamMember
                })
                console.log("teamMember", this.state.teamMember)

            });
    }

    MailModal() {
        this.setState({
            MailModal: !this.state.MailModal
        })
    }
    closeModal() {
        this.setState({
            MailModal: false
        })
    }

    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    onSubmit = () => {
        this.setState({ Loader: true });
        const formData = new FormData(); let falge = 0;
        formData.append('team_id', this.props.match.params.id);
        formData.append('subject', this.state.subject);
        formData.append('body', this.state.body);
        formData.append('to', JSON.stringify(this.state.selectedOptions));

        this.state.selectedFiles.forEach((image_file) => {
            falge = 1;
            formData.append('file[]', image_file);
        });
        if (falge == 0) {
            formData.append('file[]', undefined);
        }
        var token = this.state.token

        const option = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };


        axios
            .post(APIURL + "team/mailing-send", formData, option)
            .then(result => {
                alert("successfuly sent");
                this.setState({
                    errMsg: '',
                    subject: '',
                    body: '',
                    selectedFiles: [],
                    MailModal: false,
                    Loader: false
                }, () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 100)
                })

            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false,
                        MailModal: false,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }
            }
            );
    }
    render() {
        const { t } = this.props;
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        if (this.state.redirectToEvent) {
            return <Redirect to={"/event/" + this.state.teamId} />;
        }
        return (
            <React.Fragment>
                <div id="wrapper">
                    <Sidebar
                        data={{
                            // teamInfo: this.teamInfo.bind(this),
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage: "mailing",
                            visible:this.props.location.visible
                        }} />
                    {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}

                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt">
                                            <h4 className="page-title">{this.state.team_name} | {t("Team mailing")}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="p-2">
                                                            <form className="form-horizontal" role="form">
                                                                <div className="mb-2 row">
                                                                    <label className="col-md-2 col-form-label" htmlFor="simpleinput"> {t("To")}  </label>
                                                                    <div className="col-md-10">
                                                                        <Select
                                                                            isMulti
                                                                            placeholder={t("All")}
                                                                            className="form-select-control form-select-white"
                                                                            options={this.state.teamMember}
                                                                            value={this.state.selectedOptions}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-md-2 col-form-label" htmlFor="simpleinput"> {t("E-mail subject")} </label>
                                                                    <div className="col-md-10">
                                                                        <input
                                                                            type="text"
                                                                            id="simpleinput"
                                                                            className="form-control select "
                                                                            placeholder={t("Please enter e-mail subject")}
                                                                            value={this.state.subject}
                                                                            onChange={(e) => this.handleSubject(e.target.value)}
                                                                            data-maxzpsw="0"
                                                                        />
                                                                        <span className="text-danger"> {t(this.state.errMsg.subject)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-md-2 col-form-label" htmlFor="example-email">{t("E-mail body")}</label>
                                                                    <div className="col-md-10">
                                                                        <Global
                                                                            styles={css`
                                                                        :root {
                                                                            p {
                                                                                margin : 1px 0;
                                                                                padding : 0;
                                                                                }
                                                                            --ck-border-radius: 4px;
                                                                            --ck-color-focus-border: rgba(142, 140, 140, 0.25);
                                                                            --ck-color-shadow-inner: rgba(142, 140, 140, 1);
                                                                            --ck-inner-shadow: 0 0 0 2px var(--ck-color-shadow-inner);
                                                                            --ck-spacing-large: var(--ck-spacing-standard);                                                                        
                                                                            height: 200px;
                                                                        }                                                                       
                                                                        .ck.ck-editor__editable_inline {
                                                                         background-color:rgba(142, 140, 140, 0.25);
                                                                        border: 1px solid rgba(142, 140, 140, 0.25);
                                                                        transition: all 0.3s;
                                                                        color: rgba(19, 19, 18, 1);
                                                                        height: 200px;
                                                                        &:hover {
                                                                            border-color: rgba(19, 19, 18, 0.25);
                                                                            border-right-width: 1px !important;
                                                                          color: rgba(19, 19, 18, 1); !important;

                                                                        }
                                                                        }
                                                                        .ck-editor__editable.ck-read-only {
                                                                          color: rgba(19, 19, 18, 1);;
                                                                        opacity: 1;
                                                                        cursor: not-allowed;
                                                                         color: rgba(19, 19, 18, 1);
                                                                         height: 200px;
                                                                         background-color:rgba(142, 140, 140, 0.25);
                                                                        &:hover {
                                                                            border-color: rgba(142, 140, 140, 0.25);
                                                                        }
                                                                        }
                                                                    `}
                                                                        />

                                                                        <CKEditor
                                                                            editor={Editor}
                                                                            config={editorConfiguration}
                                                                            data={this.state.text}
                                                                            onReady={editor => {
                                                                                // You can store the "editor" and use when it is needed.
                                                                                console.log('Editor is ready to use!', editor);
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.handleBody(data)
                                                                                console.log({ event, editor, data });
                                                                            }}

                                                                            onBlur={(event, editor) => {
                                                                                console.log('Blur.', editor);
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                console.log('Focus.', editor);
                                                                            }}
                                                                        />
                                                                        {/* <CKEditor
                                                                            config={{
                                                                                extraPlugins: [this.uploadPlugin]
                                                                            }}
                                                                            style={{ height: "500px", backgroundColor: "red" }}
                                                                            editor={ClassicEditor}
                                                                            data={this.state.text}
                                                                            config={{
                                                                                toolbar: [
                                                                                    "heading",
                                                                                    "|",
                                                                                    "bold",
                                                                                    "italic",
                                                                                    "bulletedList",
                                                                                    "numberedList",
                                                                                    "|",
                                                                                    "undo",
                                                                                    "redo"
                                                                                ],
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.handleBody(data)
                                                                                console.log({ event, editor, data });
                                                                            }}
                                                                        /> */}

                                                                        <span className="text-danger">{this.state.errMsg.text}</span>
                                                                        {/* <textarea
                                                                        className="form-control"
                                                                        id="example-textarea"
                                                                        rows="5"
                                                                        placeholder="Enter email body"
                                                                        value={this.state.body}
                                                                        onChange={(e) => this.handleBody(e.target.value)}
                                                                ></textarea> */}
                                                                        <span className="text-danger">{t(this.state.errMsg.body)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-md-2 col-form-label" htmlFor="example-email">{t("Attachment")}</label>
                                                                    <div className="col-md-10">
                                                                        {this.state.selectedFiles.length > 0 &&
                                                                            <div className="card">
                                                                                <ul className="list-group list-group-flush">
                                                                                    {this.state.selectedFiles &&
                                                                                        this.state.selectedFiles.map((file, index) => (
                                                                                            <li className="list-group-item" key={index}>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        {file.name}
                                                                                                    </div>
                                                                                                    <div className="col text-right">
                                                                                                        <i onClick={this.handleRemoveTeamAttrs(index)} className="cursor_pointer far fa-trash-alt"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        ))}
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                        <input type="file" ref={this.myRef} className="form-control form-control-sm" multiple onChange={this.fileSelectedHandler} />
                                                                        ({t("File size upto 25 MB is allowed")})
                                                                        <span className="text-danger">{this.state.errMsg.file}</span>
                                                                    </div>
                                                                </div>
                                                                <button type="button" onClick={() => this.MailModal()} className="btn btn-success">{t("Send e-mail")}</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.MailModal}  >
                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {this.state.errMsg.message &&
                                <span className="text-danger">{this.state.errMsg}</span>
                            }
                            <h6 className="mt-3">{t("Confirm to send e-mail?")} </h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={() => this.onSubmit()} >{t("Confirm")} </Button>{' '}
                                <Button color="default" onClick={() => this.closeModal()}>{t("Cancel")}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Add);