import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import '../../assets/css/Custom.css'
import Sidebar from "../GeneralComponents/Sidebar";
import { APIURL } from '../constants/Common';
import axios from "axios";
import { Spinner, Input } from 'reactstrap'
import Footer from '../GeneralComponents/Footer'
import { Trans, withTranslation } from 'react-i18next';
import queryString from 'query-string'

class Index extends Component {
    constructor() {
        super()
        this.state = {
            navigate: false,
            visible: false,
            Fullvisible: false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Package_List: [],
            Is_Demo: [],
            scsMsg: "",
            isLoading: false,
            false: "",
            redirectToAddTeam: false
        };
    }

    componentDidMount() {
        this.getPackage()
        this.getPackageRecords();
    }

    getPackage() {
        const formData = new FormData();
        var token = this.state.token
        axios
            .post(APIURL + "booking/details", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                console.log("Is_Demo", response.data.data);
                this.setState({
                    Is_Demo: response.data.data,
                })
            });
    }
    getPackageRecords() {
        axios
            .get(APIURL + "package/list")
            .then((response) => {
                if (response.data.status == 200) {
                    this.setState({
                        Package_List: response.data.data,
                    });
                    console.log(this.state.Package_List)
                }
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    HandlePackageDuration(e, idx) {
        console.log(e.target.selectedIndex)
        let Package_List = this.state.Package_List;

        Package_List[idx].license_type.map((item, index) => (
            item.is_default = "No"
        ))
        Package_List[idx].license_type[e.target.selectedIndex].is_default = "Yes"

        this.setState({
            Package_List: Package_List,
            license_type_id: e.id,
            packageId: e.package_id
        })
    }
    OnSubmit(pkgId) {
        this.setState({
            isLoading: true
        })
        var token = this.state.token
        const formData = new FormData();
        formData.append('license_type_id', pkgId.id)


        axios.post(APIURL + "payment", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                this.getPackage();
                if (response.data.type == "demo") {
                    this.demoSubmit(pkgId)
                }
                else {
                    window.location.href = response.data.link;
                }
            });
    }

    demoSubmit(pkgId) {
        var token = this.state.token
        const formData = new FormData();
        formData.append('license_type_id', pkgId.id)
        axios.post(APIURL + "booking/update", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                this.getPackage();
                this.setState({
                    scsMsg: response.data.message,
                    isLoading: false,
                    redirectToAddTeam: true
                })
                var self = this;
                setTimeout(function () {
                    self.setState({
                        scsMsg: ""
                    })
                }, 2000)
            });
    }

    onLogoutHandler = () => {
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    render() {
        const { t } = this.props;

        if (this.state.redirectToAddTeam) {
            return <Redirect to="/addteam" push={true} />;
        }
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.isLoading ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <Sidebar
                    data={{
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage: "upgrade_package",
                        visible: this.props.location.visible
                    }} />
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">

                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">{t("Buy license")}</h4>

                                    </div>
                                </div>
                                {
                                    this.state.scsMsg != "" &&
                                    <div className="col-12  text-primary Login_error" >
                                        {this.state.scsMsg}
                                    </div>

                                }
                            </div>

                            <div className="row license-price">
                                {this.state.Package_List.length > 0 && this.state.Package_List.map((item, idx) => (
                                    this.state.Is_Demo.length > 0 ?
                                        item.name == "Demo" ? "" :
                                            <div className="col-xl-4 col-sm-6" key={item.id}>
                                                <div className="card card-pricing">
                                                    <div className="card-body text-center">
                                                        <p className="card-pricing-plan-name fw-bold text-uppercase">{item.name}</p>
                                                        {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                            <span className="card-pricing-icon text-primary">
                                                                {
                                                                    sItem.number_of_teams <= 1 && sItem.number_of_teams >= 1 ?
                                                                        <i className="fe-at-sign"></i>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    sItem.number_of_teams <= 5 && sItem.number_of_teams > 1 ?
                                                                        <i className="fe-users"></i>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    sItem.number_of_teams <= 10 && sItem.number_of_teams >= 6 ?
                                                                        <i className="fe-award"></i>
                                                                        :
                                                                        ""
                                                                }
                                                                {
                                                                    sItem.number_of_teams > 10 ?
                                                                        <i className="fe-aperture"></i>
                                                                        :
                                                                        ""
                                                                }
                                                            </span>
                                                        ))}

                                                        {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                            <div>
                                                                <h2 className="card-pricing-price text-white"><span className="card-pricing-price text-white"><sup className="currency">SFr. </sup></span>{sItem.amount}<span className="text-white"> /{t("Year")}</span></h2>
                                                            </div>
                                                        ))}
                                                        <p className="card-pricing-desc">{item.description}</p>
                                                        <ul className="card-pricing-features text-white">
                                                            {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                                <li><b>{sItem.number_of_teams}</b></li>
                                                            ))}
                                                            <li><b>{t("All Functions")}</b></li>
                                                            <li>{t("No restrictions")}</li>
                                                            <li>{t("Swiss solution & Swiss hosting")}</li>

                                                            {item.license_type.filter(person => person.amount == 0).map((sItem, sIdx) => (
                                                                <li>{t("30 days term")} </li>
                                                            ))}
                                                            {item.license_type.filter(person => person.amount != 0).length > 0 &&
                                                                <li> {t("1 to 3 year term *")}</li>
                                                            }
                                                        </ul>
                                                        {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                            item.name != "Unlimited" &&
                                                            <select className="form-select" value={sItem.duration_month}
                                                                onChange={(e) => this.HandlePackageDuration(e, idx)} >
                                                                {item.license_type.map((sItem, sIdx) => (
                                                                    <option value={sItem.duration_month} >{sItem.duration_month % 12 == 0 ? (sItem.duration_month / 12) + " " + t("Years") : sItem.duration_month + " " + t("Months")}</option>
                                                                ))}
                                                            </select>
                                                        ))}
                                                        <div className="d-grid">
                                                            {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                                <button
                                                                    className="btn btn-primary waves-effect waves-light mt-4"
                                                                    onClick={() => this.OnSubmit(sItem)}
                                                                >{t("Buy")}</button>

                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <div className="col-xl-4 col-sm-6">
                                            <div className="card card-pricing">
                                                <div className="card-body text-center">
                                                    <p className="card-pricing-plan-name fw-bold text-uppercase">{item.name}</p>
                                                    {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                        <span className="card-pricing-icon text-primary">

                                                            {
                                                                sItem.number_of_teams <= 1 && sItem.number_of_teams >= 1 ?
                                                                    <i className="fe-at-sign"></i>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                sItem.number_of_teams <= 5 && sItem.number_of_teams > 1 ?
                                                                    <i className="fe-users"></i>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                sItem.number_of_teams <= 10 && sItem.number_of_teams >= 6 ?
                                                                    <i className="fe-award"></i>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                sItem.number_of_teams > 10 ?
                                                                    <i className="fe-aperture"></i>
                                                                    :
                                                                    ""
                                                            }
                                                        </span>
                                                    ))}
                                                    {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                        <div>
                                                            <h2 className="card-pricing-price text-white"><span className="card-pricing-price text-white"><sup className="currency">SFr. </sup></span>{sItem.amount}<span className="text-white"> /{t("Year")}</span></h2>
                                                        </div>
                                                    ))}
                                                    <p className="card-pricing-desc">{item.description}</p>
                                                    <ul className="card-pricing-features">

                                                        {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                            <li><b>
                                                                {item.name != "Unlimited" &&
                                                                    sItem.number_of_teams + " " + t("Teams")
                                                                }
                                                                {item.name == "Unlimited" &&
                                                                    t("Unlimited number of teams")
                                                                }

                                                            </b></li>
                                                        ))}

                                                        <li><b>{t("All Functions")}</b></li>
                                                        <li>{t("No restrictions")}</li>
                                                        <li>{t("Swiss solution & Swiss hosting")}</li>

                                                        {item.license_type.filter(person => person.amount == 0).map((sItem, sIdx) => (
                                                            <li>  {t("30 days term")}  </li>
                                                        ))}
                                                        {item.license_type.filter(person => person.amount != 0).length > 0 &&
                                                            <li> {t("1 to 3 year term *")}</li>
                                                        }
                                                    </ul>
                                                    {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                        <div>
                                                            {item.name != "Unlimited" &&
                                                                <select className="form-select" value={sItem.duration_month}
                                                                    onChange={(e) => this.HandlePackageDuration(e, idx)} >
                                                                    {item.license_type.map((sItem, sIdx) => (
                                                                        <option value={sItem.duration_month} >{sItem.duration_month % 12 == 0 ? (sItem.duration_month / 12) + " " + t("Years") : sItem.duration_month + " " + t("Months")}</option>
                                                                    ))}
                                                                </select>
                                                            }
                                                        </div>
                                                    ))}
                                                    <div className="d-grid">
                                                        {item.license_type.filter(person => person.is_default == "Yes").map((sItem, sIdx) => (
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light mt-4 text-white fw-bolder"
                                                                onClick={() => this.OnSubmit(sItem)}
                                                            >{t("Buy")}</button>
                                                        ))}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
            </div>
        );
    }
}
export default withTranslation()(Index);
