import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../constants/Common';
import { Button } from 'reactstrap';
import Switch from "react-switch";
import { Link } from "react-router-dom"
import { Input ,Spinner} from 'reactstrap';
import no_img from '../../assets/images/no_data_found.svg'
import {  withTranslation } from 'react-i18next';

class TeamAttributes extends Component {
    constructor() {
        super();

        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            teamAttrs: [],
            register_switch: false,
            events_switch: false,
            new_news_switch: false,
            new_documents: false,
            TeamList: [],
            OwnerTeamList: [],
            MemberTeamList: [],
            unique_id: "",
            team_id: "",
            teamInfo: [],
            attrList: [],
            checked: false,
            ActiveteamAtr: "",
            no_record_of_Team_specific_configuration:false

        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getTeamList()
    }

    refreshPage(e) {
        console.log("onclicked", e)
        this.setState({
            teamInfo: e,
            team_id: e.team_id,
            unique_id: e.id
        }, () => {
            this.getTeamAttributes()
        });
    }

    getTeamList() {
        if (this.state.user) {
            var token = this.state.token
            axios
                .get(APIURL + "get_team_specific_configuration/" + this.state.user.user_id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("Team List", response.data.data)
                    if (response.data.status === 200) {
                        this.setState({
                            MemberTeamList: response.data.data,
                        })
                    }
                });

            const formData = new FormData();
            var app_url = APIURL + "owner_of_teams_list";

            axios
                .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("Owner Team List", response.data.data)
                    if (response.data.status === 200) {
                        this.setState({
                            OwnerTeamList: response.data.data,
                        })
                    }
                });

            axios
                .get(APIURL + "my_teams/" + this.state.user.user_id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("Member Team List", response.data.data)
                    if (response.data.status === 200) {
                        this.setState({
                            TeamList: response.data.data,
                            no_record_of_Team_specific_configuration:true
                        })

                        response.data.data.map((item, idx) => {
                            if (idx==0){
                              this.refreshPage(item);
                            }

                       });

                        
                    }
                });
        }
    }


    getTeamAttributes() {
        const formData = new FormData();
        formData.append('user_id', this.state.user.user_id);
        formData.append('team_id', this.state.team_id);
         var token = this.state.token
        axios
            .post(APIURL + "user_profile/get-attributes", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    teamAttrs: response.data.data,
                    register_switch: response.data.member.remindermail_reg_events,
                    events_switch: response.data.member.infomail_new_events,
                    new_news_switch: response.data.member.infomail_new_news,
                    new_documents: response.data.member.infomail_new_documents,
                    reminderDay: response.data.member.remindermail_reg_events_days,
                    ActiveteamAtr: response.data.member.team_id
                });
            })
    }

    onChangeAttr = (e, index) => {
        let teamAttrs = this.state.teamAttrs;
        console.log(teamAttrs, index);
        teamAttrs[index]['value'] = e.target.value;
        this.setState({
            teamAttrs: teamAttrs,

        })
    };

    onSubmit = (e) => {
        const formData = new FormData();
        formData.append('remindermail_reg_events', Number(this.state.register_switch));
        formData.append('infomail_new_events', Number(this.state.events_switch));
        formData.append('infomail_new_news', Number(this.state.new_news_switch));
        formData.append('infomail_new_documents', Number(this.state.new_documents));
        formData.append('remindermail_reg_events_days', this.state.reminderDay);
        formData.append('user_id', this.state.user.user_id);
        formData.append('team_id', this.state.team_id);
        formData.append('attributess', JSON.stringify(this.state.teamAttrs));
        var token = this.state.token
        var app_url = APIURL + "update_team_specific_configuration";
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                // this.setState({ isLoading: false });
                if (response.data.status === 200) {
                    // this.props.data.teamInfo({team_id:response.data.data});
                    this.getTeamInfo(response.data.data);
                    this.setState({
                        teamId: response.data.data,
                        msg: response.data.message,
                        errMsg: '',
                        Loader:false
                    });
                }
                if (
                    response.data.status === "failed" &&
                    response.data.success === undefined
                ) {
                    // this.props.data.teamInfo(response.data)
                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.errors,
                    });
                    setTimeout(() => {
                        // this.setState({ errMsgEmail: "", errMsgPwd: "" });
                    }, 2000);
                } else if (
                    response.data.status === "failed" &&
                    response.data.success === false
                ) {
                    // this.props.data.teamInfo(response.data)
                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.message,
                        errMsgEmail: '',
                        errMsgPwd: '',
                    });
                    setTimeout(() => {
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }; onSubmit = (e) => {
        this.setState({Loader:true})
        const formData = new FormData();
        formData.append('remindermail_reg_events', Number(this.state.register_switch));
        formData.append('infomail_new_events', Number(this.state.events_switch));
        formData.append('infomail_new_news', Number(this.state.new_news_switch));
        formData.append('infomail_new_documents', Number(this.state.new_documents));
        formData.append('remindermail_reg_events_days', this.state.reminderDay);
        formData.append('user_id', this.state.user.user_id);
        formData.append('team_id', this.state.team_id);
        formData.append('attributess', JSON.stringify(this.state.teamAttrs));
        var token = this.state.token
        var app_url = APIURL + "update_team_specific_configuration";
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
             
                if (response.data.status === 200) {
                    this.setState({ Loader: false });
                    // this.props.data.teamInfo({team_id:response.data.data});
                    this.getTeamInfo(response.data.data);
                    this.setState({
                        teamId: response.data.data,
                        msg: response.data.message,
                        errMsg: '',
                        Loader:false,
                    });
                }
                if (
                    response.data.status === "failed" &&
                    response.data.success === undefined
                ) {
                    // this.props.data.teamInfo(response.data)
                    this.setState({
                        Loader:false,
                        scsMsg: '',
                        errMsg: response.data.errors,
                    });
                    setTimeout(() => {
                        // this.setState({ errMsgEmail: "", errMsgPwd: "" });
                    }, 2000);
                } else if (
                    response.data.status === "failed" &&
                    response.data.success === false
                ) {
                    // this.props.data.teamInfo(response.data)
                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.message,
                        errMsgEmail: '',
                        errMsgPwd: '',
                        Loader:false
                    });
                    setTimeout(() => {
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    handle_register_switch() {
        this.setState({
            register_switch: !this.state.register_switch
        })
        console.log(this.state.register_switch)
    }
    handle_events_switch_switch() {
        this.setState({
            events_switch: !this.state.events_switch
        })
        console.log(this.state.events_switch)
    }
    handle_new_news_switch_switch() {
        this.setState({
            new_news_switch: !this.state.new_news_switch
        })
        console.log(this.state.new_news_switch)
    }
    handle_new_documents_switch() {
        this.setState({
            new_documents: !this.state.new_documents
        })
        console.log(this.state.new_documents)
    }

    handleChange(checked) {
        this.setState({ checked });
        console.log(this.state.checked)
    }
    reminderDay(e) {
        this.setState({
            reminderDay: e
        })
        console.log(this.state.reminderDay)
    }

    render() {
          const { t } = this.props;
        return (
            <div>
                {
                    this.state.Loader &&
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                }
                <div id="settings">
                    <form>
                        <h6 className="mb-3  bg-light p-2"><i className="ri ri-team-fill  me-1"></i> {t("Team specific configuration")}</h6>
                        <div className="w-100">
                         <div className="row">
                            <div className="col-sm-4 text-left" style={{ marginBottom:"20px" }}>
                                {
                                    this.state.TeamList.length > 0 ?
                                        <div className="team-list">
                                            {this.state.TeamList.map((item, idx) => (
                                                <div>
                                                    <Button
                                                        onClick={(e) => this.refreshPage(item)}
                                                        className="waves-effect ms-0 mb-1 btn-link btn"
                                                        style={{ color: "#999", fontWeight: "600" }}
                                                    >
                                                        <div
                                                            style={{ fontSize: 15 }}
                                                            className={item.team_id === this.state.ActiveteamAtr ? "waves-effect mt-2 active" : "waves-effect"} >&nbsp; &nbsp; {item.team_name}
                                                        </div>
                                                    </Button>
                                                </div>

                                            ))}
                                        </div>
                                        :
                                        <div className="menu-title" >{/*No Teams available*/}</div>
                                }
                            </div>
                            {
                                this.state.no_record_of_Team_specific_configuration==true && this.state.team_id &&
                                    <div className="col-sm-8 mb-2" >
                                        <div className="p-3 bg-light" >
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                    <div style={{ overflowY: "scroll", maxHeight: "420px" }} >
                                                        {this.state.teamAttrs.length > 0 ? this.state.teamAttrs.map((attrs, idx) => (
                                                            <div className="mb-2">
                                                                <label htmlFor="firstname" className="form-label">{attrs.name}</label>
                                                                <Input
                                                                    className="form-control"
                                                                    required=""
                                                                    type="text"
                                                                    name={attrs.team_attrs_id}
                                                                    placeholder={t("Enter attribute name")}
                                                                    value={attrs.value==null?"":attrs.value}
                                                                    onChange={(e) => this.onChangeAttr(e, idx)}
                                                                />
                                                            </div>
                                                        )) : ""}
                                                    </div>
                                                    <br />
                                                    <div className="col-12">
                                                        <div className="form-check form-switch">
                                                            {/* <Switch onChange={this.handleChange} checked={this.state.checked} /> */}
                                                            <Switch
                                                                checked={this.state.register_switch}
                                                                onChange={() => this.handle_register_switch()}
                                                                onColor="#86d3ff"
                                                                onHandleColor="#2693e6"
                                                                handleDiameter={10}
                                                                // uncheckedIcon={false}
                                                                // checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={10}
                                                                width={25}
                                                                className="form-check-input"
                                                                id="material-switch"
                                                            />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("I would like to receive a reminder for all events for which I am registered (number of days before the event)")}</label>
                                                            <input type="number" min="0" className="form-control" value={this.state.reminderDay} onChange={(e) => this.reminderDay(e.target.value)} id="firstname" placeholder="DAY" />
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <Switch
                                                                checked={this.state.events_switch}
                                                                onChange={() => this.handle_events_switch_switch()}
                                                                onColor="#86d3ff"
                                                                onHandleColor="#2693e6"
                                                                handleDiameter={10}
                                                                // uncheckedIcon={false}
                                                                // checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={10}
                                                                width={25}
                                                                className="form-check-input"
                                                                id="material-switch"
                                                            />
                                                            {/* <input value={this.state.register_switch} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> */}
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("I would like to be informed when new events are added (e-mail and push notification)")}</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <Switch
                                                                checked={this.state.new_news_switch}
                                                                onChange={() => this.handle_new_news_switch_switch()}
                                                                onColor="#86d3ff"
                                                                onHandleColor="#2693e6"
                                                                handleDiameter={10}
                                                                // uncheckedIcon={false}
                                                                // checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={10}
                                                                width={25}
                                                                className="form-check-input"
                                                                id="material-switch"
                                                            />
                                                            {/* <input value={this.state.register_switch} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> */}
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("I would like to be informed when new news entries are added (e-mail and push notification)")}</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <Switch
                                                                checked={this.state.new_documents}
                                                                onChange={() => this.handle_new_documents_switch()}
                                                                onColor="#86d3ff"
                                                                onHandleColor="#2693e6"
                                                                handleDiameter={10}
                                                                // uncheckedIcon={false}
                                                                // checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={10}
                                                                width={25}
                                                                className="form-check-input"
                                                                id="material-switch"
                                                            />
                                                            {/* <input value={this.state.register_switchNotification} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> */}
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("I would like to be informed when new documents are added (e-mail and push notification)")}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                    <div className="mb-2">
                                                        <label htmlFor="firstname" className="form-label">Left/Right shote</label>
                                                        <input type="text" className="form-control" id="firstname" placeholder="" />
                                                    </div>
                                                    <br />
                                                    <div className="col-12">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to receive a reminder 1 day before an event where I am registered (email and push notification)</label>
                                                            <input type="text" className="form-control" id="firstname" placeholder="DAY" />
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I want to be informed when new events are added (email and push notification)</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to be informed when new news are added (email and push notification)</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to be informed when new documents are added (email and push notification)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                    <div className="col-12">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to receive a reminder 1 day before an event where I am registered (email and push notification)</label>
                                                            <input type="text" className="form-control" id="firstname" placeholder="DAY" />
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I want to be informed when new events are added (email and push notification)</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to be informed when new news are added (email and push notification)</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to be informed when new documents are added (email and push notification)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                                    <div className="col-12">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to receive a reminder 1 day before an event where I am registered (email and push notification)</label>
                                                            <input type="text" className="form-control" id="firstname" placeholder="DAY" />
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I want to be informed when new events are added (email and push notification)</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to be informed when new news are added (email and push notification)</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">I would like to be informed when new documents are added (email and push notification)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                   {this.state.no_record_of_Team_specific_configuration==true && !this.state.team_id &&
                                    <div className=" col mr-1 mb-2 bg-light p-2 text-center">
                                        <div className="nodata">
                                            <img src={no_img} />
                                            <div>No Teams available</div>
                                        </div>
                                    </div>
                                }
                            {
                            this.state.no_record_of_Team_specific_configuration==true && this.state.TeamList.length > 0 && 
                            <div className="col-sm-12">
                                <div className="text-end">
                                    <Button onClick={() => this.onSubmit()} color="primary" className="btn btn-success waves-effect waves-light fw-bolder mt-2 fw-bolder">{t("Save")}</Button>
                                </div>
                            </div>
                           }
                        </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default withTranslation()(TeamAttributes);