import React, { Component } from "react";
import { Button, Form, Label, Input } from "reactstrap";
import axios from "axios";
import { Redirect , Link} from "react-router-dom";
import logoDark from '../assets/images/sortimensologo2.png';

import {APIURL, BASEURL} from './constants/Common';

import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/icons.css";
import Language from "./Front/Language/Language";

import { withTranslation } from 'react-i18next';

 class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      msg: "",
      isLoading: false,
      redirect: false,
      errMsgEmail: "",
      errMsgPwd: "",
      errMsg: "",
      scsMsg: "",
    };
  }
  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  onAlertClose = () => {
    this.setState({
      errMsg: "", 
      scsMsg: "", 
    });
  };

  onSignInHandler = () => {
    this.setState({ isLoading: true });
    axios
      .post(APIURL+"forgot-passowrd", {
        email: this.state.email,
      })

      .then((response) => {
          this.setState({
            scsMsg: response.data.message,
            errMsg: "", 
            email: "",
            isLoading:false
            // redirect: true,
          }); 
      })
      .catch((error) => {
         if (error.response.data.message == "validation_error") {
                this.setState({
                    errMsg: error.response.data.errors,
                    isLoading: false,
                    scsMsg:""
                })
            }
            else {
                this.setState({
                    error,
                    isLoading: false,
                    errMsg:{"email":error.response.data.message}
                })
            }
      });
  };

  render() {
    const { t } = this.props;
    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    const login = localStorage.getItem("isLoggedIn");
    if (login) {
      return <Redirect to="/home" />;
    }
    const isLoading = this.state.isLoading;
    return (
      <div className="auth-fluid">

        <div className="auth-fluid-right">
          <div className="auth-user-testimonial">
             <h3 className="mb-3 text-white">{t("Organize your teams")}!</h3>
             <p className="lead fw-normal"><i className="mdi mdi-format-quote-open"></i> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. <i className="mdi mdi-format-quote-close"></i>
             </p>
             <h5 className="text-white">
                Regula Placemann - Zürich Oberland Pumas
             </h5>
          </div>
        </div>
        <div className="auth-fluid-form-box"> <div style={{position:"absolute",top:0,right:50}}><Language /></div>
            <div className="align-items-center d-flex h-100">
              <div className="card-body">
                <div className="auth-brand text-center text-lg-start">
                   <div className="auth-logo">
                      <a href="login" className="text-center">
                        <img src={logoDark} alt="Dark logo" height="22"/>
                      </a>
                   </div>
                </div>
                <h4 className="mt-0 form-heading">{t("Forgot password")}</h4>
                <p className=" mb-4"></p>
                {
                  this.state.errMsg.email ? 
                  <div className={this.state.errMsg.email ? "mb-2  text-danger Login_error" : "mb-2  text-danger" } >
                  {t(this.state.errMsg.email)}
                  </div>:""
                }
                {
                  this.state.scsMsg ?
                  <div className={this.state.scsMsg ? "mb-2  text-primary Login_error" : "mb-2  text-danger" } >
                  {t(this.state.scsMsg)}
                </div>
                :""
                }
                
                <Form action="#">
                  <div className="mb-3">
                    <Label htmlFor="email" className="form-label">{t("E-mail address")}</Label>
                    <Input
                      className="form-control"
                      required=""
                      type="email"
                      name="email"
                      placeholder={t("Enter e-mail address")}
                      value={this.state.email}
                      onChange={this.onChangehandler}
                    />
                    <span className="text-danger">{t(this.state.errMsgEmail)}</span>
                  </div>
                  <div className="d-grid text-center">
                      <Button
                        className="btn btn-primary"
                        onClick={this.onSignInHandler}
                      >
                        {t("Submit")}
                        {isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm ml-5"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <span></span>
                        )}
                      </Button>
                  </div>
                </Form>
                <div className="text-center mt-5">
                  <p className="">Or 
                  <a href={BASEURL+'login'} className="text-primary fw-medium ms-1">{t("Login")}</a>
                  </p>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ForgotPassword);
