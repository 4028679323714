
import React, { Component } from 'react'
import Select from 'react-select'
import axios from "axios";
import {Spinner, Input, Button, Modal, ModalBody, Table, ModalFooter, ModalHeader, Label } from 'reactstrap';
import { APIURL } from '../constants/Common'; import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '../../assets/css/Custom.css'
import { withTranslation } from 'react-i18next';

const options = [
    { value: 0, label: 'No' },
    { value: 1, label: 'yes' },
];

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {

            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};
 class SettingEventTypes extends Component {
    constructor() {
        super();

        this.state = {
            Event_Types_List: [],
            Event_type_id: "",
            Event_type: "",
            Color_code: "#F0D4D4",
            default_deadline_days: "",
            default_duration_hours: "",
            infomail_uncertain: [{ value: 0, label: "No" }],
            infomail_unregistered: [{ value: 0, label: "No" }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            errMsg: "",
            modal: false,
            AddModal: false,
            addEvtModal: false,
            toast: false,
            RemScsMsg: "",
            sortRow: [],
            disabled: false,
            checked: false,
            Task_list: [],
            DefaultTaskSelected: [],
            default_Task: [],
            gatherTime: [],
            active: true,
            removeEvtModal:false,
            yesNo:[]
        }
        this.Editclick = this.Editclick.bind(this);
        this.AddModal = this.AddModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.CloseEditModal = this.CloseEditModal.bind(this)
    }

    toggle(e) {
       const { t } = this.props;
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            addEvtModal: !this.state.addEvtModal,
            errMsg: "",
            Event_type: "",
            Color_code: "#F0D4D4",
            default_deadline_days: [],
            default_duration_hours_hrs:"",
            default_duration_hours_min:"",
            gatherTime_hrs:"",
            gatherTime_min:"",
            default_infomail_uncertain_days: '',
            default_infomail_unregistered_days: '',
            infomail_uncertain: [{ value: 0, label: t("No") }],
            infomail_unregistered: [{ value: 0, label: t("No") }],
            active: true,
            TaskSelected:[]
        });
    }

    AddModal(e) {
        this.setState({
            AddModal: !this.state.AddModal,
        });
    }

    getEventType(id) {
        this.setState({Loader:true})
        var token = this.state.token
        axios
            .get(APIURL + "get_event_types/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        Event_Types_List: response.data.data,
                        Loader:false
                    });
                }
            });
    }
    HandleEventType(e) {
       const { t } = this.props;
        this.setState({
            Event_id: e.event_type_id,
            Event_type: e.Event_type,
            Color_code: e.Color_code,
            default_deadline_days: e.default_deadline_days,
            gatherTime: e.default_gather_time,
            default_duration_hours_hrs: e.default_duration_hours.split(':')[0],
            default_duration_hours_min: e.default_duration_hours.split(':')[1],
            gatherTime_hrs: e.default_gather_time.split(':')[0],
            gatherTime_min: e.default_gather_time.split(':')[1],

            infomail_uncertain: [{ value: e.default_infomail_uncertain, label: e.default_infomail_uncertain == 1 ? t("Yes") : t("No") }],
            infomail_unregistered: [{ value: e.default_infomail_unregistered, label: e.default_infomail_unregistered == 1 ? t("Yes") : t("No") }],
            default_infomail_unregistered_days: e.default_infomail_unregistered_days,
            default_infomail_uncertain_days: e.default_infomail_uncertain_days,
            active: e.active,
        }, () => {
            this.EditDefaultTask()
        })
    }
    EditDefaultTask() {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('event_type_id', this.state.Event_id);
        var token = this.state.token
        axios
            .post(APIURL + "eventtype/edit-default-task", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    TaskSelected: response.data.data
                })
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    HandleEvent(e) {
        this.setState({
            Event_type: e
        })
    }
    handleColor(e) {
        this.setState({
            Color_code: e,
        })
    }
    handleDeadline(e) {
        const re = /^[0-9\b]+$/;
        if (e == '' || re.test(e)) {
            this.setState({
                default_deadline_days: e
            })
        }      
    }
    handlestdDuration_hrs(e) {
        const re = /^[0-9\b]+$/;
        if ( (e == '') || (re.test(e) && e < 24)) {
            this.setState({
                default_duration_hours_hrs: e
            })
        }
       
    }
    handlestdDuration_min(e) {
        const re = /^[0-9\b]+$/;
        if ( (e === '') || (re.test(e) && e < 60)) {
            this.setState({
                default_duration_hours_min: e
            })
        }
        
    }
    handleGatherTime_hrs(e) {
        const re = /^[0-9\b]+$/;
        if ( (e === '') || (re.test(e) && e < 24)) {
            this.setState({
                gatherTime_hrs: e
            })
        }
        
    }
    handleGatherTime_min(e) {
        const re = /^[0-9\b]+$/;
        if ( (e === '') || (re.test(e) && e < 60)) {
            this.setState({
                gatherTime_min: e
            })
        }
       
    }

  
    handle_default_duration_hours(e) {
        this.setState({
            default_duration_hours: e
        })
    }




    uncertainSelect = (e) => {
        this.setState({ infomail_uncertain: [{ value: e.value, label: e.label }] });
    };

    unregisteredSelect = (e) => {
        this.setState({ infomail_unregistered: [{ value: e.value, label: e.label }] });
    };

    handleEntries(e) {
        this.setState({
            default_infomail_uncertain_days: e
        })
    }
    handleRegistred(e) {
        this.setState({
            default_infomail_unregistered_days: e
        })
    }

    activeStatus() {
        this.setState({
            checked: !this.state.checked
        })
    }

    handleStatus(e) {

        this.setState({
            active: e.target.checked
        });
        // alert(this.state.active)
    }

    addEvtType = () => {
        const { t } = this.props;
        if(this.state.default_duration_hours_hrs=="")
        {
            this.state.default_duration_hours_hrs="00";
        }
         if(this.state.default_duration_hours_min=="")
        {
            this.state.default_duration_hours_min="00";
        }

          if(this.state.gatherTime_hrs=="")
        {
            this.state.gatherTime_hrs="00";
        }

          if(this.state.gatherTime_min=="")
        {
            this.state.gatherTime_min="00";
        }
        let default_duration_hours = this.state.default_duration_hours_hrs+":"+this.state.default_duration_hours_min;
        let gather_time = this.state.gatherTime_hrs+":"+this.state.gatherTime_min;
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('event_type_id', '');
        formData.append('event_type', this.state.Event_type);
        formData.append('color_code', this.state.Color_code);
        formData.append('deadline', this.state.default_deadline_days);
        formData.append('duration', default_duration_hours);
        formData.append('gather_time', gather_time);
        formData.append('infomail_uncertain', this.state.infomail_uncertain[0].value);
        formData.append('infomail_uncertain_days', this.state.infomail_uncertain[0].value ? this.state.default_infomail_uncertain_days : null);
        formData.append('infomail_unregistered', this.state.infomail_unregistered[0].value);
        formData.append('infomail_unregistered_days', this.state.infomail_unregistered[0].value ? this.state.default_infomail_unregistered_days : null);
        formData.append('default_task', JSON.stringify(this.state.TaskSelected));
        formData.append('active', Number(this.state.active));
        this.setState({
            Loader: true
        })
        var app_url = APIURL + "create_event_types";
        var token = this.state.token

        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getEventType(this.props.data.teamId);
                this.setState({
                    errMsg: "",
                    Event_type: '',
                    Color_code: '',
                    default_duration_hours: '',
                    default_deadline_days: '',
                    default_infomail_uncertain_days: '',
                    default_infomail_unregistered_days: '',
                    infomail_uncertain: [{ value: 0, label: t("No") }],
                    infomail_unregistered: [{ value: 0, label: t("No") }],
                    errMsgAttr: '',
                    addEvtModal: false,
                    Loader: false
                });
            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false,
                        Loader: false,
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false,
                        Loader: false,
                    })
                }
            }
            );
    }

    handleAddEvtType = () => {
      const { t } = this.props;
        /*if (this.state.Event_type){
            this.setState({ errMsg: { event_type: 'Please enter member email.'} }); 
        }
        if (this.state.color_code){
            this.setState({ errMsg: { color_code: 'Please select function.'} });   
        }*/
        if (this.state.Event_type) {
            this.setState({ AddModal: false })
            this.setState({
                Event_Types_List: this.state.Event_Types_List.concat([{
                    Event_type_id: "",
                    Event_type: this.state.Event_type,
                    Color_code: this.state.Color_code,
                    default_deadline_days: this.state.default_deadline_days,
                    default_duration_hours: this.state.default_duration_hours,
                    default_infomail_uncertain: this.state.default_infomail_uncertain,
                    default_infomail_unregistered: this.state.default_infomail_unregistered,
                    event_type_id: ""
                }]),
                errMsg: "",
                Event_type: '',
                Color_code: '',
                default_duration_hours: '',
                default_deadline_days: '',
                default_infomail_uncertain_days: '',
                default_infomail_unregistered_days: '',
                infomail_uncertain: [{ value: 0, label: t("No") }],
                infomail_unregistered: [{ value: 0, label: t("No") }],
                errMsgAttr: '',
                addEvtModal: false,
                Loader: false
            });
        }
        else {
            this.setState({ errMsgAttr: 'Please enter event type.' });
        }
    }


    onSubmit = () => {
         if(this.state.default_duration_hours_hrs=="")
        {
            this.state.default_duration_hours_hrs="00";
        }
         if(this.state.default_duration_hours_min=="")
        {
            this.state.default_duration_hours_min="00";
        }

          if(this.state.gatherTime_hrs=="")
        {
            this.state.gatherTime_hrs="00";
        }

          if(this.state.gatherTime_min=="")
        {
            this.state.gatherTime_min="00";
        }

        let default_duration_hours = this.state.default_duration_hours_hrs+":"+this.state.default_duration_hours_min;
        let gather_time = this.state.gatherTime_hrs+":"+this.state.gatherTime_min;
        const formData = new FormData();
        formData.append('event_type', this.state.Event_type);
        formData.append('color_code', this.state.Color_code);
        formData.append('deadline', this.state.default_deadline_days);
        formData.append('duration', default_duration_hours);
        formData.append('gather_time', gather_time);
        formData.append('infomail_uncertain', this.state.infomail_uncertain[0].value);
        formData.append('infomail_uncertain_days', this.state.infomail_uncertain[0].value ? this.state.default_infomail_uncertain_days : null);
        formData.append('infomail_unregistered', this.state.infomail_unregistered[0].value);
        formData.append('infomail_unregistered_days', this.state.infomail_unregistered[0].value ? this.state.default_infomail_unregistered_days : null);
        formData.append('team_id', this.props.data.teamId);
        formData.append('active', Number(this.state.active));
        formData.append('default_task', JSON.stringify(this.state.TaskSelected));

        var app_url = APIURL + "edit_event_type/" + this.state.Event_id
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getEventType(this.props.data.teamId);
                this.setState({
                    msg: response.data.message,
                    errMsg: '',
                    redirect: true,
                    modal: false
                });

            })
            .catch(error => {

                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false,
                        Loader: false,
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false,
                        Loader: false,
                    })
                }

            }
            );
    };

    handleRemoveEvtModal = (e) =>{
        this.setState({
            removeEvtId:e.event_type_id,
            removeEvtModal:!this.state.removeEvtModal
        })
    }
    RemoveModalClose(){
        this.setState({
            removeEvtModal:false
        })
    }


    handleRemoveAtr(e) {

        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        axios.post(APIURL + "delete_event_type/" +this.state.removeEvtId, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
              
                this.setState({
                    RemScsMsg: response.data.message,
                    redirect: true,
                    removeEvtModal:false
                })
                this.setState({
                    Event_Types_List: this.state.Event_Types_List.filter((s, sidx) =>this.state.removeEvtId != s.event_type_id
                    )
                },() => {
                this.getEventType(this.props.data.teamId)
                })
            })
            .catch(error => {
           
                this.setState({
                    errMsg: { message: error.response.data.message },
                    removeEvtModal:false
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }

    Editclick(e) {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.HandleEventType(e)
        this.setState({
            modal: !this.state.modal,

        });
    }
    CloseEditModal() {
        this.setState({
            modal: false
        });
    }


    handleStep(type, index) {
        const sort_List = this.state.Event_Types_List;

        let sortRow = []; let selfsort; let othersort;

        if (type == "up") {

            const self = sort_List[index];
            const other = sort_List[index - 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index - 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['event_type_id'], "sort": self['sort'] });
            sortRow.push({ id: other['event_type_id'], "sort": other['sort'] });

            this.setState({
                Event_Types_List: sort_List
            })

        }
        else if (type == "down") {
            const self = sort_List[index];
            const other = sort_List[index + 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index + 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['event_type_id'], "sort": self['sort'] });
            sortRow.push({ id: other['event_type_id'], "sort": other['sort'] });

            this.setState({
                Event_Types_List: sort_List
            })
        }
        this.setState({
            sortRow: sortRow
        }, () => { this.updateStep() })

    }

    updateStep() {
        // this.setState({ func_List: this.state.func_List.filter((s, sidx) => idx !== sidx) });
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "eventtype/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {

            })
            .catch(error => {

                this.setState({
                    errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }

    getTasks() {
        this.setState({Loader:true})
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "event/get_tasks", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                let Task_list = response.data.data; let DefultSec = [];
                let Task_listArray=[];
                for (var c = 0; c < Task_list.length; c++) {
                    Task_listArray.push({ value: Task_list[c].task_id, label: Task_list[c].task_name })
                    if (Task_list[c].is_default == "Yes") {
                        DefultSec.push({ value: Task_list[c].task_id, label: Task_list[c].task_name })
                    }
                }

                this.setState({
                    DefaultTaskSelected: DefultSec,
                    TaskSelected: DefultSec,
                    Task_list:Task_listArray,
                    Loader:false
                })
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false,
                    Loader:false
                })
            );
    }

    EventTaskSelect = TaskSelected => {
        this.setState({ TaskSelected });
    };
    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getEventType(this.props.data.teamId);
            this.getTasks()
        }
         this.getOption();
    }
    componentWillReceiveProps() {
        if (this.props.data.teamId > 0) {
        
            this.getTasks();
        }
         this.getOption();
    }

     getOption(){
        const { t } = this.props;
        let yesNo=[];
        yesNo.push({ value: 0, label: t('No') })
        yesNo.push({ value: 1, label: t('Yes') })

        let infomail_uncertain=this.state.infomail_uncertain;
        infomail_uncertain= [{ value: infomail_uncertain[0].value, label: infomail_uncertain[0].label}]

        let infomail_unregistered=this.state.infomail_unregistered;
        infomail_unregistered= [{ value: infomail_unregistered[0].value, label: infomail_unregistered[0].label}]
 
       this.setState({ 
        yesNo: yesNo,
        infomail_uncertain:infomail_uncertain,
        infomail_unregistered:infomail_unregistered
       })
   }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                 {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <h5>{t("Event types")}</h5>
                                {this.state.errMsg.message &&
                                    <span className="text-danger">{this.state.errMsg.message}</span>
                                }
                                <div className="table-responsive">
                                    <Table hover className="table table-hover table-centered nowrap">
                                        <thead className="bg-light">
                                            <tr>
                                            <th></th>
                                            <th>{t("Id")}</th>
                                            <th>{t("Event type")}</th>
                                            <th>{t("Color")}</th>
                                            <th>{t("Deadline (days)")} </th>
                                            <th>{t("Standard duration (hh:mm)")}</th>
                                            <th>{t("Meeting time (hh:mm)")} </th>
                                            <th>{t("Send information e-mail and push notification to all members who have 'not' registered until (number of days before registration deadline)")} </th>
                                            <th>{t("Send information e-mail and push notification to all members who have registered with 'maybe' until (number of days before registration deadline)")}</th>
                                            <th>{t("Default tasks")}</th>
                                            <th>{t("Active")}</th>
                                            <th className="text-center" style={{ width: "170px" }}>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.Event_Types_List.length > 0 && this.state.Event_Types_List.map((item, idx) => (

                                                <tr key={item.event_type_id}>
                                                    <td width="50">
                                                        {idx != 0 &&
                                                            <i onClick={(e) => this.handleStep('up', idx)} className="mdi mdi-arrow-up"></i>
                                                        }
                                                        {this.state.Event_Types_List.length != idx + 1 &&
                                                            <i onClick={(e) => this.handleStep('down', idx)} className="mdi mdi-arrow-down"></i>
                                                        }
                                                    </td>
                                                    <th scope="row">#{item.event_type_id}</th>
                                                    <td>{item.Event_type}</td>

                                                    <td>
                                                        <div style={{ width: "30px", height: "25px", backgroundColor: item.color_code, borderRadius: "5px" }}></div>

                                                    </td>
                                                    <td>{item.default_deadline_days}</td>
                                                    <td>{item.default_duration_hours}</td>
                                                    {/*<td>{item.default_duration_hours != "00:00" ? item.default_duration_hours : "Null"}</td> */}
                                                    <td>{item.default_gather_time}</td>
                                                    <td>
                                                        {item.default_infomail_uncertain ? 'Yes / ' + item.default_infomail_uncertain_days + ' day before' : 'No'}
                                                    </td>
                                                    <td>
                                                        {item.default_infomail_unregistered ? 'Yes / ' + item.default_infomail_unregistered_days + ' day before' : 'No'}
                                                    </td>
                                                    <td style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>{item.task_name}</td>
                                                    <td>
                                                        {item.active === 0 ? <span>No</span>
                                                            :
                                                            item.active === 1 ? <span>Yes</span>
                                                                :
                                                                ""
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <Button size="sm" color="warning" className="mobile-icon" onClick={(e) => this.Editclick(item)} > <i className="ri-pencil-fill"></i> <span>{t("Edit")}</span></Button>
                                                            <Button size="sm" color="danger"  className="mobile-icon ms-2" onClick={(e) => this.handleRemoveEvtModal(item)}> <i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                                        </div>
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </Table>
                                    <div className=" mt-3">
                                        <Button
                                            type="button"
                                            id="add_attrs"
                                            onClick={this.toggle}
                                            className="btn btn-warning waves-effect waves-light mt-2 add_attrs"
                                        >
                                           {t("Add event type")} 
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal size="md" isOpen={this.state.addEvtModal}  autoFocus={false} >
                    <ModalHeader toggle={this.toggle}>{t("Add event type")}</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295",height:"500px",overflowY:"auto" }}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <Label>{t("Event type")} *</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Enter event type")}
                                        value={this.state.Event_type}
                                        onChange={(e) => this.HandleEvent(e.target.value)}
                                        autoFocus={true}
                                    />
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.event_type)}</span>
                                        :"" 
                                    }                                    
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-group">
                                    <Label>{t("Color")} *</Label>
                                    <Input
                                        className="form-control-color"
                                        type="color"
                                        // id="example-color"
                                        name="color"
                                        value={this.state.Color_code}
                                        onChange={(e) => this.handleColor(e.target.value)} />
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.color_code)}</span>
                                        :"" 
                                    }                                      
                                </div>
                            </div>
                        </div>                        

                        <div className="zIndex_4">
                            <div className="form-group">
                                <Select
                                    aria-label=".form-select-lg example"
                                    required=""
                                    placeholder={t("Select Default Task")}
                                    options={this.state.Task_list}
                                    value={this.state.TaskSelected}
                                    onChange={this.EventTaskSelect}
                                    isMulti={true}
                                    styles={colourStyles}
                                    maxMenuHeight={200}
                                    menuPlacement="auto"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <Label>{t("Send information e-mail and push notification to all members who have 'not' registered until (number of days before registration deadline)")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder={t("Select")}
                                        options={this.state.yesNo}
                                        value={this.state.infomail_uncertain}
                                        onChange={this.uncertainSelect}
                                        styles={colourStyles}
                                    />
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.infomail_uncertain)}</span>
                                        :"" 
                                    }  
                                    
                                </div>
                                <div className="form-group">
                                    {this.state.infomail_uncertain[0].value > 0 &&
                                        <Input
                                            type="number"
                                            min={0}
                                            className="form-control"
                                            placeholder={t("Enter uncertain entries days")}
                                            value={this.state.default_infomail_uncertain_days}
                                            onChange={(e) => this.handleEntries(e.target.value)}
                                        />}
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.infomail_uncertain_days)}</span>
                                        :"" 
                                    }  
                                    
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                   <Label>{t("Send information e-mail and push notification to all members who have registered with 'maybe' until (number of days before registration deadline)")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder={t("Select")}
                                        options={this.state.yesNo}
                                        value={this.state.infomail_unregistered}
                                        onChange={this.unregisteredSelect}
                                        styles={colourStyles}
                                    />
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.infomail_unregistered)}</span>
                                        :"" 
                                    }  
                                    
                                </div>
                                <div className="form-group">
                                    {this.state.infomail_unregistered[0].value > 0 &&
                                        <Input
                                            type="number"
                                            min={0}
                                            className="form-control"
                                            placeholder={t("Enter unregistered days")}
                                            value={this.state.default_infomail_unregistered_days}
                                            onChange={(e) => this.handleRegistred(e.target.value)}
                                        />}
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.infomail_unregistered_days)}</span>
                                        :"" 
                                    }  
                                    
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <Label>{t("Standard deadline (days)")}*</Label>
                                    <Input
                                        // type="number"
                                        // min={0}
                                        className="form-control"
                                        placeholder={t("Deadline (days)")}
                                        value={this.state.default_deadline_days}
                                        onChange={(e) => this.handleDeadline(e.target.value)} />
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.deadline)}</span>
                                        :"" 
                                    }  
                                    
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <Label>{t("Standard duration (hh:mm)")}</Label>
                                    <div className="d-flex">
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="23"
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.default_duration_hours_hrs}
                                        onChange={(e) => this.handlestdDuration_hrs(e.target.value)} />
                                        &nbsp;&nbsp;&nbsp;
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="59"
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.default_duration_hours_min}
                                        onChange={(e) => this.handlestdDuration_min(e.target.value)} />
                                    </div>
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.duration)}</span>
                                        :"" 
                                    }  
                                    
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                <Label>{t("Meeting time (hh:mm)")} </Label>
                                    <div className="d-flex">
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="23"
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.gatherTime_hrs}
                                        onChange={(e) => this.handleGatherTime_hrs(e.target.value)} />
                                        &nbsp;&nbsp;&nbsp;
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="59"
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.gatherTime_min}
                                        onChange={(e) => this.handleGatherTime_min(e.target.value)} />
                                    </div>
                                    {
                                        this.state.errMsg ?
                                        <span className="text-danger ms-1">{t(this.state.errMsg.gather_time)}</span>
                                        :"" 
                                    }  
                                    
                                </div>
                            </div>                        
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-12">
                                <input
                                    className="form-check-input mr-1"
                                    type="checkbox"
                                    onChange={(e) => this.handleStatus(e)}
                                    checked={this.state.active}
                                    id="flexCheckDefault" />
                                <Label className="mb-0">{t("Active")} </Label>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                          <button className="btn btn-danger" onClick={() => this.toggle()}> {t("Cancel")} </button>
                          <Button className="btn btn-success" onClick={() => this.addEvtType()}>{t("Save")} </Button>
                            </div>

                    </ModalFooter>
                </Modal>

                <Modal size="md" isOpen={this.state.modal} autoFocus={false}>
                    <ModalHeader toggle={this.CloseEditModal}>{t("Edit event type")}</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295" }}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <Label>{t("Enter event type")}</Label>
                                    <Input
                                        type="text"
                                        placeholder="Event type"
                                        value={this.state.Event_type}
                                        onChange={(e) => this.HandleEvent(e.target.value)}
                                        autoFocus={true}
                                    />
                                    { this.state.errMsg ? <span className="text-danger ms-1">{t(this.state.errMsg.event_type)}</span> : ""}
                                 
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <Label>{t("Color")}</Label>
                                    <Input
                                        className="form-control-color"
                                        type="color"
                                        id="example-color"
                                        name="color"
                                        value={this.state.Color_code}
                                        onChange={(e) => this.handleColor(e.target.value)} />
                                        { this.state.errMsg ? <span className="text-danger ms-1">{t(this.state.errMsg.color_code)}</span> : ""}                                    
                                </div>
                            </div>
                        </div>

                        <div className="zIndex_4">
                            <div className="form-group">
                                <Select
                                    aria-label=".form-select-lg example"
                                    required=""
                                    placeholder="Select Default Task"
                                    options={this.state.Task_list}
                                    value={this.state.TaskSelected}
                                    onChange={this.EventTaskSelect}
                                    isMulti={true}
                                    styles={colourStyles}
                                    maxMenuHeight={200}
                                    menuPlacement="auto"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                        <Label>{t("Send information e-mail and push notification to all members who have 'not' registered until (number of days before registration deadline)")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder={t("Select")}
                                        options={this.state.yesNo}
                                        value={this.state.infomail_uncertain}
                                        onChange={this.uncertainSelect}
                                        styles={colourStyles}
                                    />
                                    { this.state.errMsg ?  <span className="text-danger ms-1">{t(this.state.errMsg.infomail_uncertain)}</span>: ""}
                                </div>
                                <div className="form-group">
                                    {this.state.infomail_uncertain[0].value > 0 &&
                                    <Input
                                        type="number"
                                        min={0}
                                        className="form-control"
                                        placeholder={t("Enter uncertain entries days")}
                                        value={this.state.default_infomail_uncertain_days}
                                        onChange={(e) => this.handleEntries(e.target.value)}
                                    />}
                                    { this.state.errMsg ?   <span className="text-danger ms-1">{t(this.state.errMsg.infomail_uncertain_days)}</span>: ""}                                   
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                        <Label>{t("Send information e-mail and push notification to all members who have registered with 'maybe' until (number of days before registration deadline)")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder={t("Select")}
                                        options={this.state.yesNo}
                                        value={this.state.infomail_unregistered}
                                        onChange={this.unregisteredSelect}
                                        styles={colourStyles}
                                    />
                                    { this.state.errMsg ? <span className="text-danger ms-1">{t(this.state.errMsg.infomail_unregistered)}</span>: ""}                                   
                                </div>
                                <div className="form-group">
                                    {this.state.infomail_unregistered[0].value > 0 &&
                                    <Input
                                        type="number"
                                        min={0}
                                        className="form-control"
                                        placeholder={("Enter unregistered days")}
                                        value={this.state.default_infomail_unregistered_days}
                                        onChange={(e) => this.handleRegistred(e.target.value)}
                                    />}
                                    { this.state.errMsg ? <span className="text-danger ms-1">{t(this.state.errMsg.infomail_unregistered_days)}</span>: ""}                                   
                                </div>
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <Label>{t("Standard deadline (days)")}</Label>
                                    <Input
                                        // type="number"
                                        // min={0}
                                        className="form-control"
                                        placeholder={t("Deadline (days)")}
                                        value={this.state.default_deadline_days}
                                        onChange={(e) => this.handleDeadline(e.target.value)} />
                                    { this.state.errMsg ? <span className="text-danger ms-1">{t(this.state.errMsg.deadline)}</span>: ""}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <Label>{t("Standard duration (hh:mm)")}</Label>
                                    <div className="d-flex">
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="23"
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.default_duration_hours_hrs}
                                        onChange={(e) => this.handlestdDuration_hrs(e.target.value)} />
                                        &nbsp;&nbsp;&nbsp;
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="59"
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.default_duration_hours_min}
                                        onChange={(e) => this.handlestdDuration_min(e.target.value)} />
                                        { this.state.errMsg ? <span className="text-danger ms-1">{t(this.state.errMsg.duration)}</span>: ""}                                   
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <Label>{t("Meeting time (hh:mm)")}</Label>
                                    <div className="d-flex">
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="23"/
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.gatherTime_hrs}
                                        onChange={(e) => this.handleGatherTime_hrs(e.target.value)} />
                                        &nbsp;&nbsp;&nbsp;
                                    <Input
                                        // type="number"
                                        // min="0"
                                        // max="59"
                                        className="form-control"
                                        placeholder="00"
                                        value={this.state.gatherTime_min}
                                        onChange={(e) => this.handleGatherTime_min(e.target.value)} />
                                    </div>
                                    { this.state.errMsg ? <span className="text-danger ms-1">{t(this.state.errMsg.gather_time)}</span>: ""}                                   
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-12">
                                <input
                                    className="form-check-input mr-1"
                                    type="checkbox"
                                    onChange={(e) => this.handleStatus(e)}
                                    checked={this.state.active}
                                    id="flexCheckDefault" />
                                <Label className="mb-0">{t("Active")}</Label>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger fw-bolder" onClick={() => this.CloseEditModal()}>{t("Cancel")} </Button>
                            <Button className="btn btn-success fw-bolder" onClick={() => this.onSubmit()}>{t("Save")} </Button>
                        </div>

                    </ModalFooter>
                </Modal>
                <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeEvtModal} >
                    <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">{t("Are you sure want to remove")} ?</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={()=> this.handleRemoveAtr()} >{t("Confirm")} </Button>{' '}
                                <Button className="mx-2" color="default" onClick={()=> this.RemoveModalClose()}>{t("Cancel")}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(SettingEventTypes);
