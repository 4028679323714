import React, { Component } from "react";
import Sidebar from "../../GeneralComponents/Sidebar";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import { APIURL } from '../../constants/Common';
import axios from "axios";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { Button, Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import dateFormat from 'dateformat';
import { withTranslation } from 'react-i18next';

class Future extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: JSON.parse(localStorage.getItem("userData")),
      token: JSON.parse(localStorage.getItem("token")),
      activeTab: "",
      isLoading: false,
      repos: [],
      error: [],
      team_name: "",
      teamId: this.props.match.params.id,
      eventId: "",
      index: "",
      SubscriptionModal: false,
      subscriptionPlan: "No",
      is_owner: "No",
      day: 0,
      ActiveTeam: 0,
      isPermission: 1,
      series_event_id: 0,
      SeriesModal: false,
      isSeriestypeRedirect: false,
      allchecked: false,
      removeEvtALLDeleteModal: false,
      isMutiDeleteDesable: true,
      no_record: false

    };

  }

  componentDidMount() {
    document.body.classList.remove('front-end')
    this.EventList();
    if (this.state.teamId != "undefined") {
      this.getExpirationPlanMsg()
    }

  }

  EventList() {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('team_id', this.state.teamId);
    var token = this.state.token
    /* fetch repos with axios */
    axios
      .post(APIURL + "event/future", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(result => {

        this.setState({
          repos: result.data.data,
          isLoading: false,
          no_record: true
        });
        if (result.data.team != null) {
          this.setState({
            team_name: result.data.team.team_name
          })
        }

      })
      .catch(error =>
        this.setState({
          error,
          isLoading: false
        })
      );
  }

  getClone(item) {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append('item', item);
    formData.append('team_id', this.state.user.user_id);

    var token = this.state.token
    /* fetch repos with axios */
    axios
      .post(APIURL + "event/clone", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(result => {
        this.setState({
          // repos: [...result.data.data,...this.state.repos],
          isLoading: false
        });
        this.EventList()
      })
      .catch(error =>
        this.setState({
          error,
          isLoading: false
        })
      );
  }

  removeEvtModal(e, idx, eventTtype) {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    if (eventTtype === "single") {
      this.setState({
        index: idx,
        eventId: e,
        removeEvtModal: !this.state.removeEvtModal
      })
    }
    if (eventTtype === "multiple") {
      this.setState({
        index: idx,
        eventId: e,
        removeMultipleEvtModal: !this.state.removeMultipleEvtModal
      })
    }
  }

  removeMultipleEvtModal(e, idx) {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    this.setState({
      index: idx,
      eventId: e,
      removeMultipleEvtModal: !this.state.removeMultipleEvtModal
    })
  }



  RemoveModalClose() {

    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    this.setState({
      removeEvtModal: false,
      removeMultipleEvtModal: false
    })
  }

  RemoveMultipleModalClose() {

    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    this.setState({
      removeMultipleEvtModal: false
    })
  }


  doDelete(singleMultiple) {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('item', this.state.eventId);
    formData.append('team_id', this.state.user.user_id);
    formData.append('option', singleMultiple);
    var token = this.state.token
    /* fetch repos with axios */
    axios
      .post(APIURL + "event/delete", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(result => {

        const newRep = [...this.state.repos];
        newRep.splice(this.state.index, 1);
        if (singleMultiple == "multiple") {

          this.EventList();
        }





        this.setState({
          repos: newRep,
          isLoading: false,
          removeEvtModal: false,
          removeMultipleEvtModal: false
        }, () => { this.checkIsAllChecked() });

      })
      .catch(error =>
        this.setState({
          removeEvtModal: false,
          removeMultipleEvtModal: false,
          error,
          isLoading: false
        })
      );
  }

  doArchive(item, index) {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append('item', item);
    formData.append('team_id', this.state.user.user_id);
    formData.append('status', "archive");
    var token = this.state.token
    /* fetch repos with axios */
    axios
      .post(APIURL + "event/update-status", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(result => {

        const newRep = [...this.state.repos];
        newRep.splice(index, 1);

        this.setState({
          repos: newRep,
          isLoading: false
        }, () => { this.checkIsAllChecked() });

      })
      .catch(error =>
        this.setState({
          error,
          isLoading: false
        })
      );
  }

  getExpirationPlanMsg = () => {
    console.log(this.state.user.user_id)
    const formData = new FormData();
    formData.append('user_id', this.state.user.user_id);
    formData.append('team_id', this.state.teamId);
    var token = this.state.token
    axios
      .post(APIURL + "license_booking/send_notifications", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        console.log("Subscription", response)
        let days = response.data.after_days;
        let Owner = response.data.is_owner;
        let Sub_true = false; let isPermission = 1;
        if ((response.data.subscriptionPlan == "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
          Sub_true = true;
        }

        if (days > 60 || response.data.member.length == 0) {
          isPermission = 0;
        }
        else if (response.data.member.length == 1) {
          if (response.data.member[0].is_admin == 0 && response.data.member[0].is_teamleader == 0) {
            isPermission = 0;
          }
        }

        this.setState({
          SubscriptionModal: Sub_true,
          subscriptionPlan: response.data.subscriptionPlan,
          is_owner: response.data.is_owner,
          day: response.data.after_days,
          ActiveTeam: response.data.active,
          isPermission: isPermission

        })
      });
  }
  SubscriptionModal = () => {
    this.setState({
      SubscriptionModal: !this.state.SubscriptionModal
    })
  }

  Sub_CloseModal() {
    this.setState({
      SubscriptionModal: !this.state.SubscriptionModal
    })
  }
  goToAddEvent() {
    this.setState({
      MoveToAddEvent: true
    })
  }

  doSeriesType(event_id) {
    this.setState({
      series_event_id: event_id
    }, () => { this.SeriesTypeModal() })


  }

  SeriesTypeModal() {

    this.setState({
      SeriesModal: !this.state.SeriesModal
    })
  }

  handleDeleteChange = (e, index) => {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    const repos = this.state.repos
    repos[index]['checked'] = e.target.checked;
    this.setState({ repos: repos }, () => { this.checkIsAllChecked() });
  };

  handleAllDeleteChange = (e) => {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    let isMutiDeleteDesable = true;
    const repos = this.state.repos
    repos.map((tasks, index) => {
      repos[index]['checked'] = e.target.checked;
      if (e.target.checked == true) {
        isMutiDeleteDesable = false;
      }
    });

    this.setState({ repos: repos, allchecked: e.target.checked, isMutiDeleteDesable: isMutiDeleteDesable });
  };

  checkIsAllChecked = () => {

    const repos = this.state.repos
    let totalLength = repos.length;
    let newLength = 0; let allchecked = false;
    let isMutiDeleteDesable = true;
    repos.map((tasks, index) => {
      if (repos[index]['checked'] == true) {
        newLength++;
        isMutiDeleteDesable = false;
      }
    });

    if (newLength == totalLength) {
      allchecked = true;
    }

    this.setState({ allchecked: allchecked, isMutiDeleteDesable: isMutiDeleteDesable });

  }

  RemoveAllDeleteModalClose() {
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    this.setState({
      removeEvtALLDeleteModal: !this.state.removeEvtALLDeleteModal
    })
  }

  doMultipleDelete() {
    this.setState({
      removeEvtALLDeleteModal: false
    })
    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }
    const repos = this.state.repos
    let newLength = 0; let item = [];
    repos.map((event, index) => {
      if (event.checked == true) {
        item.push(event.event_id);
        newLength++;
      }
    });

    if (newLength < 1) {
      return false;
    }

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append('item', JSON.stringify(item));
    var token = this.state.token
    /* fetch repos with axios */
    axios
      .post(APIURL + "event/multi-delete", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(result => {
        this.EventList();
        this.setState({
          isLoading: false,
          allchecked: false,
          isMutiDeleteDesable: true,
        });

      })
      .catch(error =>
        this.setState({
          error,
          isLoading: false
        })
      );

  }

  updateSeriesType(seriestype) {

    if (this.state.ActiveTeam === 0) {
      this.Sub_CloseModal()
      return false;
    }

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append('event_id', this.state.series_event_id);
    formData.append('seriestype', seriestype);
    var token = this.state.token
    /* fetch repos with axios */
    axios
      .post(APIURL + "event/update-seriestype", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(result => {
        this.setState({
          isLoading: false,
          isSeriestypeRedirect: true
        });


      })
      .catch(error =>
        this.setState({
          error,
          isLoading: false
        })
      );

  }

  render() {
    const { t } = this.props;
    console.log("Future", this.props)
    if (!this.state.user) {
      return <Redirect to="/login" push={true} />;
    }
    else if (!this.state.isPermission) {
      return <Redirect to="/permission" push={true} />;
    }
    else if (this.state.isSeriestypeRedirect) {
      return <Redirect to={"/editEvent/" + this.state.series_event_id + "/" + this.state.teamId} push={true} />;

    }
    const { repos, error } = this.state

    return (
      <React.Fragment>
        <div id="wrapper">
          <Sidebar
            data={{
              // CloseModal:this.CloseModal.bind(),
              teamId: this.props.match.params.id,
              team_name: this.state.team_name,
              ActivePage: "events",
              visible: this.props.location.visible
            }} />

          <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
            <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
            <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
              <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                {
                  this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                  <h6 className="mt-3">Upgrade Package.</h6>
                }
                {
                  this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                  <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>
                }
                {
                  this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                  <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                }
                {
                  this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                  <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                }
                {
                  this.state.day >= 30 && this.state.day < 61 &&
                  <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Imenso if the team is to be reactivated.</h6>
                }

              </div>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex justify-content-between w-100 text-center">
                <Button className="btn btn-danger" onClick={() => this.Sub_CloseModal()}>Cancel </Button>
                {this.state.is_owner === "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>Upgrade Package</Button> : ""}
              </div>
            </ModalFooter>
          </Modal>

          <div className="content-page">
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box page-title-box-alt">
                      <h4 className="page-title">{this.state.team_name} | Events </h4>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <ul className="nav nav-tabs ">
                      <li className="nav-item">
                        <Link to={"/event/" + this.state.teamId} className="nav-link active">
                          <span>{t("Future events")}</span></Link>
                      </li>
                      <li className="nav-item">
                        <Link to={"/eventPast/" + this.state.teamId} className="nav-link">
                          <span>{t("Past events")}</span></Link>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div className="tab-pane active" id="events1">
                        <div className="row mb-3">
                          <div className="col-12">
                            <Link
                              to={"/eventAdd/" + this.state.teamId}
                              className="btn btn-primary waves-effect waves-light mt-0 add_attrs"
                              onClick={() => this.goToAddEvent()}
                            >
                              {/* <i className="mdi mdi-plus-circle"></i>  */}
                              <i className="mdi mdi-plus"></i>{t("Add event")}
                            </Link>

                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <div>
                                  <Button className="mx-2 mb-2" disabled={this.state.isMutiDeleteDesable} width="50px" color="light" onClick={() => this.RemoveAllDeleteModalClose()} >Delete</Button>{' '}
                                </div>
                                <div className="table-responsive table-wrapper">

                                  <React.Fragment>

                                    <table className="table table-hover tableFixHead">
                                      <thead className="bg-light">

                                        <tr>
                                          <th className="fw-medium"><Input type="checkbox" checked={this.state.allchecked === 'true' || this.state.allchecked === true} onChange={(e) => this.handleAllDeleteChange(e)} /></th>
                                          <th className="fw-medium" width="90">{t("Date")}</th>
                                          <th className="fw-medium" width="80">{t("Event")}</th>
                                          {/*<th className="fw-medium" style={{ maxWidth: "300px" }}>Information</th>*/}
                                          <th className="fw-medium">{t("Location")}</th>
                                          <th className="fw-medium" width="150">{t("Time: meeting time")}</th>
                                          <th className="fw-medium" width="100">{t("Time: start")}</th>
                                          <th className="fw-medium" width="100">{t("Time: end")}</th>
                                          <th className="fw-medium" width="160">{t("Registration deadline")}</th>
                                          <th className="fw-medium" width="100">{t("Event type")}</th>
                                          <th className="fw-medium">{t("Action")}</th>
                                        </tr>
                                      </thead>
                                      <tbody className="font-14">
                                        {repos.length > 0 && repos.map((result, index) =>
                                          <tr>
                                            <td><Input type="checkbox" checked={result.checked === 'true' || result.checked === true} value={result.checked} onChange={(e) => this.handleDeleteChange(e, index)} /></td>
                                            <td>{dateFormat(result.date, "dd.mm.yyyy")}</td>
                                            <td>
                                              <div className="icon_absolute">
                                                {result.series_event_type == "multiple" &&
                                                  <i className="mdi mdi-twitter-retweet"></i>
                                                }
                                                <span>{result.name}</span>
                                              </div>
                                            </td>
                                            {/*<td>{result.info ? result.info : "-"}</td>*/}
                                            <td>{result.place}</td>
                                            <td>{result.time_gather}</td>
                                            <td>{result.time_from}</td>
                                            <td>{result.time_to}</td>
                                            <td>{dateFormat(result.registration_deadline, "dd.mm.yyyy")}</td>
                                            <td>{result.event_type_name}</td>

                                            <td>
                                              <Menu style={{ color: "red", }} direction="left" menuButton={<MenuButton color="primary" className="Menuedit  ">....</MenuButton>}>
                                                {result.series_event_type == "single" ?
                                                  <MenuItem href={"/editEvent/" + result.event_id + "/" + this.state.teamId} ><i className="ri-pencil-line me-2  font-18 vertical-middle"></i>{t("Edit")}</MenuItem>
                                                  :
                                                  <MenuItem onClick={() => this.doSeriesType(result.event_id)} ><i className="ri-pencil-line me-2  font-18 vertical-middle"></i>{t("Edit")}</MenuItem>
                                                }
                                                <MenuItem onClick={() => this.getClone(result.event_id)}><i className="mdi mdi-content-copy me-2  font-18 vertical-middle"></i>{t("Copy")}</MenuItem>

                                                {result.series_event_type == "single" ?
                                                  <MenuItem onClick={() => this.removeEvtModal(result.event_id, index, 'single')}><i className="ri-delete-bin-7-line me-2  font-18 vertical-middle"></i>{t("Remove")}</MenuItem>
                                                  :
                                                  <MenuItem onClick={() => this.removeMultipleEvtModal(result.event_id, index)}><i className="ri-delete-bin-7-line me-2  font-18 vertical-middle"></i>{t("Remove")}</MenuItem>

                                                }
                                                {/* <MenuItem onClick={() => this.doArchive(result.event_id, index)}><i className="ri-inbox-archive-line me-2  font-18 vertical-middle"></i>{t("Archive")}</MenuItem> */}
                                              </Menu>


                                            </td>
                                          </tr>
                                        )}
                                        {this.state.no_record == true && !repos.length &&
                                          <tr>
                                            <td colSpan="12" className="text-center">{t("No Event Found")}</td>
                                          </tr>
                                        }

                                      </tbody>
                                    </table>
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* single remove event event  modal*/}
        <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeEvtModal}  >
          <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
              <h6 className="mt-3">{t("Are you sure want to remove")} ?</h6>
              <p className="mt-4 col-12">
                <Button className="mx-2" width="50px" color="light" onClick={() => this.doDelete("single")} >{t("Confirm")} </Button>{' '}
                <Button className="mx-2" color="default" onClick={() => this.RemoveModalClose()}>{t("Cancel")}</Button>
              </p>
            </div>
          </ModalBody>
        </Modal>

        <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeEvtALLDeleteModal}  >
          <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
              <h6 className="mt-3">{t("Are you sure want to remove")} ?</h6>
              <p className="mt-4 col-12">
                <Button className="mx-2" width="50px" color="light" onClick={() => this.doMultipleDelete("single")} >{t("Confirm")} </Button>{' '}
                <Button className="mx-2" color="default" onClick={() => this.RemoveAllDeleteModalClose()}>{t("Cancel")}</Button>
              </p>
            </div>
          </ModalBody>
        </Modal>


        <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#555" }} isOpen={this.state.SeriesModal}  >
          <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#555" }}>
            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
              <i className="ri-repeat-fill" style={{ fontSize: "20px" }}></i>
              <h6 className="mt-3">{t("You are changing a series event")} </h6>
              <p className="mt-2 small">{t("Do you want to change only this series element or all series elements")} </p>
              <p>
                <Button className="m-1" color="light" onClick={() => this.updateSeriesType('single')} >{t("Only This Events")}</Button>{' '}
                <Button className="m-1" color="light" onClick={() => this.updateSeriesType("multiple")} >{t("All Series Events")}</Button>{' '}
              </p>
              <p className="mt-4 col-12">
                <Button color="default" onClick={() => this.SeriesTypeModal()}>{t("Cancel")}</Button>
              </p>
            </div>
          </ModalBody>
        </Modal>

        <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#555" }} isOpen={this.state.removeMultipleEvtModal}  >
          <ModalBody color="primary w-25" className="modal-danger" style={{ borderRadius: "5px", backgroundColor: "#555" }}>
            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
              <i className="ri-repeat-fill" style={{ fontSize: "20px" }}></i>
              <h6 className="mt-3">{t("You are deleating a series event")}</h6>
              <p className="mt-2 small">{t("Do you want to remove only this series element or all series events?")}</p>
              <p>
                <Button className="m-1" color="light" onClick={() => this.doDelete('single')} >{t("Only This Events")}</Button>{' '}
                <Button className="m-1" color="light" onClick={() => this.doDelete("multiple")} >{t("All Series Events")}</Button>{' '}
              </p>
              <p className="mt-4 col-12">
                <Button color="default" onClick={() => this.RemoveMultipleModalClose()}>{t("Cancel")}</Button>
              </p>
            </div>
          </ModalBody>
        </Modal>

      </React.Fragment>
    )
  }
}
export default withTranslation()(Future);