import React, { Component } from 'react'
import { Spinner, Label, Input, Button, Modal, ModalBody, Table, ModalFooter, ModalHeader } from 'reactstrap';
import axios from "axios";
import { APIURL } from '../constants/Common';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '../../assets/css/Custom.css'
import { withTranslation } from 'react-i18next';

class SettingAttribute extends Component {
    constructor() {
        super()
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            teamAttrs: [],
            addAttrModal: false,
            attribute: "",
            atr_id: "",
            modal: false,
            Editmodal: false,
            fade: false,
            ToastMsg: "",
            toast: false,
            attribute_id: "",
            errMsgAttr: '',
            errMsg: [],
            setAttr: "",
            AddAtrModal: false,
            RemoveAtrModal: false,
            active: false,
            team_attrs_id: ""
        };
        this.Editclick = this.Editclick.bind(this);
        this.AtrModal = this.AtrModal.bind(this);
        this.addToggle = this.addToggle.bind(this);
        this.handleRemoveAtrModal = this.handleRemoveAtrModal.bind(this);
    }

    addToggle(e) {
        if (this.props.data.ActiveTeam === 0) {
            this.props.data.CloseModal()
            return false;
        }
        this.setState({
            addAttrModal: !this.state.addAttrModal,
            errMsgAttr: '',
            setAttr: '',
            errMsg: "",
            active: true
        });
    }

    handleStatus(e) {
        this.setState({
            active: e.target.checked
        });
    }

    AtrModal(e) {
        this.setState({
            AddAtrModal: !this.state.AddAtrModal,
        });
    }

    getTeamAttributes(id) {
        this.setState({
            Loader: true
        })
        var token = this.state.token
        axios
            .get(APIURL + "team_info/" + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.status === 200) {
                    const { teamInfo } = this.state;
                    this.setState({ teamInfo, Loader: false });
                }
            })

        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        axios
            .post(APIURL + "team_attribute/list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({ teamAttrs: response.data.data });
                }
            })
    }
    handleRemoveAtr(e) {
        if (this.props.data.ActiveTeam === 0) {
            this.props.data.CloseModal()
            return false;
        }

        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token

        axios.post(APIURL + "delete_team_attribute/" + this.state.team_attrs_id, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                this.setState({
                    toast: true,
                    modal: false,
                    removeAtrModal: false,
                    ToastMsg: response.data.message
                })
                this.setState({
                    teamAttrs: this.state.teamAttrs.filter((s, sidx) => this.state.team_attrs_id != s.team_attrs_id
                    )
                });
            })
            .catch(error => {
                this.setState({
                    modal: false,
                    removeEvtModal: false,
                    removeAtrModal: false,
                    errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );

    }



    handleTeamAtr(e) {
        this.setState({
            attribute: e,
        })
    }
    Editclick(e) {
        if (this.props.data.ActiveTeam == 0) {
            this.props.data.CloseModal()
            return false;
        }

        this.handleTeamAtr(e.name)
        this.setState({
            modal: !this.state.modal,
            atr_id: e.team_attrs_id,
            active: e.is_active == 1 ? true : false
        });
    }
    CloseModal() {
        if (this.props.data.ActiveTeam === 0) {
            this.props.data.CloseModal()
            return false;
        }
        this.setState({
            modal: !this.state.modal
        });
    }

    updateInput = (event) => {
        this.setState({ setAttr: event.target.value })
    };

    addTeamAttrs = () => {
        if (this.props.data.ActiveTeam === 0) {
            this.props.data.CloseModal()
            return false;
        }
        if (this.props.data.teamId) {
            this.setState({
                Loader: true
            })
            const formData = new FormData();
            formData.append('team_id', this.props.data.teamId);
            formData.append('team_member_attribute', this.state.setAttr);
            formData.append('team_member_id', '');
            formData.append('is_active', Number(this.state.active));
            var app_url = APIURL + "create_team_attribute";
            var token = this.state.token

            axios
                .post(app_url, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.getTeamAttributes(this.props.data.teamId);
                    this.setState({
                        addAttrModal: false,
                        teamAttrs: this.state.teamAttrs.concat([{ name: this.state.setAttr, team_attrs_id: "" }]),
                        setAttr: '',
                        errMsg: '',
                        errMsgAttr: '',
                        msg: response.data.message,
                        Loader: false
                    });

                })
                .catch(error => {
                    if (error.response.data.message == "validation_error") {
                        this.setState({
                            errMsg: error.response.data.errors,
                            isLoading: false,
                            Loader: false,
                        })
                    }
                    else {
                        this.setState({
                            error,
                            isLoading: false,
                            Loader: false,
                        })
                    }
                }
                );
        }
    }

    handleAddTeamAttrs = () => {
        if (this.state.setAttr) {
            this.setState({ teamAttrs: this.state.teamAttrs.concat([{ name: this.state.setAttr, team_attrs_id: "" }]), setAttr: '', errMsgAttr: '' });
            this.setState({ AddAtrModal: false })
        }
        else {
            this.setState({ errMsgAttr: 'Please enter teammember attribute.' });
        }
    }

    // onSubmitSave = (e) => {
    //     this.handleAddTeamAttrs();
    //     console.log("idd", this.state.setAttr, this.state.teamAttrs)
    //     const formData = new FormData();
    //     formData.append('team_id', this.props.data.teamId);
    //     formData.append('team_member_attributes[]', this.state.setAttr);
    //     formData.append('team_member_ids[]', '');
    //     formData.append('is_active', this.state.active);
    //     var app_url = APIURL + "create_team_attributes";

    //     axios
    //         .post(app_url, formData)
    //         .then((response) => {
    //             this.setState({ isLoading: false });
    //             if (response.data.status === 200) {
    //                 this.getTeamAttributes(this.props.data.teamId);
    //                 this.setState({
    //                     msg: response.data.message,
    //                     errMsg: '',
    //                     redirect: true,
    //                 });
    //             }
    //             if (
    //                 response.data.status === "failed" &&
    //                 response.data.success === undefined
    //             ) {
    //                 // this.props.data.teamInfo(response.data)
    //                 this.setState({
    //                     scsMsg: '',
    //                     errMsg: response.data.errors,
    //                 });
    //                 setTimeout(() => {
    //                     // this.setState({ errMsgEmail: "", errMsgPwd: "" });
    //                 }, 2000);
    //             } else if (
    //                 response.data.status === "failed" &&
    //                 response.data.success === false
    //             ) {
    //                 // this.props.data.teamInfo(response.data)
    //                 this.setState({
    //                     scsMsg: '',
    //                     errMsg: response.data.message,
    //                     errMsgEmail: '',
    //                     errMsgPwd: '',
    //                 });
    //                 setTimeout(() => {
    //                 }, 2000);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };


    onSubmit = (e) => {
        if (this.props.data.ActiveTeam === 0) {
            this.props.data.CloseModal()
            return false;
        }
        const formData = new FormData();
        formData.append('team_member_attribute', this.state.attribute);
        formData.append('team_id', this.props.data.teamId);
        formData.append('is_active', Number(this.state.active));
        var app_url = APIURL + "edit_team_attribute/" + this.state.atr_id;
        var token = this.state.token
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.getTeamAttributes(this.props.data.teamId);
                this.setState({
                    msg: response.data.message,
                    errMsg: '',
                    redirect: true,
                    modal: false
                })


            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false,
                        Loader: false,
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false,
                        Loader: false,
                    })
                }

            }
            );
    };

    handleRemoveAtrModal = (e) => {
        if (this.props.data.ActiveTeam === 0) {
            this.props.data.CloseModal()
            return false;
        }
        this.setState({
            team_attrs_id: e.team_attrs_id,
            removeAtrModal: !this.state.removeAtrModal
        })
    }
    RemoveModalClose() {
        this.setState({
            removeAtrModal: false
        })
    }
    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getTeamAttributes(this.props.data.teamId);
        }
    }
    componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus)
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <div className="row">
                    <div className="col-sm-8">
                        <div className="card">
                            <div className="card-body">
                                <h5>{t("Team attributes")}</h5>
                                {this.state.errMsg.message &&
                                    <span className="text-danger">{this.state.errMsg.message}</span>
                                }
                                <div className="table-responsive">
                                    <Table hover className="table table-hover table-centered nowrap">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>{t("Attribute name")}</th>
                                                <th>{t("Active")}</th>
                                                <th className="text-center" style={{ width: "170px" }}>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.teamAttrs.length > 0 && this.state.teamAttrs.map((attrs, idx) => (
                                                <tr key={idx}>
                                                    <td>{attrs.name}</td>
                                                    <td>{attrs.is_active === 1 ? "Yes" : "No"}</td>
                                                    <td className="text-right">
                                                        <div>
                                                            <Button size="sm" color="warning" className="mobile-icon" onClick={(e) => this.Editclick(attrs)} > <i className="ri-pencil-fill"></i> <span>{t("Edit")}</span></Button>
                                                            <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={(e) => this.handleRemoveAtrModal(attrs)}><i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className=" mt-3">
                                    <Button
                                        type="button"
                                        id="add_attrs"
                                        onClick={this.addToggle}
                                        className="btn btn-warning waves-effect waves-light mt-2 add_attrs"
                                    > {t('Add team attributes')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Modal    */}
                <Modal size="md" isOpen={this.state.addAttrModal} autoFocus={false}>
                    <ModalHeader toggle={this.addToggle}>{t('Add attributes')}</ModalHeader>
                    <ModalBody>

                        <div className="form-group">
                            <Input
                                type="text"
                                className="form-control"
                                id="team_attr"
                                onChange={this.updateInput}
                                placeholder={t("Enter team member attribute")}
                                value={this.state.setAttr}
                                autoFocus={true}
                            />
                            <span className="text-danger">{this.state.errMsg.team_member_attribute}</span>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <input className="form-check-input mr-1" type="checkbox" onChange={(e) => this.handleStatus(e)} checked={this.state.active} id="flexCheckDefault" />

                                <Label className="mb-0">{t('Active')}</Label>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <button className="btn btn-danger" onClick={() => this.addToggle()}>{t('Cancel')} </button>
                            <button className="btn btn-success" onClick={() => this.addTeamAttrs()}>  {t('Save')} </button>
                            </div>
                    </ModalFooter>
                </Modal>
                <Modal size="md" isOpen={this.state.modal} autoFocus={false}  >
                    <ModalHeader toggle={this.Editclick}>{t("Edit attribute")}</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <Label>{t('Attribute name')}</Label>
                            <input
                                type="text"
                                className="form-control p-2"
                                placeholder={t("Please enter team member attribute")}
                                id="team_name"
                                name="team_name"
                                required=""
                                data-maxzpsw="0"
                                onChange={(e) => this.handleTeamAtr(e.target.value)}
                                value={this.state.attribute}
                                autoFocus={true}
                            />
                            <span className="text-danger">{this.state.errMsg.team_member_attribute}</span>
                        </div>
                        <div className="form-group">
                            <input className="form-check-input mr-1" type="checkbox" onChange={(e) => this.handleStatus(e)} checked={this.state.active} id="flexCheckDefault" />

                            <Label className="mb-0">{t('Active')}</Label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                           <button className="btn btn-danger" onClick={() => this.CloseModal()}>{t('Cancel')} </button>
                           <Button className="btn btn-success" onClick={() => this.onSubmit()}>{t('Save')} </Button>
                            
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeAtrModal}  >
                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">{t('Are you sure want to remove')} ?</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={() => this.handleRemoveAtr()} >{t('Confirm')} </Button>
                                <Button className="mx-2" color="default" onClick={() => this.RemoveModalClose()}>{t('Cancel')}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(SettingAttribute);
