import React, { Component } from 'react'
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Form, Input, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../constants/Common';
import Select from 'react-select'
import { withTranslation } from 'react-i18next';


const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333"
        };
    }
};
class Tasks extends Component {
    constructor(props) {
        super();

        this.state = {
            task_name: "",
            rights_user: [],
            Task_list: [],
            errMsg: "",
            sortRow:[],
            checked: false,
            change_rights_user: [{ value: 0, label: 'No' }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Loader: false,
            modal: false,
            errMsgTask: "",
            yesNoList:[]
        }
        this.toggle = this.toggle.bind(this);
        this.closemodal = this.closemodal.bind(this);
    }

    toggle(e) {
         const { t } = this.props;
        this.setState({
            modal: !this.state.modal,
            errMsg: '',
            task_name: '',
            change_rights_user: [{ value: 0, label: t("No") }]
        });
    }

    closemodal(){
        this.setState({
            modal:false
        })
    }

    handleTask(e) {
        this.setState({
            task_name: e
        })
    }

    // handleChange = (rights_user) => {
    //     this.setState({ rights_user }, () =>
    //       console.log(`Option selected:`, this.state.rights_user)
    //     );
    //   };

    handleChange = (e) => {
        
        if (e) {
            this.setState({ change_rights_user: [{ value: e.value, label: e.label }] })
        }
        else {
            // this.setState({ errMsg: { function_id: 'Please select is-Admin.'} }); 
        }
        // console.log("change_rights_user",this.state.change_rights_user)
    };

    handleAddTasks = () => {
           const { t } = this.props;
                const formData = new FormData();
                formData.append('team_id', this.props.data.teamId);

                formData.append('task_id', '');
                formData.append('task_name', this.state.task_name);
                formData.append('rights_user', this.state.change_rights_user[0].value);
                formData.append('active', 'true');

                this.setState({
                    Loader: true
                })
                var app_url = APIURL + "create_task";
                var token = this.state.token
                axios
                    .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((response) => {
                            this.getTasks(this.props.data.teamId);
                            this.setState({
                                msg: response.data.message,
                                change_rights_user: [{ value: 0, label: t("No") }],
                                task_name: '',
                                errMsg: { task_name: '' },
                                Loader: false,
                                modal: false
                            });
                 
                    })
                    .catch(error =>{
                       if(error.response.data.message === "validation_error")
                       {
                            this.setState({
                                errMsg:error.response.data.errors,
                                Loader: false
                            })
                       }
                       else
                       {
                            this.setState({
                              error,
                              Loader: false
                            })
                       }
                        
                    }
                  );   
  
    }

    handleRemoveTask(e) {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        axios.post(APIURL + "delete_task/" + e.task_id, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        RemScsMsg: response.data.message,
                        redirect: true,
                        toast: true
                    })
                    this.setState({
                        Task_list: this.state.Task_list.filter((s, sidx) => e.task_id != s.task_id
                        )
                    });

                }
            });
    }
    onSubmit = (e) => {
        this.props.data.handleCompleteTab(4);
       // this.props.data.handleActiveTab(4);
        // console.log("ids", this.props.data.teamId)
    };

    getTasks(id) {
        var token = this.state.token
        axios
            .get(APIURL + "get_tasks/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                //   console.log("task",response)
                if (response.data.status === 200) {
                    this.setState({ Task_list: response.data.data });
                }
            });
    }

    getAnswerOption(id) {
        var token = this.state.token
        axios
            .get(APIURL + "get_answers/" + id)
            .then((response) => {
                console.log("Ans AddTeam ", response.data,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                if (response.data.status === 200) {
                    this.setState({
                        AnsOpt_List: response.data.data,
                        Comment: response.data.data.comment_mandatory,
                    });
                    this.setState({
                        selected_type: [{
                            'value': response.data.data.comment_mandatory,
                        }]
                    });
                }
            });
    }

    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getTasks(this.props.data.teamId);
        }
         this.getOption();
    }
    componentWillReceiveProps() {  
        this.getOption();
    }

    handleStep(type,index){
        const sort_List=this.state.Task_list;
        let sortRow=[]; let selfsort; let othersort;
     
       if(type === "up")
       {
           
            const self=sort_List[index];
            const other=sort_List[index-1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index-1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['task_id'],"sort":self['sort']});
            sortRow.push({id:other['task_id'],"sort":other['sort']});

            this.setState({ 
                Task_list: sort_List
            })

       }
       else if(type === "down")
       {
            const self=sort_List[index];
            const other=sort_List[index+1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index+1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['task_id'],"sort":self['sort']});
            sortRow.push({id:other['task_id'],"sort":other['sort']});

            this.setState({ 
                Task_list: sort_List
            })
       }
        this.setState({ 
            sortRow: sortRow
        },()=>{this.updateStep()})

    }


    updateStep()
    {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "task/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                   
            })
             .catch(error =>{
                
                this.setState({
                   errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }

    getOption(){
       const { t } = this.props;
        let yesNoList=[];
        yesNoList.push({ value: 0, label: t('No') })
        yesNoList.push({ value: 1, label: t('Yes') })

        let change_rights_user=this.state.change_rights_user;
        change_rights_user= [{ value: change_rights_user[0].value, label: change_rights_user[0].label}]

       this.setState({ 
        yesNoList: yesNoList,
        change_rights_user:change_rights_user 
       })
   }

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Form id="teaminfoform" method="post" action="#" enctype='multipart/form-data' className="form-horizontal was-validated">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <h5>{t("Tasks")}</h5>
                                        <span>{t("Tasks can be added for each event. Members can sign up for these tasks. Tasks can also be contingent per event. For example, for a sports club this can be 1 x washing shirts, 10 x offering a ride, 1 x organizing break tea, etc.")} </span>
                                    </div>

                                    <div className="table-responsive table-wrapper">
                                        <table className="table table-hover tableFixHead" >
                                            <thead className="bg-light">
                                                <tr className="fw-medium" >
                                                    <th></th>
                                                    <th> {t("Task")} </th>
                                                    <th className="text-center">{t("Only admin and team administrators can assign task")}</th>
                                                    <th className="text-center">{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className="font-14">
                                                {
                                                    this.state.Task_list.map((item, idx) => (
                                                        <tr>
                                                             <td>
                                                                 {idx != 0 &&
                                                                  <i  onClick={(e) => this.handleStep('up',idx)}   className="mdi mdi-arrow-up"></i>
                                                                 }
                                                                {this.state.Task_list.length != idx+1 &&
                                                                    <i onClick={(e) => this.handleStep('down',idx)}  className="mdi mdi-arrow-down"></i>
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.task_name}
                                                            </td>
                                                            <td className="text-center">
                                                              {parseInt(item.change_rights_user) == 1 ? <span>Only Admin</span> : <span>All</span>}
                                                                                                                           </td>
                                                            <td className="text-center">
                                                    <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={(e) => this.handleRemoveTask(item)}><span>{t("Remove")}</span></Button>

                                                                {/* <i onClick={(e) => this.handleRemoveTask(item)} className=" cursor_pointer far fa-trash-alt"></i> */}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="mt-3">
                                        <Button
                                            type="button"
                                            id="add_attrs"
                                            onClick={this.toggle}
                                            className="btn btn-warning waves-effect waves-light mt-2 add_attrs fw-bolder"
                                        > {t("Add tasks")} </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="pager wizard mb-0 list-inline">
                        <li className="previous list-inline-item">
                            <button onClick={() => this.props.data.handleActiveTab(2)} type="button" className="btn btn-warning mr-2 fw-bolder"><i className="mdi mdi-arrow-left me-1"></i> {t("Back to members")}</button>
                        </li>
                        <li className="next list-inline-item disabled">
                            <button
                                onClick={() => this.onSubmit()}
                                type="button"
                                className="btn btn-primary fw-bolder">{t("Go to answer options")} <i className="mdi mdi-arrow-right ms-1"></i></button>
                        </li>
                    </ul>
                    {/* <!-- end row -->  */}
                </Form>

                <Modal size="md" isOpen={this.state.modal} autoFocus={false}>
                    <ModalHeader toggle={this.closemodal}>{t("Add task")}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Task")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="useremail"
                                        placeholder={t("Enter task name")}
                                        value={this.state.task_name}
                                        onChange={(e) => this.handleTask(e.target.value)}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.task_name)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Only admin and team admin can set task")}</Label>
                                    <Select
                                        styles={colourStyles}
                                        placeholder="Select permissions"
                                        value={this.state.change_rights_user}
                                        onChange={this.handleChange}
                                        options={this.state.yesNoList}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.rights_user)}</span>
                                </div>
                            </div>

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            
                        <button className="btn btn-danger fw-bolder" onClick={() => this.closemodal()}>{t("Cancel")} </button>
                            <Button className="btn btn-success fw-bolder" onClick={() => this.handleAddTasks()}>{t("Save")} </Button>
                         </div>
                    </ModalFooter>
                </Modal>

            </>
        )
    }
}
export default withTranslation()(Tasks);