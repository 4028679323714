import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import { Input, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import { APIURL } from '../../constants/Common';
import axios from "axios";
import Sidebar from "../../GeneralComponents/Sidebar";
import { withTranslation } from 'react-i18next';

class Document extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            activeTab: "",
            Loader: false,
            repos: [],
            team_name: "",
            name: "",
            doc_id: "",
            errMsg: {},
            DocTypes_list: [],
            addModal: false,
            modal: false,
            teamId: this.props.match.params.id,
            sortRow: [],
            removeDocTypeModal:false,
            DocTypeId:"",
            index:"",
            SubscriptionModal:false,
            subscriptionPlan:"No",
            is_owner:"No",
            day:0,
            ActiveTeam:0,
            isPermission:1,
            no_record:false
        };
        this.toggle = this.toggle.bind(this)
        this.Editclick = this.Editclick.bind(this)
    }

    toggle(e) {
        if(this.state.ActiveTeam === 0){
            this.Sub_CloseModal()
            return false;
           }
        this.setState({
            addModal: !this.state.addModal,
            name: "",
            doc_id: "",
            errMsg: {},
        });
    }

    Editclick(e) {
        if(this.state.ActiveTeam === 0){
            this.Sub_CloseModal()
            return false;
           }
        this.setState({
            modal: !this.state.modal,
            name: e.name,
            doc_id: e.id,
            errMsg: ""
        });
    }

    CloseModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.getDocTypes(this.props.match.params.id);
            this.getTeamDetails();
            this.getExpirationPlanMsg()
        }
    }

    removeDocTypeModal( idx ,e){
        if(this.state.ActiveTeam === 0){
            this.Sub_CloseModal()
            return false;
           }
        this.setState({
            DocTypeId: e.id,
            index:idx,
            removeDocTypeModal:!this.state.removeDocTypeModal
        })
    }

    removeModalClose(){
        if(this.state.ActiveTeam === 0){
            this.Sub_CloseModal()
            return false;
           }
        this.setState({
            removeDocTypeModal:false
        })
    }

    doDelete() {
        this.setState({ Loader: true });

        const formData = new FormData();
        formData.append('id', this.state.DocTypeId);
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "document_type/delete", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {
                const newRep = [...this.state.DocTypes_list];
                newRep.splice(this.state.index, 1);
                this.setState({
                    DocTypes_list: newRep,
                    Loader: false,
                    removeDocTypeModal:false
                });

            })
            .catch(error => {
                this.setState({
                    removeDocTypeModal:false,
                    errMsg: {
                        message: error.response.data.message
                    },
                    Loader: false
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }

    handleAddDocumentTypes = () => {
        // this.reloadPage()
        const errMsg = this.state.errMsg;
        if (this.state.name) {
            this.setState({
                Loader: true
            })
            // console.log("ids", this.state.DocTypes_list)
            const formData = new FormData();
            formData.append('team_id', this.props.match.params.id);
            formData.append('name', this.state.name);
            var token = this.state.token

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            }

            var app_url = APIURL + "document_type/create";

            axios
                .post(app_url, formData, config)
                .then((response) => {
                    console.log(response.data)
                    this.getDocTypes(this.props.match.params.id);
                    this.setState({
                        addModal: false,
                        Loader: false
                    })
                    // this.toggle();
                })
                .catch((error) => {
                    if (error.response.data.message === "validation_error") {
                        this.setState({
                            errMsg: error.response.data.errors,
                            Loader: false
                        })
                    }
                    else {
                        this.setState({
                            error,
                            Loader: false
                        })
                    }
                });

        }
        else {
            errMsg['name'] = 'Please enter document type';
            this.setState({ errMsg });
        }
    }

    getDocTypes(id) {
        const formData = new FormData();
        formData.append('team_id', id);
        var token = this.state.token
        axios
            .post(APIURL + "document_type/list", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({ DocTypes_list: response.data.data });
                }
                 this.setState({ no_record: true});
                
            });
    }

    onChangehandler = (e) => {
        if (e.target.value) {
            this.setState({
                name: e.target.value,
                errMsg: { subject: '' }
            });
        }
        else {
            this.setState({
                name: '',
                errMsg: { subject: "Please enter document type" },
            });
        }
    };

    onSubmit = (e) => {
        this.setState({
            Loader: true
        });
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        formData.append('name', this.state.name);
        formData.append('id', this.state.doc_id);
        var app_url = APIURL + "document_type/update";
        var token = this.state.token

        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getDocTypes(this.props.match.params.id);
                this.setState({
                    msg: response.data.message,
                    // errMsg: '',
                    redirect: true,
                    modal: false,
                    Loader: false
                });
            })
            .catch((error) => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }
            });
    };
    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token

        axios
            .post(APIURL + "get_team_detail", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    team_name: team_name
                })

            });

    }

    handleStep(type, index) {
        const sort_List = this.state.DocTypes_list;
        let sortRow = []; let selfsort; let othersort;

        if (type === "up") {

            const self = sort_List[index];
            const other = sort_List[index - 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index - 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['id'], "sort": self['sort'] });
            sortRow.push({ id: other['id'], "sort": other['sort'] });

            this.setState({
                func_List: sort_List
            })

        }
        else if (type === "down") {
            const self = sort_List[index];
            const other = sort_List[index + 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index + 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['id'], "sort": self['sort'] });
            sortRow.push({ id: other['id'], "sort": other['sort'] });

            this.setState({
                func_List: sort_List
            })
        }
        this.setState({
            sortRow: sortRow
        }, () => { this.updateStep() })

    }


    updateStep() {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "document_type/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {

            })
            .catch(error => {

                this.setState({
                    errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }

        getExpirationPlanMsg = () => {
            console.log(this.state.user.user_id)
            const formData = new FormData();
            formData.append('user_id', this.state.user.user_id);
            formData.append('team_id', this.props.match.params.id);
            var token = this.state.token
            axios
                .post(APIURL + "license_booking/send_notifications", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("Subscription", response)
                    let days =  response.data.after_days ;
                    let Owner = response.data.is_owner;
                    let Sub_true = false ; let isPermission=1;
                    if( (response.data.subscriptionPlan === "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)){
                        Sub_true = true ;
                    }
                    
                    if(days>60 || response.data.member.length === 0)
                    {
                       isPermission=0;
                    }
                    else if(response.data.member.length === 1)
                    {
                        if(response.data.member[0].is_admin === 0 && response.data.member[0].is_teamleader === 0)
                        {
                             isPermission=0;
                        }
                    }
                   
                    this.setState({
                        SubscriptionModal:Sub_true,
                        subscriptionPlan:response.data.subscriptionPlan,
                        is_owner:response.data.is_owner,
                        day:response.data.after_days,
                        ActiveTeam:response.data.active,
                        isPermission:isPermission
                       
                    })
                });
        }
    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    Sub_CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    render() {
         const { t } = this.props;
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        
        return (
            <React.Fragment>
                <div id="wrapper">
                    {
                        this.state.Loader ?
                            <div className="loader">
                                <Spinner style={{ width: '3rem', height: '3rem' }} />
                            </div>
                            :
                            ""
                    }
                    <Sidebar
                        data={{
                            // teamInfo: this.teamInfo.bind(this),
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage:"document",
                            visible:this.props.location.visible
                        }} />

<Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&  
                                <h6 className="mt-3">Upgrade Package.</h6>   
                            }
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&  
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>   
                            }
                            {
                                this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                            }
                            {
                                this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Imenso if the team is to be reactivated.</h6>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                                  <Button className="btn btn-danger" onClick={() => this.Sub_CloseModal()}>Cancel </Button>
                                  {this.state.is_owner === "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>Upgrade Package</Button> : ""}
                       </div>
                    </ModalFooter>
                </Modal>
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt">
                                            <h4 className="page-title">{this.state.team_name} | {t("Document types")} </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs ">
                                            <li className="nav-item">
                                                <Link to={"/documents/" + this.state.teamId} className="nav-link">
                                                    <span>{t("Documents")}</span></Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"/document_types/" + this.state.teamId} className="nav-link active">
                                                    <span>{t("Document types")}</span></Link>
                                            </li>
                                        </ul>

                                        <div className="tab-content">

                                            {
                                                this.state.errMsg.message ?
                                                    <div className="col-12 document_list_area text-danger mb-2  text-danger Login_error" >
                                                        {this.state.errMsg.message}
                                                    </div>
                                                     : ""
                                            } 
                                            <div className="tab-pane active" id="events1">
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                         <Button
                                                            id="add_attrs"
                                                            onClick={this.toggle}
                                                            className="btn btn-primary waves-effect waves-light mt-0 add_attrs"
                                                           >
                                                           <i className="mdi mdi-plus"></i>{t("Add document type")}
                                                          </Button>
                                                          
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-sm-8">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="table-responsive table-wrapper">
                                                                    <React.Fragment>
                                                                        <table className="table table-hover tableFixHead">
                                                                            <thead className="bg-light">
                                                                                <tr>
                                                                                    <th></th>
                                                                                    {/* <th className="fw-medium">ID</th> */}
                                                                                    <th className="fw-medium">{t("Document type")}</th>
                                                                                    <th className="fw-medium text-center" style={{ maxWidth: "170px" }}>{t("Action")}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="font-14">
                                                                                {this.state.DocTypes_list.length > 0 && this.state.DocTypes_list.map((result, index) =>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {index !== 0 &&
                                                                                                <i onClick={(e) => this.handleStep('up', index)} className="mdi mdi-arrow-up"></i>
                                                                                            }
                                                                                            {this.state.DocTypes_list.length != index + 1 &&
                                                                                                <i onClick={(e) => this.handleStep('down', index)} className="mdi mdi-arrow-down"></i>
                                                                                            }
                                                                                        </td>
                                                                                        {/* <td><b>#{index + 1}</b></td> */}
                                                                                        <td>{result.name}</td>
                                                                                        <td className="text-center">
                                                                                            <Button size="sm" color="warning"  className="mobile-icon" onClick={(e) => this.Editclick(result)} > <i className="ri-pencil-fill"></i> <span>{t("Edit")}</span></Button>
                                                                                            <Button size="sm" color="danger" className="ms-2 mobile-icon" onClick={(e) => this.removeDocTypeModal(index, result)}><i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                                {this.state.no_record === true && !this.state.DocTypes_list.length &&
                                                                                    <tr>
                                                                                        <td colspan="12" className="text-center">{t("No document type found")}</td>
                                                                                    </tr>
                                                                                }

                                                                            </tbody>
                                                                        </table>
                                                                    </React.Fragment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Modal size="md" isOpen={this.state.addModal} >
                                            <ModalHeader toggle={this.toggle}>{t("Add document type")}</ModalHeader>
                                            <ModalBody>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t("Enter document name")}
                                                                name="name"
                                                                required=""
                                                                value={this.state.name}
                                                                onChange={this.onChangehandler}
                                                                max="20"
                                                            />
                                                            <span className="text-danger">{t(this.state.errMsg.name)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ModalBody>

                                            <ModalFooter>
                                                <div className="d-flex justify-content-between w-100">
                                                    <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </button>
                                                    <Button className="btn btn-success fw-bolder" onClick={() => this.handleAddDocumentTypes()}>{t("Save")} </Button>
                                                </div>
                                            </ModalFooter>
                                        </Modal>

                                        <Modal size="md" isOpen={this.state.modal} >
                                            <ModalHeader toggle={this.Editclick}>{t("Edit document type")}</ModalHeader>
                                            <ModalBody>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                           
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t("Enter document name")}
                                                                name="name"
                                                                required=""
                                                                value={this.state.name}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <span className="text-danger">{t(this.state.errMsg.name)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="d-flex justify-content-between w-100">
                                                    <Button className="btn btn-danger fw-bolder" onClick={() => this.CloseModal()}>{t("Cancel")}  </Button>
                                                    <Button className="btn btn-success fw-bolder" onClick={() => this.onSubmit()}>{t("Save")} </Button>
                                                </div>
                                            </ModalFooter>
                                        </Modal>
                                        <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeDocTypeModal}  >
                                            <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                                                <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                                                    <h6 className="mt-3">{t("Are you sure want to remove")} ?</h6>
                                                    <p className="mt-4 col-12">
                                                        <Button className="mx-2" width="50px" color="light" onClick={()=> this.doDelete()} >{t("Confirm")} </Button>{' '}
                                                        <Button className="mx-2" color="default" onClick={()=> this.removeModalClose()}>{t("Cancel")}</Button>
                                                    </p>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Document);